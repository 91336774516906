import httpCiient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getListAllowance = async () => {
  try {
    const response = await httpCiient.get(`/expense/manager/company/${comCode}/memberexpense/page`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getListMemberExpensePage = async (payload) => {
  try {
    const response = await httpCiient.post(`/expense/manager/company/${comCode}/memberexpense/page`, payload);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const saveMemberExpense = async (payload) => {
  try {
    const response = await httpCiient.post(`/expense/manager/company/${comCode}/memberexpense/save`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const updateMemberExpense = async (payload) => {
  try {
    const response = await httpCiient.post(`/expense/manager/company/${comCode}/memberexpense/changestatusall`, payload);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const getMemberExpenseDetail = async (id) => {
  try {
    const response = await httpCiient.get(`/expense/manager/company/${comCode}/memberexpense/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const updateAllowanceDetail = async (allowanceId, payload) => {
  try {
    const response = await httpCiient.put(`/v1/task-setting/manager/RT/allowance/${allowanceId}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const exportMemberExpenseResource = async (payload) => {
  try {
    const response = await httpCiient.post(`/expense/manager/company/${comCode}/memberexpense/export`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export {
  getListMemberExpensePage,
  getMemberExpenseDetail,
  updateMemberExpense,
  exportMemberExpenseResource,
  saveMemberExpense
};
