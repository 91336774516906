import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { Table, Row, Card, Avatar, Button, List, Modal, Popover, Icon, Dropdown, Menu } from 'antd';
import ActionCustomer from './action-customer';
import { CustomerContext } from '../customer-context';
import httpClient from '../../../components/axiosClient';
import { debounce } from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { dd, useQuery } from '../../../helpers/general-helper';
import style from './css/customer.css';
import { PageSettings } from '../../../config/page-settings';
import ModalAddCustomer from '../../../components/add-customer/index';
import ModalEditCustomer from '../../../components/edit-customer/index';
import Button01 from '../../../components/v2/button_01';
import { successNotification, errorNotification, warningNotification } from '../../../components/v2/notification';
import Addcustomer from '../../../components/add-business-partner/index';
import _ from 'lodash';
import EditbusinessPartner from '../../../components/edit-business-partner/index';
import Button_01 from '../../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';

export default () => {
  const { checkPermissionAction } = useContext(PageSettings);
  const query = useQuery();
  const intl = useIntl();
  const { confirm } = Modal;
  const memComId = localStorage.getItem('memComId');

  const [visiblePopover, setVisiblePopover] = useState(false);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => record.index,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
      width: 70,
      align: 'center',
    },
    {
      title: '',
      dataIndex: 'profileImg',
      key: 'profileImg',
      render: (text, record, index) => <Avatar src={record.profileImg} />,
      fixed: 'left',
      width: 70,
    },
    {
      title: <FormattedMessage id="customerTableColumnCustomerCode" defaultMessage="Customer Code" />,
      dataIndex: 'customerCode',
      key: 'customerCode',
      fixed: 'left',
      width: 160,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <FormattedMessage id="customerTableColumnCustomerName" defaultMessage="Customer Name" />,
      dataIndex: 'customerName',
      key: 'customerName',
      width: 300,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        if (record.customerName) {
          return (
            <PageSettings.Consumer>
              {({ setPageSidebarTitle, checkPermissionAction }) =>
                checkPermissionAction('P8PG2', 'P8PG2A1') ? (
                  <List.Item.Meta
                    key={index}
                    title={
                      <Link
                        onClick={() => setPageSidebarTitle(['Customer Detail'])}
                        style={{ fontWeight: 'initial' }}
                        to={'/main/crm/customers/customer-detail/' + record.customerId}
                      >
                        {record.customerName}
                      </Link>
                    }
                  />
                ) : (
                  record.customerName
                )
              }
            </PageSettings.Consumer>
          );
        }
      },
    },
    {
      title: <FormattedMessage id="customerTableColumnBusinessType" defaultMessage="Business Type" />,
      dataIndex: 'businessType',
      key: 'businessType',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <FormattedMessage id="customerTableColumnCustomerType" defaultMessage="Customer Type" />,
      dataIndex: 'customerType',
      key: 'customerType',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <FormattedMessage id="customerTableColumnAddress" defaultMessage="Address" />,
      dataIndex: 'address',
      key: 'address',
      width: 450,
      ellipsis: true,
    },
    {
      title: <FormattedMessage id="customerTableColumnCreatedBy" defaultMessage="Created By" />,
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <FormattedMessage id="customerTableColumnCreatedDate" defaultMessage="Created Date" />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <FormattedMessage id="customerTableColumnReference" defaultMessage="Reference" />,
      dataIndex: 'referredPhone',
      key: 'referredPhone',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '',
      dataIndex: 'test',
      key: 'test',
      align: 'center',
      width: 70,
      render: (text, record, index) => fncActionTable(record, index),
    },
  ];

  const setShowColumn = {
    index: true,
    profileImg: true,
    customerCode: true,
    customerName: true,
    businessType: true,
    customerType: true,
    address: true,
    createdBy: true,
    createdAt: true,
    options: false,
    referredPhone: true,
  };

  const setShowColumnArr = [
    'index',
    'profileImg',
    'customerCode',
    'customerName',
    'businessType',
    'customerType',
    'address',
    'createdBy',
    'createdAt',
    'options',
    'referredPhone',
  ];

  const ignoreColumn = ['options'];

  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const imageRef = useRef();

  const comCode = localStorage.getItem('comCode');
  const [customerData, setCustomerData] = useState();
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [fieldChang, setFieldChang] = useState('');
  const [checkSearch, setCheckSearch] = useState(false);
  const [selectSearchData, setSelectSearchData] = useState('all');
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [visibleCustomer, setvisibleCustomer] = useState(false);
  const [visibleCustomerEdit, setvisibleCustomerEdit] = useState(false);

  const [formRef, setFormRef] = useState(null);
  const [formRefEdit, setFormRefEdit] = useState(null);
  const [triggerCustomer, setTriggerCustomer] = useState(false);

  const [imageCustomer, setImageCustomer] = useState();
  const [imageFile, setImageFile] = useState();

  const [cusDataBodyApi, setCusDataBodyApi] = useState({});
  const [customerIdApi, setCustomerIdApi] = useState('');
  const [customerDataDefalse, setCustomerDataDefalse] = useState({});

  const [LocationData, setLocationData] = useState({});
  const [cusInformation, setCusInformation] = useState({});
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [checkImgDefault, setCheckImgDefault] = useState(false);
  const [newImg, setNewImg] = useState(undefined);
  const [dataPrivacy, setDataPrivacy] = useState();
  const [privacyData, setprivacyData] = useState();
  const [triggerAPI, settriggerAPI] = useState(false);

  const [triggerCancel, setTriggerCancel] = useState(false);
  const [nameAuto, setNameAuto] = useState();

  useEffect(() => {
    getAndSearchData();
  }, [selectSearchData, fieldChang, paginationPage, paginationSize, orderSort, fieldSort, triggerCustomer]);

  const getAndSearchData = async () => {
    const body = {
      searchBy: selectSearchData,
      searchVal: fieldChang,
      page: fieldChang === '' ? paginationPage : 1,
      limit: paginationSize,
      orderBy: orderSort,
      orderField: fieldSort,
    };

    try {
      const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-informations/search`, body);
      if (response.status == 200) {
        setCustomerData(response.data.customers);
        setTotal(response.data.allCustomers);
      } else {
      }
    } catch (error) {
      return;
    }
  };

  const handleExport = async () => {
    const body = {
      searchBy: selectSearchData,
      searchVal: fieldChang,
      page: 1,
      limit: total,
      orderBy: orderSort,
      viewFormat: [
        {
          colName: 'Customer Code',
          sequence: 1,
          colCode: 'customerCode',
        },
        {
          colName: 'Customer Name',
          sequence: 2,
          colCode: 'customerName',
        },
        {
          colName: 'Customer Type',
          sequence: 3,
          colCode: 'customerType',
        },
        {
          colName: 'Business Type',
          sequence: 4,
          colCode: 'businessType',
        },
        {
          colName: 'Phone',
          sequence: 5,
          colCode: 'phone',
        },
        {
          colName: 'Address',
          sequence: 6,
          colCode: 'address',
        },
        {
          colName: 'Position',
          sequence: 7,
          colCode: 'position',
        },
        {
          colName: 'Created By',
          sequence: 8,
          colCode: 'createdBy',
        },
        {
          colName: 'Created Date',
          sequence: 9,
          colCode: 'createdAt',
        },
        {
          colName: 'Referred Phone',
          sequence: 10,
          colCode: 'referredPhone',
        },
      ],
    };

    try {
      const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-informations/export`, body);
      console.log('ExportCustomer', response.data);
      if (response.status == 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {
      return;
    }
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleSearch = debounce((fieldChang) => {
    setFieldChang(fieldChang ? fieldChang : '');
    setCheckSearch(!checkSearch);
  }, 500);

  const handleSelectData = (value) => {
    setSelectSearchData(value === undefined ? 'all' : value);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 5) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }

    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationPage(current);
    setPaginationSize(size);
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px', float: 'left' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page:" />
      </span>
    );
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const saveFormRef = useCallback((node) => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const handleCancelModal = () => {
    setvisibleCustomer(false);
    setImageFile('');
    setCusDataBodyApi({});
    setImageCustomer();
    formRef.resetFields();
    imageRef.current.value = null;
    setDataPrivacy();
  };

  const handleCreate = () => {
    // ใช้ค่านี้ส่งรูป imageCustomer //
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const mapPrivacyTeamList =
        dataPrivacy && dataPrivacy.team !== undefined
          ? dataPrivacy.team.map((item) => {
              return item.toString();
            })
          : undefined;

      try {
        const body = {
          comCode: comCode,
          customerName: values.cusName,
          customerShortName: values.cusCode,
          businessTypeId: values.busType,
          customerTypeId: values.cusType,
          address: cusDataBodyApi.address,
          lat: cusDataBodyApi.lat,
          lng: cusDataBodyApi.lng,
          profileImg: undefined,
          phone: undefined,
          contacts: [],
          locationName: cusDataBodyApi.addressName,
          description: cusDataBodyApi.description,
          selectedPrivacy: dataPrivacy && dataPrivacy.privacy !== undefined ? dataPrivacy.privacy : 'only_me',
          privacyTeamLists: mapPrivacyTeamList,
        };

        const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-informations`, body);

        if (response.status === 200) {
          successNotification(response.data.status.message);
          setvisibleCustomer(false);
          setDataPrivacy();
          if (response.data) {
            let formData = new FormData();
            formData.append('file', imageCustomer);
            try {
              const resUploadImage = await httpClient.put(
                `/v3/business-partner/manager/company/${comCode}/customer-informations/${response.data.data.customerId}`,
                formData
              );
            } catch (error) {}
          }
        } else {
          errorNotification(response.data.status.message);
        }
      } catch (error) {
        if (error) {
          errorNotification(error.response.data.status.message);
        }
      }

      setTriggerCustomer((current) => !current);
      formRef.resetFields();
      setImageFile('');
      setNewImg(undefined);
      setImageCustomer();
      imageRef.current.value = null;
    });
  };

  const handlerDelete = (customerId) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'customerTableDeleteConfirmText', defaultMessage: 'Are you sure to delete customer ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await httpClient.delete(`/v3/business-partner/manager/company/${comCode}/customer-informations/${customerId}`);
          if (response.status == 200) {
            setSelectSearchData('all');
            setTriggerCustomer((current) => !current);
            successNotification(response.data.status.message);
          } else {
            errorNotification(response.data.status.message);
          }
        } catch (error) {}
      },
    });
  };

  const handleEditCustomer = (record) => {
    setCustomerDataDefalse(record);
    setVisibleEdit(true);
  };

  // -------------------------------Edit Customer By Tae--------------------------------------------

  const handleSaveEdit = () => {
    formRefEdit.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      let cusName = customerDataDefalse.customerCode.includes(values.cusCode);

      try {
        const body = {
          comCode: comCode,
          customerName: values.cusName,
          customerShortName: values.cusCode,
          businessTypeId: values.busType,
          customerTypeId: values.cusType,
          address: cusDataBodyApi.description,
          fullAddress: cusDataBodyApi.address,
          lat: cusDataBodyApi.lat,
          lng: cusDataBodyApi.lng,
          profileImg: undefined,
          phone: undefined,
          contacts: [],
          locationName: cusDataBodyApi.addressName,
          description: cusDataBodyApi.description,
          selectedPrivacy: privacyData.privacy !== undefined ? privacyData.privacy : undefined,
          privacyTeamLists: privacyData.team !== undefined ? privacyData.team : undefined,
          createdBy: values.owner,
        };

        const response = await httpClient.put(
          `/v3/business-partner/manager/company/${comCode}/customer-informations/${customerDataDefalse.customerId}`,
          body
        );

        if (response.status === 200) {
          successNotification(response.data.status.messageEdit);

          if (response.status && checkImgDefault == true) {
            if (imageCustomer) {
              let formData = new FormData();
              formData.append('file', imageCustomer);
              try {
                const resUploadImage = await httpClient.put(
                  `/v3/business-partner/manager/company/${comCode}/customer-informations/${customerDataDefalse.customerId}`,
                  formData
                );
                setVisibleEdit(false);
                setCheckImgDefault(false);
                setNameAuto([]);
              } catch (error) {}
            } else {
              const body = {
                profileImg: 'noimage',
              };
              try {
                const resUploadImage = await httpClient.put(
                  `/v3/business-partner/manager/company/${comCode}/customer-informations/${customerDataDefalse.customerId}`,
                  body
                );
                setVisibleEdit(false);
                setCheckImgDefault(false);
              } catch (error) {}
            }
          }
          setVisibleEdit(false);
        } else {
          errorNotification(response.data.status.messageEdit);
        }
      } catch (error) {
        errorNotification(error.response.data.status.message);
      }

      setTriggerCustomer((current) => !current);
      setImageFile('');
      setImageCustomer();
      setNewImg(undefined);
      imageRef.current.value = null;
      formRefEdit.resetFields();
    });
  };

  const handleCancelEdit = () => {
    setVisibleEdit(false);
    formRefEdit.resetFields();
    setCusDataBodyApi({});
    setImageFile('');
    setImageCustomer();
    setNewImg(undefined);
    setCheckImgDefault(false);
    imageRef.current.value = null;
    setNameAuto([
      {
        name: _.get(customerDataDefalse, 'createdBy'),
        memComId: Number(_.get(customerDataDefalse, 'createdById')),
      },
    ]);
  };

  const saveFormRefEdit = useCallback((node) => {
    if (node !== null) {
      setFormRefEdit(node);
    }
  }, []);

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  };

  const fncActionTable = (record, index) => {
    return (
      <div>
        <Popover
          key={index}
          placement="leftTop"
          trigger="hover"
          visiblePopover={visiblePopover}
          onVisibleChange={handleOnVisiblePopover}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {record.createdById === memComId || checkPermissionAction('P8PG1C1', 'P8PG1C1A3') ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditCustomer(record)}>
                          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>

              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P8PG1C1', 'P8PG1C1A4') ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handlerDelete(record.customerId)}>
                          <p>
                            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                          </p>
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <Icon type="more" />
        </Popover>
      </div>
    );
  };

  const menuColumn = () => {
    return (
      <Menu>
        {checkPermissionAction('P8PG1C1', 'P8PG1C1A2') ? (
          <Menu.Item
            key="1"
            onClick={() => {
              setvisibleCustomer(true);
            }}
          >
            <Button key="1" ghost type="link">
              <FormattedMessage id="customerActionButtonAddCustomer" defaultMessage="Add Customer" />
            </Button>
          </Menu.Item>
        ) : null}
        <Menu.Item key="2" onClick={() => handleExport()}>
          <Button key="2" ghost type="link">
            <FormattedMessage id="btnExportExcel" defaultMessage="Export Excel" />
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction('P8PG1C1', 'P8PG1C1A1') ? (
          <Card
            className="customer-card"
            title={intl.formatMessage({ id: 'customerTableCardHeader', defaultMessage: 'Customer' })}
            extra={
              <Dropdown
                overlay={menuColumn}
                trigger={['click']}
                // onVisibleChange={setVisible}
                // visible={visible}
                placement="bottomRight"
                className="ant-dropdown-custom"
              >
                <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                  <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                  <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                </Button_01>
              </Dropdown>
            }
          >
            <Row>
              <ActionCustomer
                onChange={handleSearch}
                onSelect={handleSelectData}
                selectSearchData={selectSearchData}
                columns={columns}
                checkedValuecolumns={checkedValuecolumns}
                checked={defaultShowColumn}
                handleReset={handleReset}
                handleCheckOk={handleCheckOk}
                visible={visible}
                handleOnVisiblecolumns={handleOnVisiblecolumns}
                handleVisibleChange={handleVisibleChange}
                textErrorSelectColumn={textErrorSelectColumn}
                setvisibleCustomer={setvisibleCustomer}
                visibleCustomer={visibleCustomer}
              />
              <Table
                className="customer-table"
                dataSource={customerData}
                columns={newDataColumns}
                rowKey={(record) => record.index}
                scroll={{ x: 2000, y: `calc(100vh - 372px)` }}
                onChange={handleChange}
                pagination={{
                  showTotal: showTotal,
                  total: total,
                  defaultCurrent: 1,
                  pageSizeOptions: ['10', '20', '30', '40', '50'],
                  showSizeChanger: true,
                  locale: { items_per_page: '' },
                  onChange: handlePagination,
                  onShowSizeChange: handleSizeChange,
                }}
              />
              {customerData && customerData.length !== 0 ? (
                <div className="total-items-timeAttendance">
                  <span style={{ fontSize: '13px' }}>
                    <FormattedMessage id="lblTotal" defaultMessage="Total" />
                    {` ${total || 0} `}
                    <FormattedMessage id="lblitems" defaultMessage="items" />
                  </span>
                </div>
              ) : null}
            </Row>
            {/* <ModalAddCustomer
              visible={visibleCustomer}
              ref={saveFormRef}
              onCancel={handleCancelModal}
              onCreate={handleCreate}
              image={{
                imageRef,
                imageFile,
                setImageFile,
                imageCustomer,
                setImageCustomer,
                newImg,
                setNewImg
              }}
              setCustomer={{
                setCusDataBodyApi,
                setCustomerIdApi
              }}
              setDataPrivacy={setDataPrivacy}
              dataPrivacy={dataPrivacy}
            /> */}

            <Addcustomer visible={visibleCustomer} setVisible={setvisibleCustomer} setTrigger={setTriggerCustomer} />

            {/* <ModalEditCustomer
              ref={saveFormRefEdit}
              visible={visibleEdit}
              onCancel={handleCancelEdit}
              onCreate={handleSaveEdit}
              customerDataDefalse={customerDataDefalse}
              image={{
                imageRef,
                imageFile,
                setImageFile,
                imageCustomer,
                setImageCustomer,
                newImg, setNewImg
              }}
              setCustomer={{
                setLocationData,
                setCusDataBodyApi,
                setCustomerIdApi
              }}
              setCheckImgDefault={setCheckImgDefault}
              checkImgDefault={checkImgDefault}
              setprivacyData={setprivacyData}
              privacyData={privacyData}
              nameAuto={nameAuto}
              setNameAuto={setNameAuto}
            /> */}

            <EditbusinessPartner
              visible={visibleEdit}
              setVisible={setVisibleEdit}
              setTrigger={setTriggerCustomer}
              CustomerData={customerDataDefalse}
              setCustomerData={setCustomerDataDefalse}
            />
          </Card>
        ) : null
      }
    </PageSettings.Consumer>
  );
};
