import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { Card, Row, Col, Avatar, Badge, Icon, Divider, Modal } from 'antd';
import ModalEditCustomer from '../../../components/edit-customer/index';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import Button03 from '../../../components/v2/button_03';
import styles from './css/information.css';
import _ from 'lodash';
import httpClient from '../../../components/axiosClient';
import { successNotification, errorNotification, warningNotification } from '../../../components/v2/notification';
import { PageSettings } from '../../../config/page-settings';
import { useHistory } from 'react-router-dom';
import EditbusinessPartner from '../../../components/edit-business-partner/index';
import ResetPassword from '../../../components/modal-reset-password';

export default ({ informationData, setTriggerAddContact, triggerAddContact, settriggerDeleteImg, triggerDeleteImg }) => {
  // const { informationData, setTriggerAddContact, triggerAddContact } = props;
  const memComId = localStorage.getItem('memComId');
  const history = useHistory();
  const intl = useIntl();
  const imageRef = useRef();
  const comCode = localStorage.getItem('comCode');
  const profileImg = _.get(informationData, 'profileImg');
  const customerShort = _.get(informationData, 'customerShortName');
  const customerName = _.get(informationData, 'customerName');
  const businessType = _.get(informationData, 'businessType');
  const customerType = _.get(informationData, 'customerType');
  const address = _.get(informationData, 'address');
  const createdBy = _.get(informationData, 'createdBy');
  const createdDate = _.get(informationData, 'createdDate');
  const namePrivacy = _.get(informationData, 'selectedPrivacy.name');
  const codePrivacy = _.get(informationData, 'selectedPrivacy.code');
  const teamPrivacy = _.get(informationData, 'selectedPrivacy.orgIdLists');
  const customerId = _.get(informationData, 'customerId');

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [formRefEdit, setFormRefEdit] = useState(null);
  const [infoData, setInfoData] = useState(null);

  const [imageCustomer, setImageCustomer] = useState();
  const [imageFile, setImageFile] = useState();
  const [newImg, setNewImg] = useState(undefined);

  const [LocationData, setLocationData] = useState({});
  const [cusDataBodyApi, setCusDataBodyApi] = useState({});
  const [customerIdApi, setCustomerIdApi] = useState('');

  const [cusInformation, setCusInformation] = useState({});

  const [checkImgDefault, setCheckImgDefault] = useState(false);
  const [privacyData, setprivacyData] = useState();
  const [nameAuto, setNameAuto] = useState([]);
  const [visibleResetPassword, setVisibleResetPassword] = useState(false);

  useEffect(() => {
    setCusInformation(informationData);
  }, [informationData]);

  const handleEdit = () => {
    setInfoData(informationData);
    setVisibleEdit(true);
  };

  const handleCancel = () => {
    setInfoData(null);
    setVisibleEdit(false);
    formRefEdit.resetFields();
    setImageFile('');
    setImageCustomer();
    setNewImg(undefined);
    imageRef.current.value = null;
    setCheckImgDefault(false);
    setNameAuto([
      {
        name: _.get(informationData, 'createdBy'),
        memComId: Number(_.get(informationData, 'createdById')),
      },
    ]);
  };

  const handleSaveEdit = async () => {
    // ใช้ค่านี้ส่งรูป imageCustomer //
    formRefEdit.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      let cusName = infoData.customerName.includes(values.cusCode);

      try {
        const body = {
          comCode: comCode,
          customerName: values.cusName,
          customerShortName: values.cusCode,
          businessTypeId: values.busType,
          customerTypeId: values.cusType,
          address: cusDataBodyApi.description,
          fullAddress: cusDataBodyApi.address,
          locationName: cusDataBodyApi.addressName,
          lat: cusDataBodyApi.lat,
          lng: cusDataBodyApi.lng,
          // description: cusDataBodyApi.description,
          profileImg: undefined,
          phone: undefined,
          contacts: [],
          selectedPrivacy: privacyData.privacy !== undefined ? privacyData.privacy : undefined,
          privacyTeamLists: privacyData.team !== undefined ? privacyData.team : undefined,
          createdBy: values.owner,
          // addressBooks: [
          //   {
          //     customerAddressBookId: customerIdApi,
          //     addressName: cusDataBodyApi.addressName,
          //     fullAddress: cusDataBodyApi.address,
          //     address: cusDataBodyApi.address,
          //     description: cusDataBodyApi.description,
          //     lat: cusDataBodyApi.lat,
          //     lng: cusDataBodyApi.lng,
          //     district: undefined,
          //     province: undefined,
          //     postCode: undefined,
          //     subDistrict: undefined,
          //   }
          // ],
        };

        const response = await httpClient.put(
          `/v3/business-partner/manager/company/${comCode}/customer-informations/${infoData.customerId}`,
          body
        );
        if (response.status === 200) {
          successNotification(response.data.status.messageEdit);
          if (response.status && checkImgDefault == true) {
            if (imageCustomer) {
              let formData = new FormData();
              formData.append('file', imageCustomer);

              try {
                const resUploadImage = await httpClient.put(
                  `/v3/business-partner/manager/company/${comCode}/customer-informations/${infoData.customerId}`,
                  formData
                );

                setVisibleEdit(false);
                setCheckImgDefault(false);
              } catch (error) { }
            } else {
              const body = {
                profileImg: 'noimage',
              };
              try {
                const resUploadImage = await httpClient.put(
                  `/v3/business-partner/manager/company/${comCode}/customer-informations/${infoData.customerId}`,
                  body
                );

                setVisibleEdit(false);
                setCheckImgDefault(false);
              } catch (error) { }
            }
          }
          //setVisibleEdit(false);
        } else {
          errorNotification(response.data.status.messageEdit);
        }
      } catch (error) {
        // errorNotification(error.response);
        errorNotification(error.response.data.status.message);
      }

      setTriggerAddContact(!triggerAddContact);
      setImageFile('');
      setImageCustomer();
      setNewImg(undefined);
      imageRef.current.value = null;
      setVisibleEdit(false);
      formRefEdit.resetFields();
      setNameAuto([]);
    });
  };

  const saveFormRefEdit = useCallback((node) => {
    if (node !== null) {
      setFormRefEdit(node);
    }
  }, []);

  const openMap = () => {
    if (informationData && informationData !== undefined) {
      if (informationData.lat !== null && informationData.lng !== null) {
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${_.get(informationData, 'lat')}, ${_.get(informationData, 'lng')}`,
          '_blank'
        );
      }
    }
  };

  const handleDelete = async () => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'customerTableDeleteConfirmText', defaultMessage: 'Are you sure to delete customer ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await httpClient.delete(`/v3/business-partner/manager/company/${comCode}/customer-informations/${customerId}`);
          if (response.status == 200) {
            setTriggerAddContact((current) => !current);
            successNotification(response.data.status.message);
            history.push('/main/crm/customers');
          } else {
            errorNotification(response.data.status.message);
          }
        } catch (error) {
          errorNotification(error.response.data.status.message);
        }
      },
    });
  };

  const handleOpenResetPassword = () => {
    setVisibleResetPassword(true);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Card
            title={intl.formatMessage({ id: 'customerInformationCardHeader', defaultMessage: 'Customer Information' })}
            extra={
              <div>
                {checkPermissionAction('P8PG1C1', 'P8PG1C1A4') ? (
                  <Button02 style={{ margin: '0px 0px 0px 10px' }} fontColor="fc_black" onClick={handleDelete}>
                    <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                  </Button02>
                ) : null}
                {_.get(cusInformation, 'createdById') === memComId || checkPermissionAction('P8PG2C1', 'P8PG2C1A2') ? (
                  <Button01 type="primary" onClick={handleEdit}>
                    <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                  </Button01>
                ) : null}
              </div>
            }
          >
            <Row gutter={[16, 16]}>
              <Col span={5} className="avatar-alignment">
                <Avatar size={160} src={_.get(cusInformation, 'profileImg')} />
              </Col>
              <Col span={19}>
                <Row>
                  <Col span={24}>
                    <p className="customer-style">
                      {customerShort ? customerShort : null}
                      <Badge color="#1D3557" className="custom-badge" />
                      {_.get(cusInformation, 'customerName')}
                    </p>
                  </Col>
                </Row>
                <Row className="customer-text-information-padding">
                  <Col span={6}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextTeam" defaultMessage="Team" />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'Organization.orgName') || '-'}</div>
                  </Col>
                  <Col span={6}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextOwner" defaultMessage="Owner" />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'createdBy') || '-'}</div>
                  </Col>
                </Row>
                <Row className="customer-text-information-padding">
                  <Col span={6}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextBusinessType" defaultMessage="Business Type" />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'businessType.businessTypeName') || '-'}</div>
                  </Col>
                  <Col span={6}>
                    <div className="customer-text-main-style">
                      <FormattedMessage id="customerInformationTextCustomerType" defaultMessage="Customer Type" />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'customerType.customerTypeName') || '-'}</div>
                  </Col>
                </Row>
                <Row className="customer-text-information-padding">
                  <Col span={6}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextCoporateContact" defaultMessage="Corporate Contact" />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'corporateContact') || '-'}</div>
                  </Col>
                  <Col span={6}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextPhone" defaultMessage="Phone" />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'phone') || '-'}</div>
                  </Col>
                </Row>
                <Row className="customer-text-information-padding">
                  <Col span={6}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextTaxNo" defaultMessage="Tax No." />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'taxNo') || '-'}</div>
                  </Col>
                  <Col span={6}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextCreatedBy" defaultMessage="Created By" />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'createdBy')}, {_.get(cusInformation, 'createdDate')}</div>
                  </Col>
                </Row>
                <Row className="customer-text-information-padding">
                  <Col span={24}>
                  <div className="customer-text-main-style ">
                      <FormattedMessage id="customerDetailReference" defaultMessage="Reference" />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'referredPhone')}</div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <p className="address-style">
                      <FormattedMessage id="customerInformationTextAddress" defaultMessage="Address" />
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <p className="customer-text-value-style">
                      
                      <Icon
                        type="environment"
                        style={{
                          fontSize: '12px',
                          color: '#1D3557',
                          paddingLeft: '6px',
                          paddingRight: '0px',
                          cursor: 'pointer',
                        }}
                        onClick={() => openMap()}
                      />
                      {' '}
                      {address}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Divider type="horizontal" style={{ marginBottom: '12px', marginTop: '12px' }} />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div className="customer-text-main-style" style={{ marginBottom: '8px' }}>
                      <FormattedMessage id="ModalCustomerCustomerRemark" defaultMessage="Remark" />
                    </div>
                    <div className="customer-text-value-style customer-text-information-remark">
                      {_.get(cusInformation, 'description') || '-'}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ marginTop: '22px', textAlign: 'end', cursor: 'pointer' }}>
                    <p className="customer-text-main-style" onClick={handleOpenResetPassword}>
                      <FormattedMessage id="customerInformationTextSetPassword" defaultMessage="Set Password" />{' '}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>

          {/* <ModalEditCustomer
            ref={saveFormRefEdit}
            visible={visibleEdit}
            onCancel={handleCancel}
            onCreate={handleSaveEdit}
            informationData={infoData}
            image={{
              imageRef,
              imageFile,
              setImageFile,
              imageCustomer,
              setImageCustomer,
              newImg,
              setNewImg
            }}
            setCustomer={{
              setLocationData,
              setCusDataBodyApi,
              setCustomerIdApi
            }}
            setCheckImgDefault={setCheckImgDefault}
            checkImgDefault={checkImgDefault}
            setprivacyData={setprivacyData}
            privacyData={privacyData}
            nameAuto={nameAuto}
            setNameAuto={setNameAuto}
          /> */}

          <EditbusinessPartner
            visible={visibleEdit}
            setVisible={setVisibleEdit}
            setTrigger={setTriggerAddContact}
            CustomerData={{
              valuePrivacy: _.get(infoData, 'valuePrivacy'),
              Organization: { orgId: _.get(infoData, 'Organization.orgId'), orgName: _.get(infoData, 'Organization.orgName') },
              address: _.get(infoData, 'address'),
              businessType: _.get(infoData, 'businessType.businessTypeName'),
              businessTypeId: _.get(infoData, 'businessType.businessTypeId'),
              comCode: _.get(infoData, 'comCode'),
              createdAt: _.get(infoData, 'createdDate'),
              createdBy: _.get(infoData, 'createdBy'),
              createdById: _.get(infoData, 'createdById'),
              customerCode: _.get(infoData, 'customerShortName'),
              customerId: _.get(infoData, 'customerId'),
              customerName: _.get(infoData, 'customerName'),
              customerType: _.get(infoData, 'customerType.customerTypeName'),
              customerTypeId: _.get(infoData, 'customerType.customerTypeId'),
              description: _.get(infoData, 'description'),
              index: 1,
              indexDate: undefined,
              lat: _.get(infoData, 'lat'),
              lng: _.get(infoData, 'lng'),
              locationName: _.get(infoData, 'locationName'),
              phone: _.get(infoData, 'phone'),
              position: '',
              profileImg: _.get(infoData, 'profileImg'),
              profileImgDefault: _.get(infoData, 'profileImgDefault'),
              profileImgHashFile: _.get(infoData, 'profileImgHashFile'),
              taxNo: _.get(infoData, 'taxNo'),
            }}
            setCustomerData={setInfoData}
          />

          <ResetPassword id={customerId} visible={visibleResetPassword} setVisible={setVisibleResetPassword} />
        </div>
      )}
    </PageSettings.Consumer>
  );
};
