import React, { useState, useEffect, useContext, useRef } from 'react';
import { Table, Tag, Button, Popover } from 'antd';
import WarehouseMovementAction from './action';
import { FormattedMessage, useIntl } from 'react-intl';
import './css/index.css';
import NumberFormat from 'react-number-format';
import { getMovementStock, getItemsMaster, warehouseReportMovementExport } from '../../../controllers/warehouse/warehouse';
import _ from 'lodash';
import WarehouseContext from '../context';
import { useDebounce } from '../../../controllers/debounce';
import ModalFormReceiveItem from '../../../components/receive/item';
import { getItemsCode } from '../../../controllers/warehouse/warehouse';
import moment from 'moment';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import { PageSettings } from '../../../config/page-settings';

const WarehouseMovement = () => {
  const intl = useIntl();
  const ref = useRef();

  const columns = [
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnType', defaultMessage: 'Type' }),
      dataIndex: 'movementModuleTxt',
      key: 'movementModuleTxt',
      fixed: 'left',
      sorter: true,
      width: 130,
      render: (text, record, index) => (
        <Tag color={record.typeColor} className="warehouseMovementTableTag" style={{ width: '100%', textAlign: 'center' }}>
          {record.movementModuleTxt}
        </Tag>
      ),
    },
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: true,
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnItem', defaultMessage: 'Item' }),
      dataIndex: 'item',
      key: 'item',
      sorter: true,
      width: 270,
      render: (text, record, index) => record.itemName,
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnItemGroup', defaultMessage: 'Item Group' }),
      dataIndex: 'itemGroupName',
      key: 'itemGroupName',
      sorter: true,
      width: 270,
      render: (text, record, index) => record.itemGroupName,
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnItemType', defaultMessage: 'Item Type' }),
      dataIndex: 'itemTypeName',
      key: 'itemTypeName',
      sorter: true,
      width: 270,
      render: (text, record, index) => record.itemTypeName,
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnlotOdSerialNo', defaultMessage: 'Lot No. or Serial No.' }),
      dataIndex: 'lotOrSerialNo',
      key: 'lotOrSerialNo',
      sorter: true,
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnCost', defaultMessage: 'Cost' }),
      dataIndex: 'cost',
      key: 'cost',
      sorter: true,
      width: 150,
      render: (text, record, index) => record.cost,
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      width: 150,
      render: (text, record, index) => (
        <span style={record.qtyColor ? { color: record.qtyColor } : null}>
          <NumberFormat value={parseFloat(record.qty)} displayType={'text'} thousandSeparator={true} />{' '}
          {/* {parseFloat(record.qty) > 1 ? 'Items' : 'Item'} */}
        </span>
      ),
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnUoM', defaultMessage: 'UoM' }),
      dataIndex: 'uomName',
      key: 'uomName',
      sorter: true,
      width: 150,
      render: (text, record, index) => record.uomName,
    },

    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnAmount', defaultMessage: 'Amount' }),
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      width: 150,
      render: (text, record, index) => record.amount,
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnmanufacturingDate', defaultMessage: 'Manufacturing Date' }),
      dataIndex: 'manufacturingDate',
      key: 'manufacturingDate',
      sorter: true,
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnExpiredDate', defaultMessage: 'Expired Date' }),
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      sorter: true,
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnFDA', defaultMessage: 'FDA' }),
      dataIndex: 'fda',
      key: 'fda',
      sorter: true,
      width: 150,
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnReferenceType', defaultMessage: 'Reference Type' }),
      dataIndex: 'referenceType',
      key: 'referenceType',
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnReference', defaultMessage: 'Reference No.' }),
      dataIndex: 'referenceNo',
      key: 'referenceNo',
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnactionBy', defaultMessage: 'Action By' }),
      dataIndex: 'actionBy',
      key: 'actionBy',
      sorter: true,
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnActionDate', defaultMessage: 'Action Date' }),
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnVendororSupplier', defaultMessage: 'Vendor or Supplier' }),
      dataIndex: 'vendorSupplier',
      key: 'vendorSupplier',
      sorter: true,
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'warehouseMovementColumnMovementType', defaultMessage: 'Movement Type' }),
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      width: 180,
    },
  ];

  const setShowColumn = {
    index: true,
    itemCode: true,
    item: true,
    movementModuleTxt: true,
    lotOrSerialNo: true,
    qty: true,
    uomName: true,
    cost: true,
    amount: false,
    manufacturingDate: false,
    expiredDate: true,
    fda: false,
    referenceNo: false,
    actionBy: true,
    date: true,
    itemGroupName: false,
    itemTypeName: false,
    referenceType: false,
    vendorSupplier: false,
    type: false,
  };

  const setShowColumnArr = [
    'index',
    'itemCode',
    'movementModuleTxt',
    'item',
    'itemGroupName',
    'itemTypeName',
    'lotOrSerialNo',
    'qty',
    'uomName',
    'cost',
    'amount',
    'manufacturingDate',
    'expiredDate',
    'fda',
    'referenceNo',
    'referenceType',
    'actionBy',
    'date',
    'vendorSupplier',
    'type',
  ];
  const { state } = useContext(WarehouseContext);
  const { warehouseId, key, roleCheck } = state;
  const ignoreColumn = ['itemGroupName', 'itemTypeName', 'referenceType', 'referenceNo', 'type', 'vendorSupplier', 'manufacturingDate', 'fda'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [searchGroup, setSearchGroup] = useState({});
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [movementData, setMovementData] = useState([]);
  const debounceSearch = useDebounce(searchGroup, 500);
  const [total, setTotal] = useState(0);
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [visibleModalItem, setVisibleModalItem] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [selectItem, setSelectItem] = useState();
  const [itemCodeList, setItemCodeList] = useState([]);
  const [searhByCode, setSearchByCode] = useState('');
  const debounceCode = useDebounce(searhByCode, 500);
  const [itemNameList, setItemNameList] = useState([]);
  const [searchByName, setSearchByName] = useState('');
  const debounceName = useDebounce(searchByName, 500);
  const [numbertypeList, setNumbertypeList] = useState([]);
  const [movementType, setMovementType] = useState([]);
  const [viewData, setViewData] = useState();
  const [refTypeList, setRefTypeList] = useState([]);
  const [resetEvet, setResetEvet] = useState(true);
  const [changeWarhouse, setChangeWarhouse] = useState('');
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const body = {
          warehouseId: warehouseId,
          itemCode: _.get(selectItem, 'itemCode'),
          itemName: _.get(selectItem, 'itemName'),
          type: _.get(debounceSearch, 'type'),
          referenceType: _.get(debounceSearch, 'referenceType') ? _.get(debounceSearch, 'referenceType') : undefined,
          referenceNo: _.get(debounceSearch, 'referenceNo') ? _.get(debounceSearch, 'referenceNo') : undefined,
          expiredDateFrom:
            _.get(debounceSearch, 'expiredDate') && _.size(debounceSearch.expiredDate) !== 0
              ? moment(_.get(debounceSearch, 'expiredDate[0]')).format('YYYY-MM-DD')
              : undefined,
          expiredDateTo:
            _.get(debounceSearch, 'expiredDate') && _.size(debounceSearch.expiredDate) !== 0
              ? moment(_.get(debounceSearch, 'expiredDate[1]')).format('YYYY-MM-DD')
              : undefined,
          lotSerialNo: _.get(debounceSearch, 'lotNo'),
          costFrom: checkCost(checkData(_.get(debounceSearch, 'minimum')), checkData(_.get(debounceSearch, 'maximum')), 'from'),
          costTo: checkCost(checkData(_.get(debounceSearch, 'minimum')), checkData(_.get(debounceSearch, 'maximum')), 'to'),
          movementDateFrom:
            _.get(debounceSearch, 'movementDate') && _.size(debounceSearch.movementDate) !== 0
              ? moment(_.get(debounceSearch, 'movementDate[0]')).format('YYYY-MM-DD')
              : undefined,
          movementDateTo:
            _.get(debounceSearch, 'movementDate') && _.size(debounceSearch.movementDate) !== 0
              ? moment(_.get(debounceSearch, 'movementDate[1]')).format('YYYY-MM-DD')
              : undefined,
          pageNumber: warehouseId !== changeWarhouse ? 1 : paginationPage,
          limit: warehouseId !== changeWarhouse ? 10 : paginationSize,
          orderBy: fieldSort,
          orderType: orderSort,
        };
        const response = await getMovementStock(body);
        if (_.get(response, 'data.status.code') === 200) {
          setLoading(false);
          setChangeWarhouse(warehouseId);
          setMovementData(_.map(_.get(response, 'data.data.list'), (item, index) => {
            return {
              ...item,
              index: (paginationPage - 1) * paginationSize + index + 1
            }
          }));
          setTotal(_.get(response, 'data.data.totalItems'));
          setNumbertypeList(_.get(response, 'data.data.numberTypeList'));
          setMovementType(_.get(response, 'data.data.movementTypeList'));
          setRefTypeList(_.get(response, 'data.data.referenceTypeList'));
        }
      } catch (error) { }
    }
    if (warehouseId && key === 'movement') {
      getData();
    }
  }, [debounceSearch, paginationPage, paginationSize, fieldSort, orderSort, trigger, warehouseId, key]);

  useEffect(() => {
    if (warehouseId && key === 'movement') {
      setPaginationPage(1);
      setPaginationSize(10);
    }
  }, [warehouseId])


  const checkData = (value) => {
    if (value && value !== '') {
      return value;
    } else {
      return undefined;
    }
  };

  const checkCost = (from, to, type) => {
    const checkFrom = from ? parseFloat(from) : 0;
    const checkTo = to ? parseFloat(to) : 0;
    if (type === 'from') {
      if (from && to) {
        return checkFrom;
      } else if (from) {
        return 0;
      } else if (to) {
        return 0;
      } else {
        return undefined;
      }
    }
    if (type === 'to') {
      if (from && to) {
        return checkTo;
      } else if (from) {
        return checkFrom;
      } else if (to) {
        return checkTo;
      } else {
        return undefined;
      }
    }
  };

  useEffect(() => {
    const getItemCode = async () => {
      const payload = {
        code: searhByCode,
      };
      const response = await getItemsCode(payload);
      const list = _.get(response.data.data, 'itemMasterList');
      setItemCodeList(_.uniqBy(_.concat(itemCodeList, list), 'itemMasterId'));
    };

    getItemCode();
  }, [debounceCode]);

  useEffect(() => {
    const getItemCode = async () => {
      const payload = {
        name: searchByName,
      };
      const response = await getItemsMaster(payload);
      const list = _.get(response.data.data, 'itemMasterList');
      setItemNameList(_.uniqBy(_.concat(itemNameList, list), 'itemMasterId'));
    };

    getItemCode();
  }, [debounceName]);

  const handleSearchCode = _.debounce((value) => {
    setSearchByCode(value || '');
  }, 500);

  const handleSearchName = _.debounce((value) => {
    setSearchByName(value || '');
  }, 500);

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setFieldSort(field);
    setPaginationSize(pagination.pageSize);
    setPaginationPage(pagination.current);
  };

  const handleOpenModal = (type) => {
    if (type === 'item') setVisibleModalItem(true);
  };

  const handleExport = async () => {
    // setDisabled(true);
    try {
      const body = {
        warehouseId: warehouseId,
        itemCode: _.get(selectItem, 'itemCode'),
        itemName: _.get(selectItem, 'itemName'),
        type: _.get(debounceSearch, 'type'),
        referenceType: _.get(debounceSearch, 'referenceType') ? _.get(debounceSearch, 'referenceType') : undefined,
        referenceNo: _.get(debounceSearch, 'referenceNo') ? _.get(debounceSearch, 'referenceNo') : undefined,
        expiredDateFrom:
          _.get(debounceSearch, 'expiredDate') && _.size(debounceSearch.expiredDate) !== 0
            ? moment(_.get(debounceSearch, 'expiredDate[0]')).format('YYYY-MM-DD')
            : undefined,
        expiredDateTo:
          _.get(debounceSearch, 'expiredDate') && _.size(debounceSearch.expiredDate) !== 0
            ? moment(_.get(debounceSearch, 'expiredDate[1]')).format('YYYY-MM-DD')
            : undefined,
        lotSerialNo: _.get(debounceSearch, 'lotNo'),
        costFrom: checkCost(checkData(_.get(debounceSearch, 'minimum')), checkData(_.get(debounceSearch, 'maximum')), 'from'),
        costTo: checkCost(checkData(_.get(debounceSearch, 'minimum')), checkData(_.get(debounceSearch, 'maximum')), 'to'),
        movementDateFrom:
          _.get(debounceSearch, 'movementDate') && _.size(debounceSearch.movementDate) !== 0
            ? moment(_.get(debounceSearch, 'movementDate[0]')).format('YYYY-MM-DD')
            : undefined,
        movementDateTo:
          _.get(debounceSearch, 'movementDate') && _.size(debounceSearch.movementDate) !== 0
            ? moment(_.get(debounceSearch, 'movementDate[1]')).format('YYYY-MM-DD')
            : undefined,
        pageNumber: 1,
        limit: total,
        orderBy: fieldSort,
        orderType: orderSort,
        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnMovementType', defaultMessage: 'Movement Type' }),
            sequence: 1,
            colCode: 'type',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnItemCode', defaultMessage: 'Item Code' }),
            sequence: 2,
            colCode: 'itemCode',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnItemGroup', defaultMessage: 'Item Group' }),
            sequence: 3,
            colCode: 'itemGroupName',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnItemTypeName', defaultMessage: 'Item Type Name' }),
            sequence: 4,
            colCode: 'itemTypeName',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnItemName', defaultMessage: 'Item Name' }),
            sequence: 5,
            colCode: 'itemName',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnlotOdSerialNo', defaultMessage: 'Lot No. or Serial No.' }),
            sequence: 6,
            colCode: 'lotOrSerialNo',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnQty', defaultMessage: 'Qty' }),
            sequence: 7,
            colCode: 'qty',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnCost', defaultMessage: 'Cost' }),
            sequence: 8,
            colCode: 'cost',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnAmount', defaultMessage: 'Amount' }),
            sequence: 9,
            colCode: 'amount',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnExpiredDate', defaultMessage: 'Expired Date' }),
            sequence: 10,
            colCode: 'expiredDate',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnReferenceType', defaultMessage: 'Reference Type' }),
            sequence: 11,
            colCode: 'referenceType',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnReference', defaultMessage: 'Reference No.' }),
            sequence: 12,
            colCode: 'referenceNo',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnactionBy', defaultMessage: 'Action By' }),
            sequence: 13,
            colCode: 'actionBy',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnUoM', defaultMessage: 'UoM' }),
            sequence: 14,
            colCode: 'uomName',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnActionDate', defaultMessage: 'Action Date' }),
            sequence: 15,
            colCode: 'date',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnVendororSupplier', defaultMessage: 'Vendor or Supplier' }),
            sequence: 16,
            colCode: 'vendorSupplier',
          },
          {
            colName: intl.formatMessage({ id: 'warehouseMovementColumnType', defaultMessage: 'Type' }),
            sequence: 17,
            colCode: 'movementModuleTxt',
          },
        ],
      };
      // const response = await httpClient.post(`/v1/resource/manager/company/${state.comId}/export/report/overtime`, body);
      const response = await warehouseReportMovementExport(body);

      console.log('exportMovement', response.data);

      window.open(response.data.data.url);
      successNotification(response.data.status.message);
      // setDisabled(false);
    } catch (error) {
      errorNotification(error.response.data.status.message);
    }
  };

  return (
    <div className='scroll-sm' style={{ overflowY: 'scroll', height: 'calc(100vh - 180px)' }}>
      <div>
        <WarehouseMovementAction
          searchGroup={searchGroup}
          setSearchGroup={setSearchGroup}
          setPaginationPage={setPaginationPage}
          columns={columns}
          setShowColumn={setShowColumn}
          newDataColumns={newDataColumns}
          listArrayColumns={listArrayColumns}
          defaultShowColumn={defaultShowColumn}
          newColumns={newColumns}
          setNewDataColumns={setNewDataColumns}
          setListArrayColumns={setListArrayColumns}
          setDefaultShowColumn={setDefaultShowColumn}
          textErrorSelectColumn={textErrorSelectColumn}
          setTextErrorSelectColumn={setTextErrorSelectColumn}
          visible={visible}
          setVisible={setVisible}
          handleOpenModal={handleOpenModal}
          roleCheck={roleCheck}
          fncForm={{
            handleSearchCode,
            itemCodeList,
            selectItem,
            setSelectItem,
            handleSearchName,
            itemNameList,
            movementType,
            numbertypeList,
            refTypeList,
          }}
          handleExport={handleExport}
        />
      </div>
      <div className="warehouseMovementTableContent">
        <Table
          className="table-global-scroll"
          columns={newDataColumns}
          dataSource={movementData}
          scroll={{ x: _.sumBy(newDataColumns, 'width') }}
          // scroll={{ x: _.sumBy(newDataColumns, 'width'), y: `calc(100vh - 360px)` }}
          onChange={handleChange}
          pagination={{
            total: total,
            current: paginationPage,
            showTotal: showTotal,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
          }}
        />
        {_.size(movementData) > 0 ? (
          <div className="total-items-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
      <ModalFormReceiveItem
        visible={visibleModalItem}
        setVisible={setVisibleModalItem}
        warehouseId={warehouseId}
        numbertypeList={numbertypeList}
        setTrigger={setTrigger}
        viewData={viewData}
        setViewData={setViewData}
      />
    </div>
  );
};

export default WarehouseMovement;
