import React, { useState, useMemo, useEffect } from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Col,
  Row,
  TimePicker
} from 'antd';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  successNotification,
  errorNotification,
} from '../v2/notification';
import _, { debounce } from 'lodash';
import './css/index.css';
import httpClient from '../axiosClient';
import getSearchLocation from '../../function/search-location/getLocation';
import Map from './map-location';
import moment from 'moment';
import { useAppContext } from '../../includes/indexProvider';

const { Option } = Select;
let id = 2;
let count = 0

const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <span>
      {text}&nbsp;
      {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
    </span>
  );
};

const ModalMapLocation = React.memo((props) => {
  const intl = useIntl();
  const { visible, form, setVisible, customerId, customerName, setTrigger, triggerFNC, toLocation, filterToLocation, fncUpdateAddressBook } = props;
  const { getFieldDecorator, validateFields, resetFields, getFieldValue } = form;
  const comCode = localStorage.getItem('comCode');
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  const [position, setPosition] = useState(latlng);
  const [center, setCenter] = useState(latlng);
  const [addressArea, setAddressArea] = useState([]);
  const [latlngTo, setLatlngTo] = useState({});
  const [valueAddress, setValueAddress] = useState("");
  const [locationMyself, setLocationMyself] = useState()
  const [textError, setTextError] = useState('')
  const format = 'HH:mm';
  const [autoComplteLocation, setAutoComplteLocation] = useState();

  const handleSearchLocation = debounce(async (fieldChang) => {
    let newData = await getSearchLocation({ name: fieldChang });
    setAddressArea(newData);
  }, 200);

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(positionMyself);
  //   }
  // }, [])

  useEffect(() => {
    if (latlng) {
      setPosition(latlng)
      setCenter(latlng)
      setLocationMyself()
    }
  }, [latlng])

  // const positionMyself = (position) => {
  //   if (position && position !== null && position !== undefined) {
  //     setPosition({
  //       lat: _.get(position, 'coords.latitude'),
  //       lng: _.get(position, 'coords.longitude'),
  //     })
  //     setCenter({
  //       lat: _.get(position, 'coords.latitude'),
  //       lng: _.get(position, 'coords.longitude'),
  //     })
  //     setLocationMyself(position)

  //   } else {

  //   }

  // }

  const handleSelect = (key, option) => {
    if (isNaN(key) == false) {
      form.setFieldsValue({ ['location']: option.props.children });
      setAutoComplteLocation(option.props.children)
      form.setFieldsValue({ ['description']: option.props.children });
      setPosition({ lat: addressArea[key].lat, lng: addressArea[key].lng });
      setCenter({ lat: addressArea[key].lat, lng: addressArea[key].lng });
      setLatlngTo({ lat: addressArea[key].lat, lng: addressArea[key].lng });
    } else {
      setPosition(latlng);
      setCenter(latlng);
      form.resetFields();
      form.setFieldsValue({
        ['location']: undefined
      });
      setAutoComplteLocation()
    }
  };

  useMemo(() => {

    form.setFieldsValue({
      ['location']: valueAddress
    });
    form.setFieldsValue({
      ['description']: valueAddress
    });
    setAutoComplteLocation(valueAddress)
  }, [valueAddress]);

  const children = addressArea.map(item =>
    <Option key={item.key}>
      {item.address}
    </Option>
  );


  const handleCreate = () => {
    validateFields(async (err, values) => {

      if (err) {
        return;
      }

      const keys = _.get(values, 'keys')
      const startTime = _.get(values, 'startTime')
      const endTime = _.get(values, 'endTime')
      const mapValues = keys.map((item, index) => {
        return {
          startTime: startTime[index] !== undefined ? moment(startTime[index]).format('HH:mm') : undefined,
          endTime: endTime[index] !== undefined ? moment(endTime[index]).format('HH:mm') : undefined
        }
      });

      const filterValues = mapValues && mapValues.filter((item, index) => {
        return item.startTime !== undefined || item.endTime !== undefined
      });

      const body = {
        customerId: customerId,
        addressName: values.locationName,
        customerName: customerName,
        contactName: undefined,
        fullAddress: autoComplteLocation,
        address: values.description,
        subDistrict: undefined,
        district: undefined,
        province: undefined,
        postCode: undefined,
        lat: latlngTo.lat,
        lng: latlngTo.lng,
        serviceTime: filterValues
      }

      try {
        const response = await httpClient.post(
          `/v3/business-partner/manager/company/${comCode}/customer-address-books`,
          body,
        );

        if (response.status == 200) {
          resetFields();
          setVisible(false);
          setLatlngTo({});
          setValueAddress("");
          setTextError('')
          if (setTrigger) {
            setTrigger(current => !current);
          }
          if (triggerFNC) {
            triggerFNC(customerId)
          }
          setTimeout(() => {
            if (toLocation) {
              toLocation(response.data.data)
            }
            if (filterToLocation) {
              filterToLocation(response.data.data)
            }
            if (fncUpdateAddressBook) {
              fncUpdateAddressBook(customerId, response)
            }
          }, 300);

          successNotification(response.data.status.message);
          setAutoComplteLocation()
        } else {
          resetFields();
          setVisible(false);
          setLatlngTo({});
          setValueAddress("");

        }

      } catch (error) {
        resetFields();
        setVisible(false);
        setLatlngTo({});
        setValueAddress("");
        errorNotification(error.response.data.status.message);
      }

      if (locationMyself) {
        setPosition({
          lat: _.get(locationMyself, 'coords.latitude'),
          lng: _.get(locationMyself, 'coords.longitude'),
        })
        setCenter({
          lat: _.get(locationMyself, 'coords.latitude'),
          lng: _.get(locationMyself, 'coords.longitude'),
        })
      } else {
        setPosition(latlng)
        setCenter(latlng)
      }

    });
  };

  const handleCreateCancel = () => {
    resetFields();
    setVisible(false);
    setLatlngTo({});
    setValueAddress("");
    setTextError('')
    setAutoComplteLocation()
    if (locationMyself) {
      setPosition({
        lat: _.get(locationMyself, 'coords.latitude'),
        lng: _.get(locationMyself, 'coords.longitude'),
      })
      setCenter({
        lat: _.get(locationMyself, 'coords.latitude'),
        lng: _.get(locationMyself, 'coords.longitude'),
      })
    } else {
      setPosition(latlng)
      setCenter(latlng)
    }
  }


  const handleClickAdd = () => {
    const nextKeys = keys.concat(id++);
    form.setFieldsValue({
      keys: nextKeys,
    });
    count++
  }

  const handleClickDelete = k => {
    const keys = form.getFieldValue('keys');
    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  }


  getFieldDecorator('keys', { initialValue: [1] });
  const keys = getFieldValue('keys');
  const keysLength = keys.length;

  const formItems = keys && keys.map((k, index) => {

    const validatorCheckStartTime = (rule, value, callback) => {
      if (value) {
        const endTime = getFieldValue(`endTime[${index}]`);
        const momentStartTime = moment(value, 'HH:mm').format('HH:mm')
        const momentEndTime = moment(endTime, 'HH:mm').format('HH:mm')
        if (momentStartTime === momentEndTime) {
          callback('The time period cannot be equal');
        } else {
          callback();
        }
      } else {
        callback();

      }
    }

    const validatorCheckEndTime = (rule, value, callback) => {
      if (value) {
        const startTime = getFieldValue(`startTime[${index}]`);
        const momentStartTime = moment(startTime, 'HH:mm').format('HH:mm')
        const momentEndTime = moment(value, 'HH:mm').format('HH:mm')

        if (momentStartTime === momentEndTime) {
          callback('The time period cannot be equal');
        } else {
          callback();
        }
      } else {
        callback();

      }
    }

    return (
      <div>
        <Row>
          <Col span={5}>
            <Form.Item
              key={index}
            >
              {getFieldDecorator(`startTime[${index}]`, {
                rules: [
                  {
                    validator: validatorCheckStartTime
                  }
                ],
              })(<TimePicker format={format} style={{ width: '100%' }} allowClear={false}
                placeholder={intl.formatMessage({ id: 'ModalLocationPlaceHolderServiceTime', defaultMessage: 'Select Time' })} />)}
            </Form.Item>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            -
          </Col>
          <Col span={5}>
            <Form.Item
              key={index}
            >
              {getFieldDecorator(`endTime[${index}]`, {
                rules: [
                  {
                    validator: validatorCheckEndTime
                  }
                ],
              })(<TimePicker format={format} style={{ width: '100%' }} allowClear={false}
                placeholder={intl.formatMessage({ id: 'ModalLocationPlaceHolderServiceTime', defaultMessage: 'Select Time' })} />)}
            </Form.Item>
          </Col>
          <Col span={1}>
          </Col>
          <Col span={10}>
            {
              index === keysLength - 1 ? (
                <Button onClick={handleClickAdd} style={{ width: '74px' }}
                  disabled={getFieldValue(`startTime[${index}]`) && getFieldValue(`endTime[${index}]`) ? false : true}
                > <FormattedMessage id="btnAdd" defaultMessage="Add" /></Button>
              ) : (
                <Button onClick={() => handleClickDelete(k)} style={{ width: '74px' }}><FormattedMessage id="btnDelete" defaultMessage="Delete" /></Button>
              )
            }
          </Col>
        </Row>
      </div >
    )
  }

  );


  return (
    <Modal
      className="create-task-modal"
      title={intl.formatMessage({ id: 'ModalLocationHeaderCreateToLocation', defaultMessage: 'Select To Location' })}
      bodyStyle={{ height: 'calc(100vh - 230px)', maxHeight: '690px', overflow: 'auto', overflowX: 'hidden' }}
      visible={visible}
      onCancel={handleCreateCancel}
      onOk={handleCreate}
      centered
      width={824}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCreateCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={handleCreate}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item
          label={<LabeRequire text={intl.formatMessage({ id: 'ModalLocationLocationName', defaultMessage: 'Location Name' })} req={true} />}
        >
          {getFieldDecorator('locationName', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalLocationValidateLocationName', defaultMessage: 'Please enter location name' })
              },
            ],
          })(
            <Input placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderLocationName', defaultMessage: 'Enter Location Name' })} autoComplete="off" />
          )}
        </Form.Item>

        <Form.Item label={<LabeRequire text={intl.formatMessage({ id: 'ModalLocationLocationAddress', defaultMessage: 'Address' })} req={true} />}>
          {getFieldDecorator(
            'description', {
            initialValue: valueAddress && valueAddress !== undefined ? valueAddress : undefined,
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalLocationLocationValidateAddress', defaultMessage: 'Please enter address' })
              },
            ],
          }
          )(
            <Input placeholder={intl.formatMessage({ id: 'ModalLocationLocationPlaceholderAddress', defaultMessage: 'Enter Address' })} autoComplete="off" ></Input>
          )}
        </Form.Item>
        <div>
          <Map
            idMap={1}
            mapModal={1}
            position={position}
            center={center}
            setPosition={setPosition}
            setCenter={setCenter}
            setLatlngTo={setLatlngTo}
            setValueAddress={setValueAddress}
            addressArea={addressArea}
            handleSearchLocation={handleSearchLocation}
            handleSelect={handleSelect}
            children={children}
          />
        </div>
        <p style={{ marginTop: '16px', marginBottom: '8px' }}><FormattedMessage id="ModalLocationServiceTimeText" defaultMessage="Service Time" /></p>
        {
          formItems
        }
        {/* {
          checkDupTime ? (
            <span>Duppppppp</span>
          ) : null
        } */}
        <p style={{ fontSize: '14px', color: '#f5222d' }}>{textError}</p>
      </Form>
    </Modal>
  )
})

const ModalMap = Form.create({
  name: 'modal_form',
})(ModalMapLocation);

export default React.memo(ModalMap);