import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Divider, Form, Modal, Tabs, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_02 from '../../../../components/v2/button_02';
import Button_01 from '../../../../components/v2/button_01';
import '../css/index.css';
import { messageLabel } from '../../../../components/message-component';
import PriceForm from './price-form';
import { StickyContainer } from 'react-sticky';
import DetailsTab from './paid/detail-paid';
import PaymentTab from './payment-tab';
import CampainTab from './campain-tab';
import UploadFileModal from '../upload-file/upload-modal';
import { AccountReceiveContext } from '../../context';
import _ from 'lodash';
import ModalCreateListItem from '../list-item/add';
import ListItem from '../list-item/list/index';
import moment from 'moment';
import ModalSelectCustomerForm from '../select-customer/customer-modal';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import PaymentTabView from './view/payment-pending';
import CampainTabView from './view/campain-pending';
import ModalApproveForm from '../approve/approve-modal';
import ModalCancelForm from '../cancel/cancel-modal';
import ModalRejectForm from '../reject/reject-modal';
import ModalWaitingForm from '../waiting/waiting-modal';
import ModalDraftForm from '../delete/delete-modal';
import ModalSelectQuotationForm from '../select-quotation/quotation-select-modal';
import DetailsTabPending from './view/detail-pending';
import DetailsTabPaid from './paid/detail-paid';
import ModalCashjForm from './payment/cash';
import ModalCancelFormPaid from '../cancel/cancel-modal-paid';
import ModalBankForm from './payment/banktranfer';
import ModalCreditARForm from './payment/creaditcard';
import ModalChequeARForm from './payment/cheque';
import ModalVoucherARForm from './payment/voucher';
import ModalAddVoucher from './payment/modelvoucher/voucherselect';
import { uploadARAttachmentPaid } from '../../../../controllers/account-receive';
import { uploadBLAttachmentPaid } from '../../../../controllers/billing-note/billingapi';

const { TabPane } = Tabs;

const PaidModal = ({ form, title, visible, onOk, onCancel, attData, setAttData, attDatainvoice, saleId, recordAR ,blNo ,typeChecked}) => {
  const { state, setState, func } = useContext(AccountReceiveContext);
  const {
    initialMemberList,
    initialTaxList,
    initialReasonList,
    visibleCancelPaid,
    visibleCash,
    visiblePaid,
    paidData,
    cancelItemData,
    openUploadPaid,
    attachmentPaidData,
    typeAttachment,
    defaultAttachment,
    visibleBank,
    visibleCredit,
    visibleCheque,
    visibleVoucher,
    typepatment,
    visibleSelectVoucher,
    tempVoucher,
    tempVoucherBrand,
    tempVoucherModel,
    tempVoucherItem,
  } = state;
  const {
    setAttachmentPaidData,
    setPaidData,
    setVisibleCash,
    setOpenUploadPaid,
    setVisibleSelectVoucher,
    setTempVoucher,
    setTempVoucherBrand,
    setTempVoucherModel,
    setTempVoucherItem,
  } = setState;
  const {
    handleOpenModalUpload,
    handleSaveDelete,
    handleSavePayment,
    handleOpenCash,
    handleCancelCash,
    handleCloseCancelPaid,
    handleOpenCancelPaid,
    handleOpenModalUploadPaid,
    handleCancelBank,
    handleOpenBank,
    handleOpenCredit,
    handleCancelCredit,
    handleOpenCheque,
    handleCancelCheque,
    handleOpenVoucher,
    handleCancelVoucher,
    openVoucherListModal,
  } = func;
  const [dataForm, setDataForm] = useState();
  const [tabkeys, setTabkeys] = useState('detail');

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [selectItem, setSelectItem] = useState([]);
  const [createData, setCreateData] = useState();
  const [receiveData, setReceiveData] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenQt, setIsOpenQt] = useState(false);
  const [paymentItem, setPaymentItem] = useState([]);
  const [paidamount, setPaidamount] = useState(0);
  const [totalCal, setTotalCal] = useState(0);
  const langValue = localStorage.getItem('langValue');

  console.log('QuotationModal dataForm', dataForm);

  const intl = useIntl();
  const { setFieldsValue, resetFields } = form;
  const formCustomerRef = useRef();

  console.log('formCustomerRef', paidData);

  useEffect(() => {
    if (paidData) {
      setDataForm(paidData);
      let totalR = 0;
      _.map(_.get(paidData, 'paymentMethod'), (o, i) => {
        if (_.get(o, 'statusCode') === 'success') {
          return (totalR = totalR + _.get(o, 'detail.amount'));
        }
      });
      const itemList = _.map(_.get(paidData, 'paymentMethod'), (o, i) => ({
        ...o,
        index: i + 1,
      }));

      setReceiveData(totalR);
      setSelectItem(itemList);

      const setobj = _.map(_.get(paidData, 'attachmentPaid'), (o, i) => ({
        ...o,
        index: i + 1,
        attachmentName: _.get(o, 'fileHash'),
        remark: _.get(o, 'remark'),
      }));
      console.log('objpaidatt', setobj);
      setAttData(setobj);
    }
  }, [paidData]);

  useEffect(() => {
    setTempVoucherItem(_.get(paidData, 'itemTotalToPay'));
    setTempVoucherBrand(_.get(paidData, 'groupBrandTotalPaid'));
    setTempVoucherModel(_.get(paidData, 'groupModelTotalPaid'));
  }, [visiblePaid]);

  useEffect(() => {
    console.log('totalcal', paidData);
    if (_.size(paidData) > 0) {
      console.log('totalcal', totalCal, '/', _.get(paidData, 'total'), '/', parseInt(receiveData));
      let cal = _.get(paidData, 'total') - parseFloat(receiveData);
      setTotalCal(parseFloat(cal).toFixed(2));
    }
  }, [receiveData, visiblePaid]);

  const handleSave = (status) => {
    console.log('handleSave', dataForm, status, attData);

    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const filterMember = _.filter(initialMemberList, (e) => {
        return e.memComId === _.get(values, 'receiveById');
      });

      let att = await Promise.all(
        _.map(attData, async (p) => {
          const formData = new FormData();
          formData.append('file', _.get(p, 'fileRaw') ?  _.get(p, 'fileRaw') : _.get(p, 'fileHash'));
          formData.append('billingId', _.get(dataForm, 'billingId'));
          formData.append('remark', _.get(p, 'remark') === undefined ? '' : _.get(p, 'remark'));
          const reaspon = await uploadBLAttachmentPaid(formData);

          const payload = { fileHash: _.get(reaspon, 'data.fileHash') ? _.get(reaspon, 'data.fileHash') : _.get(p, 'fileHash') , remark: _.get(p, 'remark') };
          return payload;
        })
      );

      const payloads = {
        billingId: _.get(dataForm, 'billingId'),
        remarkPaid: status === 'paid' ? _.get(dataForm, 'remarkPaid') : _.get(values, 'remark'),
        receiveById: status === 'paid' ? _.get(dataForm, 'receiveById') : _.get(values, 'receiveById'),
        receiveDate: status === 'paid' ? _.get(dataForm, 'receiveDate') : moment(_.get(values, 'receiveDate')).format('YYYY-MM-DD'),
        receiveBy: status === 'paid' ? _.get(dataForm, 'receiveDate') : _.get(filterMember, (filterMember, '[0].fullname')),
        attachmentPaid: att,
        paymentMethodList: selectItem,
        code: _.get(dataForm, 'code'),
      };

      // console.log('savex values', att);
      console.log('savex payload', payloads);
      onOk(payloads);
      handleCancelQuotation();
    });
  };

  const openCancelItem = (value) => {};

  const cancelItem = (value, resaonid, remark, reasontext) => {
    console.log('cancelValue', value, resaonid, remark);
    const itemList = _.map(selectItem, (o) => {
      if (o.id !== undefined && o.id === _.get(value, 'id')) {
        console.log('cancelValue1');
        return { ...o, reasonId: resaonid, reasonTxt: reasontext, statusCode: 'canceled', statusTxt: langValue === 'TH' ? 'ยกเลิก' : 'Canceled' };
      } else if (o.paymentId !== undefined && o.paymentId === _.get(value, 'paymentId')) {
        console.log('cancelValue2');
        return { ...o, reasonId: resaonid, reasonTxt: reasontext, statusCode: 'canceled', statusTxt: langValue === 'TH' ? 'ยกเลิก' : 'Canceled' };
      } else {
        console.log('cancelValue3');
        return o;
      }
    });
    console.log('cancelValueSSS', itemList);
    setReceiveData(parseInt(receiveData) - parseInt(_.get(value, 'detail.amount')));
    setSelectItem(itemList);
    handleCloseCancelPaid();
  };

  const handleCancelQuotation = () => {
    onCancel();
    resetFields();
    setDataForm();
    setCreateData();
    setAttData([]);
    setSelectItem([]);
    setTempVoucher([]);
    setTempVoucherBrand([]);
    setTempVoucherModel([]);
    setTempVoucherItem([]);
    setPaymentItem([]);
  };

  const callback = (key) => {
    setTabkeys(key);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleOk = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const payload = {
          id: _.get(paidData, 'billingId'),
          code: _.get(paidData, 'code'),
          status: 'deleted',
        };
        handleSaveDelete(payload);
      },
      onCancel() {},
    });
  };

  const handleTest = () => {
    console.log('paidTotal', _.get(paidData, 'itemTotalToPay'));
    console.log('paidallmodal', _.get(paidData, 'groupModelTotalPaid'));
    console.log('paidBrand', _.get(paidData, 'groupBrandTotalPaid'));
    console.log('paidVoucher', paymentItem, 'model', _.get(paymentItem, 'model.[0]'));
    console.log('paidTemp:', tempVoucher, 'paidTempBrand:', tempVoucherBrand, 'paidTempModel:', tempVoucherModel, 'item', tempVoucherItem);
    console.log('tempVoucher', tempVoucher);

    const filteritemmodel = _.filter(tempVoucherItem, (e) => {
      return e.itemModelId === _.get(paymentItem, 'model.[0]');
    });

    let countModel = 0;

    const plusTotalModel = _.map(filteritemmodel, (e) => {
      return (countModel = countModel + e.total);
    });

    console.log('paidmpulsmodel', countModel);

    const filterItemUpdateModel = _.filter(tempVoucher, (item, index) => {
      if (plusTotalModel - _.get(paymentItem, 'voucherBalance') > 0) {
        return item.voucherId !== _.get(paymentItem, 'voucherId');
      } else {
        return _.get(item, 'model.[0]') !== _.get(paymentItem, 'model.[0]');
      }
    });

    const filteritemBrand = _.filter(tempVoucherItem, (e) => {
      return e.itemBrandId === _.get(paymentItem, 'brand.[0]');
    });

    let countBrand = 0;

    const plusTotalBrand = _.map(filteritemBrand, (e) => {
      return (countBrand = countBrand + e.total);
    });

    console.log('paidfilteritemBrand', filteritemBrand, 'listnewItem', filterItemUpdateModel);

    const filterItemUpdateBrand = _.filter(_.size(_.get(paymentItem, 'model.[0]')) > 0 ? filterItemUpdateModel : tempVoucher, (item) => {
      if (countBrand - _.get(paymentItem, 'voucherBalance') > 0) {
        // console.log('paid1', item.voucherId,'pay',_.get(paymentItem, 'voucherId'));
        return item.voucherId !== _.get(paymentItem, 'voucherId');
      } else {
        return _.get(item, 'brand.[0]') !== _.get(paymentItem, 'brand.[0]');
      }
    });

    const usevou = _.get(paymentItem, 'voucherBalance');

    const updateTotal = _.map(tempVoucherItem, (item) => {
      if (item.itemBrandId === _.get(paymentItem, 'brand.[0]') || item.itemModelId === _.get(paymentItem, 'model.[0]')) {
        return { ...item, total: item.total - usevou };
      }

      return item;
    });

    setTempVoucherItem(updateTotal);
    setTempVoucher(_.size(_.get(paymentItem, 'model.[0]')) > 0 ? filterItemUpdateModel : filterItemUpdateBrand);
    setPaymentItem([]);
    setPaidamount(0);

    const final = _.size(_.get(paymentItem, 'model.[0]')) > 0 ? countBrand - usevou : countModel - usevou;

    console.log('paidfinal', final);

    if (_.size(_.get(paymentItem, 'model.[0]')) > 0) {
      if (countModel > usevou) {
        return usevou;
      } else {
        return usevou + final;
      }
    } else {
      if (countBrand > usevou) {
        return usevou;
      } else {
        return usevou + final;
      }
    }
  };

  const handleCalVou = (record) => {
    const filteritemmodel = _.filter(tempVoucherItem, (e) => {
      return e.itemModelId === _.get(record, 'model.[0]');
    });

    const filterUseVoucher = _.filter((e) => {
      return e.itemModelId === _.get(record, 'model.[0]');
    });

    let countModel = 0;
    _.map(filteritemmodel, (e) => {
      return (countModel = countModel + e.total);
    });

    const filteritemBrand = _.filter(tempVoucherItem, (e) => {
      return e.itemBrandId === _.get(record, 'brand.[0]');
    });

    let countBrand = 0;

    _.map(filteritemBrand, (e) => {
      return (countBrand = countBrand + e.total);
    });

    const usevou = _.get(record, 'voucherBalance');

    const finalModel = countModel - usevou;
    const finalBrand = countBrand - usevou;
    const finall = totalCal - usevou;

    console.log(
      'paidfinal',
      finall,
      'countBrand',
      countBrand,
      'countModel',
      countModel,
      'p/',
      paidamount,
      't/',
      totalCal,
      'r/',
      receiveData
    );
    console.log('paidfinal', finall, 'usevou', usevou, '/', _.get(record, 'model.[0]'), '/', _.get(record, 'brand.[0]'));

    if (_.get(record, 'model.[0]') === '*' && _.get(record, 'brand.[0]') === '*') {
      console.log('totalCalCon', totalCal, 'use', usevou);
      if (totalCal > usevou) {
        setPaidamount(usevou);
      } else {
        setPaidamount(usevou + finall);
      }
    } else {
      if (_.get(record, 'model.[0]') === '*') {
        if (countBrand > usevou) {
          setPaidamount(usevou);
        } else {
          setPaidamount(usevou + finalBrand);
        }
      } else {
        if (countModel > usevou) {
          setPaidamount(usevou);
        } else {
          setPaidamount(usevou + finalModel);
        }
      }
    }
  };

  const handleKeyDownNumberDecimal = (e) => {
    let keyCode = e.key.charCodeAt(0);

    const splitValue = _.split(e.target.value, '.');

    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
      if (keyCode === 46 && e.target.value.indexOf('.') > 1) {
        e.preventDefault();
      }
      if (keyCode === 46) {
        let sumDot = _.sumBy(e.target.value, (o) => {
          return o.charCodeAt(0) === 46 ? 1 : 0;
        });
        if (sumDot >= 1) {
          e.preventDefault();
        }
      }
    } else if (keyCode === 45) {
      e.preventDefault();
    } else {
      e.preventDefault();
    }

    if (_.get(splitValue, '[1]')) {
      if (_.size(splitValue[1]) >= 2 && keyCode !== 66 && e.target.selectionStart > e.target.value.indexOf('.')) {
        e.preventDefault();
      }
    }
  };

  const actionFooter =
    _.get(dataForm, 'code') !== 'waiting'
      ? [
          <Button_02
            style={{ margin: '0px 0px 0px 10px' }}
            key="back"
            btnsize="wd_df"
            onClick={() => {
              handleCancelQuotation();
              setTotalCal(0);
            }}
          >
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
          // <Button_01 key="save" type="primary" btnsize="wd_df" onClick={() => {handleCancelQuotation();setTotalCal(0);}}>
          //   <FormattedMessage id="btnClose" defaultMessage="Close" />
          // </Button_01>,
         
          <Button_01
            key="save"
            type="primary"
            btnsize="wd_df"
            onClick={() => handleSave('waiting')}
            disabled={receiveData > _.get(paidData, 'total') ? true : false}
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>,
        ]
      : [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
          <Button_01
            key="save"
            type="primary"
            btnsize="wd_df"
            onClick={() => handleSave('waiting')}
            disabled={receiveData > _.get(paidData, 'total') ? true : false}
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>,
        ];

  return (
    <Modal
      title={title}
      visible={visiblePaid}
      onOk={handleSave}
      onCancel={() => {
        handleCancelQuotation();
        setTotalCal(0);
      }}
      width={950}
      centered={true}
      bodyStyle={{ padding: 'unset' }}
      footer={actionFooter}
    >
      <DetailsTabPaid
        form={form}
        property={{
          handleOpenModalUpload,
          handleOpenCash,
          handleOpenBank,
          handleOpenCredit,
          handleOpenCheque,
          handleOpenVoucher,
          setDataForm,
          dataForm,
          initialMemberList,
          handleOpen,
          selectItem,
          setSelectItem,
          attData,
          setAttData,
          initialTaxList,
          receiveData,
          setReceiveData,
          handleOpenCancelPaid,
          handleOpenModalUploadPaid,
          visiblePaid,
          saleId,
          blNo,
          typeChecked
        }}
      />

      <ModalCancelFormPaid
        title={'Cancel' + ' · ' + _.get(cancelItemData, 'methodName')}
        visible={visibleCancelPaid}
        onOk={cancelItem}
        onCancel={handleCloseCancelPaid}
        initialReasonList={_.get(paidData, 'reasonList')}
        saleOrderId={_.get(paidData, 'billingId')}
        code={_.get(paidData, 'code')}
        cancelItemData={cancelItemData}
      />

      <UploadFileModal
        visible={openUploadPaid}
        setVisible={setOpenUploadPaid}
        setAttData={setAttachmentPaidData}
        attData={attachmentPaidData}
        typeAtt={typeAttachment}
        defaultAtt={defaultAttachment}
        record={dataForm}
        saleId={saleId}
        typeUp={'waiting'}
      />

      <ModalCashjForm
        title={
          typepatment === 'claim'
            ? intl.formatMessage({ id: 'ARClaim', defaultMessage: 'Claim' })
            : intl.formatMessage({ id: 'ARCash', defaultMessage: 'Cash' })
        }
        visible={visibleCash}
        onOk={handleSavePayment}
        onCancel={handleCancelCash}
        saleOrderId={_.get(paidData, 'billingId')}
        code={_.get(paidData, 'code')}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        receiveData={receiveData}
        setReceiveData={setReceiveData}
        typepatment={typepatment}
        totalCal={totalCal}
        handleKeyDownNumberDecimal={handleKeyDownNumberDecimal}
      />

      <ModalBankForm
        title={intl.formatMessage({ id: 'ARBankTransfer', defaultMessage: 'Bank Transfer' })}
        visible={visibleBank}
        onOk={handleSavePayment}
        onCancel={handleCancelBank}
        saleOrderId={_.get(paidData, 'billingId')}
        code={_.get(paidData, 'code')}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        receiveData={receiveData}
        setReceiveData={setReceiveData}
        dataForm={dataForm}
        totalCal={totalCal}
        handleKeyDownNumberDecimal={handleKeyDownNumberDecimal}
      />

      <ModalCreditARForm
        title={intl.formatMessage({ id: 'ARCreditCard', defaultMessage: 'Credit Card' })}
        visible={visibleCredit}
        onOk={handleSavePayment}
        onCancel={handleCancelCredit}
        saleOrderId={_.get(paidData, 'billingId')}
        code={_.get(paidData, 'code')}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        receiveData={receiveData}
        setReceiveData={setReceiveData}
        dataForm={dataForm}
        totalCal={totalCal}
        handleKeyDownNumberDecimal={handleKeyDownNumberDecimal}
      />

      <ModalChequeARForm
        title={intl.formatMessage({ id: 'ARCheque', defaultMessage: 'Cheque' })}
        visible={visibleCheque}
        onOk={handleSavePayment}
        onCancel={handleCancelCheque}
        saleOrderId={_.get(paidData, 'billingId')}
        code={_.get(paidData, 'code')}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        receiveData={receiveData}
        setReceiveData={setReceiveData}
        dataForm={dataForm}
        totalCal={totalCal}
        handleKeyDownNumberDecimal={handleKeyDownNumberDecimal}
      />
      <ModalVoucherARForm
        title={intl.formatMessage({ id: 'ARVoucher', defaultMessage: 'Voucher' })}
        visible={visibleVoucher}
        onOk={handleSavePayment}
        onCancel={handleCancelVoucher}
        saleOrderId={_.get(paidData, 'billingId')}
        code={_.get(paidData, 'code')}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        receiveData={receiveData}
        setReceiveData={setReceiveData}
        typepatment={typepatment}
        paymentItem={paymentItem}
        setPaymentItem={setPaymentItem}
        openVoucherListModal={openVoucherListModal}
        handleSelectVoucher={handleTest}
        paidamount={paidamount}
        setPaidamount={setPaidamount}
        totalCal={totalCal}
      />

      <ModalAddVoucher
        visible={visibleSelectVoucher}
        setvisible={setVisibleSelectVoucher}
        setItemData={setPaymentItem}
        paidData={paidData}
        visiblePaid={visiblePaid}
        setTempVoucher={setTempVoucher}
        tempVoucher={tempVoucher}
        handleSelectVoucher={handleCalVou}
      />
    </Modal>
  );
};

PaidModal.defaultProps = {
  title: 'Modal',
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalPaidForm = Form.create({ name: 'Customer_Form' })(PaidModal);

export default ModalPaidForm;
