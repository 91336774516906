import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'antd'
import './index.css'
import ListItemFilter from './filter'
import ListItemDetail from './item'
import { useIntl, FormattedMessage } from 'react-intl'
import _ from 'lodash'

import { useDebounce } from '../../../../../controllers/debounce'
import { getItemsCode,getItemsMasterNew } from '../../../../../controllers/warehouse/warehouse'
import Button_01 from '../../../../../components/v2/button_01'

const ListItemServiceSO = (props) => {
  const { visible, setVisible, setVisibleAdd, setCreateData, vendorData, form } = props
  const { getFieldValue } = form
  const intl = useIntl()
  const [searchGroup, setSearchGroup] = useState([]);
  const [itemList, setItemList] = useState([])

  const [optionsItemCode, setOptionsItemCode] = useState([]);
  const [loadingItemCode, setLoadingItemCode] = useState(false);
  const [itemCodeSearch, setItemCodeSearch] = useState();
  const debounceItemCodeSearch = useDebounce(itemCodeSearch, 500)

  const [optionsItemName, setOptionsItemName] = useState([]);
  const [loadingItemName, setLoadingItemName] = useState(false);
  const [itemNameSearch, setItemNameSearch] = useState();
  const debounceItemNameSearch = useDebounce(itemNameSearch, 500)

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      render: (text, record, index) => index + 1
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
    },
  ];

  useEffect(() => {
    if (getFieldValue('vendor') && visible) {
      setSearchGroup({ ...searchGroup, ['vendor']: getFieldValue('vendor') });
    }
  }, [getFieldValue('vendor'), visible])


  useEffect(() => {
    if (visible) {
      GetSearchListData()
    }
  }, [visible])


  const GetSearchListData = async () => {
    const body = {
      "paging": 1,
      "rowsPerPages": 10000,
      "orderBy": "",
      "orderType": "desc",
      "filter": {
        "itemCode": _.get(searchGroup, 'itemCode') ? _.get(searchGroup, 'itemCode') : "",
        "vendorId": _.get(searchGroup, 'vendor') || "",
        "itemName": _.get(searchGroup, 'itemName') ? _.get(searchGroup, 'itemName') : "",
      },
      "recently": _.get(searchGroup, 'purchase') || false,
    }
    // const response = await getItemService(body)
    // setItemList(_.get(response, 'data.data'))
  }

  const handleClose = () => {
    setSearchGroup([])
    setItemList([])
    setVisible(false)
  }

  const handleClickRow = (data) => {
    const setData = {
      ...data,
      itemMasterId: _.get(data, 'itemId')
    }
    setSearchGroup({})
    setCreateData(setData)
    setVisibleAdd(true)
  }


  useEffect(() => {
    const getItemCode = async () => {
      const payload = {
        code: itemCodeSearch || '',
      };
      const response = await getItemsCode(payload);
      const list = _.get(response.data.data, 'itemMasterList');
      setOptionsItemCode(list);
    };
    getItemCode();
  }, [debounceItemCodeSearch]);

  useEffect(() => {
    const getItemName = async () => {
      const payload = {
        name: itemNameSearch || '',
      };
      const response = await getItemsMasterNew(payload);
      const list = _.get(response.data.data, 'itemMasterList');
      setOptionsItemName(list);
    };

    getItemName();
  }, [debounceItemNameSearch]);

  const onChange = async (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
    // if (code === 'vendor') {
    //   handleChangeCustomer(value, true)
    // }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: `modalListItemServiceTitle`, defaultMessage: 'Add Item Service' })}
      visible={visible}
      onCancel={handleClose}
      centered={true}
      width={1112}
      bodyStyle={{ padding: 'unset', height: '680px' }}
      footer={[
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleClose}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_01>,
      ]}
    >
      <Row>
        <Col span={6}>
          <div className='listItemTextHeader'>
            <FormattedMessage id="btnFilter" defaultMessage="Filter" />
          </div>
          <div className='listItemDividerCenter'>
            <ListItemFilter
              onChange={onChange}
              searchGroup={searchGroup}
              onClick={GetSearchListData}
              itemCode={{
                optionsItemCode,
                loadingItemCode,
                itemCodeSearch,
                setItemCodeSearch,
              }}
              itemName={{
                optionsItemName,
                loadingItemName,
                itemNameSearch,
                setItemNameSearch,
              }}
            />
          </div>
        </Col>
        <Col span={18}>
          <div className='listItemTextHeader'>
            <FormattedMessage id="modalListItemItemText" defaultMessage="Item" />
          </div>
          <div>
            <ListItemDetail
              onChange={onChange}
              columns={columns}
              handleClickRow={handleClickRow}
              vendorData={vendorData}
              itemList={itemList}
              form={form}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export default ListItemServiceSO
