import React, { useState, useEffect, useContext } from 'react';
import { DownOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { Col, Row, Form, Select, DatePicker, Input, Dropdown, Menu, Button, Card, Icon, Divider } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import './css/index.css';
import moment from 'moment';
import { RepairticketContext } from '../repair-ticket-context';
const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

const Filtter = (props) => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(RepairticketContext);
  const { ticketData, changeTab, isLoading, fillter,brandData,totalBrand,modelData,totalModel } = state;
  const { setChangeStatus, setFillter, setChangeTab,setTotalBrand,setTotalModel } = setState;
  const { getApiSearch, changeToApproval, changeToReject, getApiApprove, getApiReject } = fnc;
  const { columns, form, tab } = props;
  const [rangeDate, setRangeDate] = useState(false);
  const paddingFilter = '2px 16px 0px';
  const statusLsit = _.get(ticketData, 'statusList');

  const handleChangeDate = (value) => {
    setRangeDate(value);
    setFillter({
      ...fillter,
      ['pmdatestart']: moment(_.get(value, '[0]')).format(dateFormat),
      ['pmdateto']: moment(_.get(value, '[1]')).format(dateFormat),
    });
    handleFillter(value);
  };

  const handleFillter = (value, code) => {
    console.log('valueFill', value);
    setFillter({ ...fillter, [code]: value });
  };

  console.log('filterRepair',_.get(fillter,'brand'));
  return (
    <div className="filterBackgrounds">
   <Form>
        <Row gutter={24}>
          <Col span={24} style={{ padding: paddingFilter }}>
            <Form.Item>
              <FormattedMessage id="AllticketTicketNoForm" defaultMessage="Ticket No." />

              <Input
                className="inputWidth"
                placeholder={intl.formatMessage({ id: 'AllTicketTicketNoEnterForm', defaultMessage: 'Enter Ticket No.' })}
                onChange={(e) => handleFillter(e.target.value, 'reno')}
                allowClear
              ></Input>
            </Form.Item>
          </Col>
          <Col span={24} style={{ padding: paddingFilter }}>
            <Form.Item>
              <FormattedMessage id="AllticketStartTicketNoForm" defaultMessage="Start Ticket Date" />
              <DatePicker
                className="inputWidth"
                placeholder={intl.formatMessage({ id: 'AllticketStartTicketNoEnterForm', defaultMessage: 'Select Start Date' })}
                format="ddd, MMM DD YYYY"
                onChange={(e) => handleFillter(moment(e).format('YYYY-MM-DD'), 'startdate')}
                allowClear={true}
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ padding: paddingFilter }}>
            <Form.Item>
              <FormattedMessage id="AllticketEndTicketNoForm" defaultMessage="End Ticket Date" />
              <DatePicker
                className="inputWidth"
                placeholder={intl.formatMessage({ id: 'AllticketEndTicketNoEnterForm', defaultMessage: 'Select End Date' })}
                format="ddd, MMM DD YYYY"
                allowClear={true}
                onChange={(e) => handleFillter(moment(e).format('YYYY-MM-DD'), 'enddate')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} style={{ padding: paddingFilter }}>
            <Form.Item>
              <FormattedMessage id="AllticketStatusForm" defaultMessage="Status" />
              <Select
                placeholder={intl.formatMessage({ id: 'AllticketEndStatueSelectForm', defaultMessage: 'Select Status' })}
                // onSelect={(e) => handleFillter(e, 'status')}
                onChange={(e) => handleFillter(e, 'status')}
                allowClear={true}
              >
                {statusLsit &&
                  _.map(statusLsit, (item, index) => {
                    return (
                      <Option key={_.get(item, 'statusCode')} value={_.get(item, 'statusCode')}>
                        {_.get(item, 'statusName')}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} style={{ padding: paddingFilter }}>
            <Form.Item>
              <FormattedMessage id="RepairTicketBrandForm" defaultMessage="Brand" />
              <Select
                placeholder={intl.formatMessage({ id: 'RepairTicketBrandSelectForm', defaultMessage: 'Select Brand' })}
                // onSelect={(e) => handleFillter(e, 'status')}
                onChange={(e) => handleFillter(e, 'brand')}
                allowClear={true}
                dropdownRender={menu => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{ padding: '4px 8px', cursor: 'pointer' }}
                      onMouseDown={e => e.preventDefault()}
                      onClick={() => setTotalBrand(totalBrand+10)}
                    >
                      <Icon type="plus" className='customer-icon-plus' />
                      {' '}
                      <span className='text-add-customer'><FormattedMessage id="RepairTicketBrandLoadmore" defaultMessage="Load More" /></span>
                    </div>
                  </div>
                )}
              >
                {brandData &&
                  _.map(brandData, (item, index) => {
                    return (
                      <Option key={_.get(item, 'assetBrandId')} value={_.get(item, 'assetBrandId')}>
                        {_.get(item, 'assetBrandName')}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} style={{ padding: paddingFilter }}>
            <Form.Item>
              <FormattedMessage id="ReapirTicketModelForm" defaultMessage="Model" />
              <Select
                placeholder={intl.formatMessage({ id: 'RepairTicketModelSelectForm', defaultMessage: 'Select Model' })}
                // onSelect={(e) => handleFillter(e, 'status')}
                onChange={(e) => handleFillter(e, 'model')}
                allowClear={true}
                disabled={_.get(fillter,'brand') !== undefined ? false : true}
                dropdownRender={menu => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{ padding: '4px 8px', cursor: 'pointer' }}
                      onMouseDown={e => e.preventDefault()}
                      onClick={() => setTotalModel(totalModel+10)}
                    >
                      <Icon type="plus" className='customer-icon-plus' />
                      {' '}
                      <span className='text-add-customer'><FormattedMessage id="RepairTicketModelLoadmore" defaultMessage="Load More" /></span>
                    </div>
                  </div>
                )}
              >
                {modelData &&
                  _.map(modelData, (item, index) => {
                    return (
                      <Option key={_.get(item, 'assetModelId')} value={_.get(item, 'assetModelId')}>
                        {_.get(item, 'assetModelName')}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
        <Col span={24} style={{ padding: paddingFilter }}>
            <Form.Item>
              <FormattedMessage id="RepairTicketAssetForm" defaultMessage="Asset Name" />

              <Input
                className="inputWidth"
                placeholder={intl.formatMessage({ id: 'RepairTicketAssetEnterForm', defaultMessage: 'Enter Asset Name' })}
                onChange={(e) => handleFillter(e.target.value, 'assetname')}
                allowClear
              ></Input>
            </Form.Item>
          </Col>
          <Col span={24} style={{ padding: paddingFilter }}>
            <Form.Item>
              <FormattedMessage id="RepairTicketSerialForm" defaultMessage="Serial No" />

              <Input
                className="inputWidth"
                placeholder={intl.formatMessage({ id: 'RepairTicketSerialEnterForm', defaultMessage: 'Enter Serial No' })}
                onChange={(e) => handleFillter(e.target.value, 'serailno')}
                allowClear
              ></Input>
            </Form.Item>
          </Col>
        </Row>
              
      </Form>
    </div>
  );
};

const FillterForm = Form.create({
  name: 'filter_form',
  mapPropsToFields() {},
})(Filtter);

export default FillterForm;
