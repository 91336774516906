import httpClient from '../../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getBusinessType = async () => {
  try {
    const response = await httpClient.get(`/v3/business-partner/manager/company/${comCode}/business-types`);
    if (response.status === 200) {
      return response.data.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getCustomerType = async () => {
  try {
    const response = await httpClient.get(`/v3/business-partner/manager/company/${comCode}/customer-types`);
    if (response.status === 200) {
      return response.data.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export { getBusinessType, getCustomerType }