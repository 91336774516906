import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Form, Row, Col, Modal, Icon, Input, Badge, Select, Divider } from 'antd';
import './css/index.css';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import AppAvatar from '../avatar';
import DefaultProfile from '../../components/image/Profile_Default.png';
import Resizer from 'react-image-file-resizer';
import PrivacyController from '../../controllers/getCustomer/get-privacy-type';
import ModalCreatePrivacy from '../modal-privacy/index';
import EditModalPrivacy from '../edit-modal-privacy/index';
import { getBusinessType, getCustomerType } from '../add-customer/controller/controller';
import ModalMapLocationFrom from '../edit-customer-location-v2';
import _, { debounce } from 'lodash';
import { successNotification, errorNotification } from '../../components/v2/notification';
import httpClient from '../../components/axiosClient';
import getOwner from '../../controllers/getCustomer/get-owner';
import styled from 'styled-components';
import getTeamSelf from '../../controllers/getCustomer/get-team-self';
import getMemberSelf from '../../controllers/getCustomer/get-member-self';
import { useAppContext } from '../../includes/indexProvider';

const { Option } = Select;
const { TextArea } = Input;

const AddcustomerV2 = (props) => {
  const { visible, setVisible, form, setTrigger, type, setId, setCustomerData, CustomerData } = props;
  const { getFieldDecorator, getFieldValue, validateFields, resetFields } = form;
  const intl = useIntl();
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  const [businessType, setBusinessType] = useState([]);
  const [customerType, setCustomerType] = useState([]);
  const comCode = localStorage.getItem('comCode');
  const [disbleButton, setDisbleButton] = useState(false);
  const nameLocal = localStorage.getItem('name');
  const memComId = localStorage.getItem('memComId');
  const [nameAuto, setNameAuto] = useState([
    {
      name: nameLocal,
      memComId: memComId,
    },
  ]);

  //---------------------State Customer ---------------------------
  const [cusDataBodyApi, setCusDataBodyApi] = useState({});
  const [autoComplteLocation, setAutoComplteLocation] = useState();
  const [autoComplteValue, setAutoComplteValue] = useState();
  //---------------------State Image ------------------------------
  const imageRef = useRef();
  const [imageFile, setImageFile] = useState();
  const [imageFileDefault, setImageFileDefault] = useState('');
  const [imageCustomer, setImageCustomer] = useState();
  const [visibleText, setVisibleText] = useState(false);
  //---------------------State Privacy ----------------------------
  const [dataPrivacy, setDataPrivacy] = useState();
  const [dataPrivacyType, setDataPrivacyType] = useState();
  const [dataTeam, setDataTeam] = useState();
  const [visibleModalPrivacy, setVisibleModalPrivacy] = useState(false);
  const [refModalPrivacy, setRefModalPrivacy] = useState(null);
  const [refModalEditPrivacy, setRefModalEditPrivacy] = useState(null);
  const [visibleModalEditPrivacy, setVisibleModalEditPrivacy] = useState(false);
  //---------------------State Address ----------------------------
  const [filterCustomerLocation, setFilterCustomerLocation] = useState(undefined);
  const [locationFormRef, setLocationFormRef] = useState(null);
  const [visibleModalFrom, setVisibleModalFrom] = useState(false);
  const [latlngCustomer, setLatlngCustomer] = useState({});
  const [valueAddress, setValueAddress] = useState('');
  const [dataFromLocationEdit, setDataFromLocationEdit] = useState({});
  const [locationMyself, setLocationMyself] = useState();
  const [position, setPosition] = useState(latlng);
  const [center, setCenter] = useState(latlng);
  const [teamSelfData, setTeamSelfData] = useState([]);
  const [memBerSelfData, setMemberSelfData] = useState([]);
  const [orgId, setOrgId] = useState();
  const [checkDisable, setCheckDisable] = useState(true);
  //---------------------------------------------------------------

  useEffect(() => {
    const getApiBusinessTypeTnfo = async () => {
      const getBusinessTypeTnfo = await getBusinessType();
      setBusinessType(getBusinessTypeTnfo);
    };
    getApiBusinessTypeTnfo();
  }, []);

  useEffect(() => {
    const getApiCustomerTypeTnfo = async () => {
      const getCustomerTypeTnfo = await getCustomerType();
      setCustomerType(getCustomerTypeTnfo);
    };
    getApiCustomerTypeTnfo();
  }, []);

  const LabelRequire = styled.label`
    color: #ff1010;
  `;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  useEffect(() => {
    const getTeamSelfData = async () => {
      const getTeam = await getTeamSelf();
      // setTeamSelfData(_.get(getTeam, 'organization'));
      setTeamSelfData(_.sortBy(_.get(getTeam, 'organization'), ['orgName']));
    };
    getTeamSelfData();
  }, []);

  useEffect(() => {
    const filterOrg =
      teamSelfData &&
      teamSelfData.filter((item) => {
        return parseFloat(item.orgId) === parseFloat(_.get(CustomerData, 'Organization.orgId'));
      });
    if (_.size(filterOrg) < 1) {
      let objName = {
        orgId: parseFloat(_.get(CustomerData, 'Organization.orgId')),
        orgName: _.get(CustomerData, 'Organization.orgName'),
        orgSelected: false,
      };
      const sortData = _.sortBy([...teamSelfData, objName], ['orgName']);
      setTeamSelfData(sortData);
    }
  }, [_.get(CustomerData, 'customerId')]);

  useEffect(() => {
    const getMemberSelfData = async () => {
      const getMember = await getMemberSelf(orgId);
      const filterMemcom = _.get(getMember, 'data.members').filter((item) => {
        return item.mem_com_id === _.get(CustomerData, 'valuePrivacy');
      });
      if (_.size(filterMemcom) < 1) {
        let objName = {
          com_id: '',
          emp_code: '',
          fullname: _.get(CustomerData, 'createdBy'),
          mem_com_id: _.get(CustomerData, 'valuePrivacy'),
        };
        const sortData = _.sortBy([..._.get(getMember.data, 'members'), objName], ['fullname']);
        setMemberSelfData(sortData);
      } else {
        setMemberSelfData(_.sortBy(_.get(getMember.data, 'members'), ['fullname']));
      }
    };
    getMemberSelfData();
  }, [orgId, _.get(CustomerData, 'customerId')]);

  useEffect(() => {
    if (getFieldValue('team') !== undefined) {
      setOrgId(getFieldValue('team'));
    }
  }, [getFieldValue('team')]);

  const handleChangeTeam = () => {
    setOrgId(getFieldValue('team'));
    setCheckDisable(false);
    form.setFieldsValue({
      ['owner']: memComId,
    });
  };

  //------------------ Add Customer and Image----------------------

  const handleCreate = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const mapPrivacyTeamList =
        dataPrivacy && dataPrivacy.team !== undefined
          ? dataPrivacy.team.map((item) => {
            return item.toString();
          })
          : undefined;
      setDisbleButton(true);
      try {
        const body = {
          comCode: comCode,
          customerName: values.cusName,
          customerShortName: values.cusCode,
          businessTypeId: values.busType,
          customerTypeId: values.cusType,
          address: cusDataBodyApi.description,
          fullAddress: cusDataBodyApi.address,
          lat: cusDataBodyApi.lat,
          lng: cusDataBodyApi.lng,
          profileImg: undefined,
          contacts: [],
          locationName: cusDataBodyApi.addressName,
          description: values.remark,
          selectedPrivacy: 'public',
          privacyTeamLists: mapPrivacyTeamList,
          type: type ? type : undefined,
          createdBy: !type ? values.owner : undefined,
          taxNo: values.taxNo,
          phone: values.phone,
          orgId: orgId,
        };

        const response = await httpClient.put(
          `/v3/business-partner/manager/company/${comCode}/customer-informations/${CustomerData.customerId}`,
          body
        );

        if (response.status === 200) {
          successNotification(response.data.status.message);
          if (setTrigger) {
            setTrigger((current) => !current);
          }
          setTimeout(() => {
            if (setCustomerData) {
              setCustomerData(response.data.data);
            }
          }, 100);
          setVisible(false);
          setDataPrivacy();
          if (setId !== undefined) {
            setId(response.data.data.customerId);
          }

          if (response.data) {
            let formData = new FormData();
            formData.append('file', imageCustomer);
            try {
              await httpClient.put(
                `/v3/business-partner/manager/company/${comCode}/customer-informations/${CustomerData.customerId}`,
                formData
              );
            } catch (error) { }
          }

          locationFormRef.resetFields();
          resetFields();
          // setImageFile('');
          setImageCustomer();
          imageRef.current.value = null;
          // setFilterCustomerLocation();
          setValueAddress(undefined);
          setDisbleButton(false);
          setCheckDisable(true);
          setAutoComplteLocation();
          setAutoComplteValue();
          setNameAuto([
            {
              name: nameLocal,
              memComId: memComId,
            },
          ]);
        } else {
          errorNotification(response.data.status.message);
        }
      } catch (error) {
        if (error.response.status === 500) {
          errorNotification(error.response.data.error);
        } else {
          errorNotification(error.response.data.status.message);
          setDisbleButton(false);
        }
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
    imageRef.current.value = null;
    setImageCustomer();
    resetFields();
    // setImageFile('');
    setCusDataBodyApi({});
    setDataPrivacy();
    // setFilterCustomerLocation();
    locationFormRef.resetFields();
    setCheckDisable(true);
    setValueAddress(undefined);
    setAutoComplteLocation();
    setAutoComplteValue();
    setNameAuto([
      {
        name: nameLocal,
        memComId: memComId,
      },
    ]);
  };

  //---------------------------------------------------------------

  //------------------ Image Process ------------------------------
  useEffect(() => {
    setImageFile(_.get(CustomerData, 'profileImg'));
    setImageFileDefault(DefaultProfile);
    //  form.setFieldsValue({
    //     ['owner']: _.get(CustomerData,'valuePrivacy')
    //   });
  }, [_.get(CustomerData, 'customerId')]);

  useEffect(() => {
    setFilterCustomerLocation({
      addressName: _.get(CustomerData, 'locationName'),
      fullAddress: _.get(CustomerData, 'address'),
      address: _.get(CustomerData, 'address'),
      description: _.get(CustomerData, 'address'),
      lat: _.get(CustomerData, 'lat'),
      lng: _.get(CustomerData, 'lng'),
      subDistrict: '',
      province: '',
      postCode: '',
    });
    setCusDataBodyApi({
      addressName: _.get(CustomerData, 'locationName'),
      fullAddress: _.get(CustomerData, 'address'),
      address: _.get(CustomerData, 'address'),
      description: _.get(CustomerData, 'address'),
      lat: _.get(CustomerData, 'lat'),
      lng: _.get(CustomerData, 'lng'),
      subDistrict: '',
      province: '',
      postCode: '',
    });
    setLatlngCustomer({
      lat: _.get(CustomerData, 'lat'),
      lat: _.get(CustomerData, 'lng'),
    });
  }, [_.get(CustomerData, 'customerId')]);

  const showOpenFileDialog = (e) => {
    imageRef.current.click();
  };

  const deleteImage = (e) => {
    setImageFile('');
    setImageCustomer();
    imageRef.current.value = null;
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPEG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleChangePicture = async (event) => {
    event.preventDefault();
    let reader = new FileReader();
    const file = event.target.files[0];

    if (file) {
      setVisibleText(false);
      if (file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
        const image = await resizeFile(file);
        reader.onloadend = () => {
          const image = reader.result;
          //setImage(image);
          setImageFile(image);
        };
        reader.readAsDataURL(file);

        const fileChange = dataURLtoFile(image, file.name);
        setImageCustomer(fileChange);
      }
    }
  };

  //---------------------------------------------------------------
  //------------------ Add Privacy --------------------------------
  const namePrivacy =
    dataPrivacy && dataPrivacy !== undefined ? dataPrivacyType.filter((col) => dataPrivacy.privacy.includes(col.code)) : undefined;
  const exportName = namePrivacy && namePrivacy !== undefined ? namePrivacy[0].name : undefined;
  const exportCode = namePrivacy && namePrivacy !== undefined ? namePrivacy[0].code : undefined;
  const lengthPrivacy = dataPrivacy && dataPrivacy.team !== undefined ? dataPrivacy.team.length : undefined;

  useEffect(() => {
    getPrivacyType();
  }, []);

  const getPrivacyType = async () => {
    const response = await PrivacyController();
    if (response.status.code == 200) {
      setDataPrivacyType(response.data.privacyTypes);
      setDataTeam(response.data.teams);
    }
  };

  const savePrivacy = useCallback((node) => {
    if (node !== null) {
      setRefModalPrivacy(node);
    }
  }, []);

  const saveEditPrivacy = useCallback((node) => {
    if (node !== null) {
      setRefModalEditPrivacy(node);
    }
  }, []);

  const handleCreatePrivacy = () => {
    refModalPrivacy.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        setDataPrivacy(values);
        setVisibleModalPrivacy(false);
      }
    });
  };

  const handleCreateEditPrivacy = () => {
    refModalEditPrivacy.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        setDataPrivacy(values);
        setVisibleModalEditPrivacy(false);
      }
    });
  };
  //---------------------------------------------------------------
  //------------------ Add Addressbook ----------------------------
  const saveCustomerFormRef = useCallback((node) => {
    if (node !== null) {
      setLocationFormRef(node);
    }
  }, []);

  const handleCancelModalFrom = () => {
    setVisibleModalFrom(false);
    setValueAddress(undefined);
    resetFields();
    setLatlngCustomer({
      lat: _.get(filterCustomerLocation, 'lat'),
      lng: _.get(filterCustomerLocation, 'lng'),
    });
    setPosition({
      lat: _.get(filterCustomerLocation, 'lat'),
      lng: _.get(filterCustomerLocation, 'lng'),
    });
    setCenter({
      lat: _.get(filterCustomerLocation, 'lat'),
      lng: _.get(filterCustomerLocation, 'lng'),
    });

    setCusDataBodyApi({});
    setDataFromLocationEdit({});
  };

  const handleCreateFromLocation = () => {
    locationFormRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const body = {
        addressName: values.locationName,
        fullAddress: autoComplteValue,
        address: autoComplteValue,
        description: values.description,
        lat: latlngCustomer.lat,
        lng: latlngCustomer.lng,
        subDistrict: '',
        province: '',
        postCode: '',
      };

      setFilterCustomerLocation(body);
      setCusDataBodyApi(body);
      setVisibleModalFrom(false);
    });
  };

  //---------------------------------------------------------------

  const handleSearchName = debounce(async (fieldChang) => {
    if (fieldChang !== undefined && fieldChang !== '') {
      let newData = await getOwner(fieldChang);
      if (_.size(newData) !== 0) {
        setNameAuto(newData.data.members);
      }
    } else {
      setNameAuto([]);
    }

    // setAddressArea(newData);
  }, 200);

  const handleBlur = () => {
    const OwnerValue = getFieldValue(`owner`);
    const filterValue =
      nameAuto &&
      nameAuto.filter((item) => {
        return Number(OwnerValue) === Number(item.memComId);
      });
    if (_.size(filterValue) === 0) {
      form.setFieldsValue({
        ['owner']: undefined,
      });
    }
  };


  const handleKeyDownNumber = (e) => {
    console.log('key down', e.key.charCodeAt(0));
    let keyCode = e.key.charCodeAt(0);
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66) {
      return true;
    } else {
      e.preventDefault();
    }
  };


  const children = nameAuto && nameAuto.map((item) => <Option key={item.memComId}>{item.name}</Option>);

  return (
    <Modal
      title={
        type === 'vendor'
          ? intl.formatMessage({ id: 'ModalVendorEditHeader', defaultMessage: 'Edit Vendor' })
          : intl.formatMessage({ id: 'ModalCustomerEditHeader', defaultMessage: 'Edit Customer' })
      }
      className="business-partner-modal"
      centered={true}
      visible={visible}
      width={700}
      onCancel={handleCancel}
      footer={[
        <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={handleCancel} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleCreate} disabled={disbleButton}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form colon={false} hideRequiredMark={true}>
        <Row>
          <Col span={8}>
            <div className="image-container">
              <AppAvatar size={150} src={`${imageFile ? imageFile : imageFileDefault}`} />
              <input
                ref={imageRef}
                type="file"
                style={{ display: 'none' }}
                accept=".jpg, .png, .JPG, .PNG"
                onChange={handleChangePicture}
              />
              {imageFile ? (
                <div className="image-container after" style={{ paddingTop: 63, paddingLeft: 42, paddingRight: 42 }}>
                  <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
                  &nbsp; &nbsp;
                  <Icon type="delete" theme="outlined" style={{ fontSize: '25px' }} onClick={deleteImage} />
                </div>
              ) : (
                <div className="image-container after" style={{ paddingTop: 63, paddingLeft: 62, paddingRight: 62 }}>
                  <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
                </div>
              )}
            </div>
          </Col>
          <Col span={16}>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'ModalCustomerCustomerCode', defaultMessage: 'Customer Code' })}
                      req={true}
                    />
                  }
                  className="form-customer"
                >
                  {getFieldDecorator('cusCode', {
                    initialValue: CustomerData ? _.get(CustomerData, 'customerCode') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'ModalCustomerValidateCustomerCode', defaultMessage: 'Please enter code' }),
                      },
                    ],
                  })(
                    <Input
                      className=""
                      placeholder={intl.formatMessage({ id: 'ModalCustomerPlacholderCustomerCode', defaultMessage: 'Enter Customer Code' })}
                      autoComplete="off"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={{ marginLeft: '4px' }}
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'ModalCustomerCustomerName', defaultMessage: 'Customer Name' })}
                      req={true}
                    />
                  }
                  className="form-customer"
                >
                  {getFieldDecorator('cusName', {
                    initialValue: CustomerData ? _.get(CustomerData, 'customerName') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'ModalCustomerValidateCustomerName', defaultMessage: 'Please enter name' }),
                      },
                    ],
                  })(
                    <Input
                      className=""
                      placeholder={intl.formatMessage({ id: 'ModalCustomerPlacholderCustomerName', defaultMessage: 'Enter Customer Name' })}
                      autoComplete="off"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  label={<LabeRequire text={intl.formatMessage({ id: 'ModalCustomerCustomerTeam', defaultMessage: 'Team' })} req={true} />}
                  className="form-customer"
                >
                  {getFieldDecorator('team', {
                    initialValue: CustomerData ? parseFloat(_.get(CustomerData, 'Organization.orgId')) : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'ModalCustomerValidateCustomerTeam', defaultMessage: 'Please Select Team' }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({ id: 'ModalCustomerPlacholderCustomerTeam', defaultMessage: 'Select Team' })}
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={handleChangeTeam}
                    >
                      {teamSelfData && teamSelfData.map((item) => <Option value={item.orgId}>{item.orgName}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={{ marginLeft: '4px' }}
                  label={
                    <LabeRequire text={intl.formatMessage({ id: 'venderInformationTextOwner', defaultMessage: 'Owner' })} req={true} />
                  }
                  className="form-customer"
                >
                  {getFieldDecorator('owner', {
                    initialValue: _.get(CustomerData, 'valuePrivacy'),
                    // initialValue: 1,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'ModalCustomerValidateCustomerOwner', defaultMessage: 'Please select Owner' }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({ id: 'ModalCustomerPlacholderCustomerOwner', defaultMessage: 'Select  Owner' })}
                      // disabled={checkDisable}
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {memBerSelfData && memBerSelfData.map((item) => <Option value={item.mem_com_id}>{item.fullname}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'ModalCustomerBusinessType', defaultMessage: 'Business Type' })}
                      req={true}
                    />
                  }
                  className="form-customer"
                >
                  {getFieldDecorator('busType', {
                    initialValue: CustomerData ? _.get(CustomerData, 'businessTypeId') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'ModalCustomerValidateBusinessType',
                          defaultMessage: 'Please select business type',
                        }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({
                        id: 'ModalCustomerPlacholderBusinessType',
                        defaultMessage: 'Select Business Type',
                      })}
                    >
                      {businessType.map((item, index) => (
                        <Option value={item.businessTypeId} key={item.businessTypeId}>
                          {item.businessTypeName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={{ marginLeft: '4px' }}
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'venderInformationTextCustomerType', defaultMessage: 'Vendor Type' })}
                      req={true}
                    />
                  }
                  className="form-customer"
                >
                  {getFieldDecorator('cusType', {
                    initialValue: CustomerData ? _.get(CustomerData, 'customerTypeId') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'ModalCustomerValidateCustomerType',
                          defaultMessage: 'Please select customer type',
                        }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={
                        type
                          ? intl.formatMessage({ id: 'ModalCustomerPlacholderVendorType', defaultMessage: 'Select Vendor Type' })
                          : intl.formatMessage({ id: 'ModalCustomerPlacholderCustomerType', defaultMessage: 'Select Customer Type' })
                      }
                    >
                      {customerType.map((item, index) => (
                        <Option value={item.customerTypeId} key={item.customerTypeId}>
                          {item.customerTypeName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequire text={intl.formatMessage({ id: 'ModalCustomerCustomerTaxno', defaultMessage: 'Tax No.' })} req={false} />
                  }
                  className="form-customer"
                >
                  {getFieldDecorator('taxNo', {
                    initialValue: CustomerData ? _.get(CustomerData, 'taxNo') : undefined,
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({ id: 'ModalCustomerValidateCustomerTaxno', defaultMessage: 'Please input tax No' }),
                      },
                    ],
                  })(
                    <Input
                      className=""
                      placeholder={intl.formatMessage({ id: 'ModalCustomerPlacholderCustomerTaxno', defaultMessage: 'Enter Tax No' })}
                      autoComplete="off"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={{ marginLeft: '4px' }}
                  label={
                    <LabeRequire text={intl.formatMessage({ id: 'ModalCustomerCustomerPhone', defaultMessage: 'Phone' })} req={true} />
                  }
                  className="form-customer"
                >
                  {getFieldDecorator('phone', {
                    initialValue: CustomerData ? _.get(CustomerData, 'phone') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'ModalCustomerValidateCustomerPhone', defaultMessage: 'Please input phone' }),
                      },
                    ],
                  })(
                    <Input
                      className=""
                      placeholder={intl.formatMessage({ id: 'ModalCustomerPlacholderCustomerPhone', defaultMessage: 'Enter Phone' })}
                      autoComplete="off"
                      onKeyDown={handleKeyDownNumber}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <div style={{ marginTop: '10px' }}>
                <div>
                  <p className="text-address-local">
                    <FormattedMessage id="ModalCustomerAddressText" defaultMessage="Address" />
                    <LabeRequire req={true} />
                  </p>
                </div>
                <Row gutter={[8, 8]}>
                  <div>
                    {filterCustomerLocation && filterCustomerLocation !== undefined ? (
                      <Form.Item className="form-line-height">
                        <div className="text-location-short">
                          <Icon
                            type="environment"
                            style={{
                              fontSize: '12px',
                              color: '#1D3557',
                              paddingLeft: '0px',
                              paddingRight: '4px',
                              cursor: 'pointer',
                            }}
                          />
                          <span
                            // className='span-format'
                            style={{ color: '#1D3557' }}
                            onClick={() => setVisibleModalFrom(true)}
                          >
                            {_.get(filterCustomerLocation, 'addressName')} <Badge color="#000000" className="badge-text" />{' '}
                            {_.get(filterCustomerLocation, 'description')}
                          </span>
                        </div>
                      </Form.Item>
                    ) : (
                      <Form.Item className="form-line-height">
                        <Icon
                          type="environment"
                          style={{
                            fontSize: '12px',
                            color: '#1D3557',
                            paddingLeft: '0px',
                            paddingRight: '4px',
                            cursor: 'pointer',
                          }}
                        />
                        {getFieldDecorator('addressname', {
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'ModalCustomerValidateLocationName',
                                defaultMessage: 'Please enter location name',
                              }),
                            },
                          ],
                        })(
                          <Input
                            placeholder={intl.formatMessage({
                              id: 'ModalCustomerPlacholderLocationName',
                              defaultMessage: 'Enter Location Name',
                            })}
                            onClick={() => setVisibleModalFrom(true)}
                            className="new-input-add-user-name"
                            style={{ padding: 'unset', cursor: 'pointer' }}
                            autoComplete="off"
                          />
                        )}
                      </Form.Item>
                    )}
                  </div>
                </Row>
              </div>
            </Row>
            <Divider type="horizontal" style={{ marginTop: '25px', marginBottom: '12px' }} />
            <Row>
              <Form.Item
                label={
                  <LabeRequire text={intl.formatMessage({ id: 'ModalCustomerCustomerRemark', defaultMessage: 'Remark' })} req={false} />
                }
                className="form-customer"
              >
                {getFieldDecorator('remark', {
                  initialValue: CustomerData ? _.get(CustomerData, 'description') : undefined,
                })(<TextArea maxLength={250} autoSize={{ minRows: 4, maxRows: 4 }} style={{ borderRadius: '5px' }} />)}
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>

      <ModalCreatePrivacy
        ref={savePrivacy}
        onCreate={() => handleCreatePrivacy()}
        visibleModalPrivacy={visibleModalPrivacy}
        setVisibleModalPrivacy={setVisibleModalPrivacy}
        dataPrivacyType={dataPrivacyType}
        dataTeam={dataTeam}
        intl={intl}
      />

      <EditModalPrivacy
        ref={saveEditPrivacy}
        onCreate={() => handleCreateEditPrivacy()}
        setVisibleModalPrivacy={setVisibleModalEditPrivacy}
        visibleModalPrivacy={visibleModalEditPrivacy}
        dataPrivacyType={dataPrivacyType}
        dataTeam={dataTeam}
        dataPrivacy={dataPrivacy}
        intl={intl}
      />

      <ModalMapLocationFrom
        ref={saveCustomerFormRef}
        visible={visibleModalFrom}
        setLatlngCustomer={setLatlngCustomer}
        onCancel={() => handleCancelModalFrom()}
        onCreate={() => handleCreateFromLocation()}
        stateCustomer={{
          valueAddress,
          filterCustomerLocation,
          dataFromLocationEdit,
          position,
          center,
          locationMyself,
          autoComplteLocation,
          autoComplteValue,
        }}
        setStateCustomer={{
          setValueAddress,
          setDataFromLocationEdit,
          setPosition,
          setCenter,
          setLocationMyself,
          setAutoComplteLocation,
          setAutoComplteValue,
        }}
        CustomerData={CustomerData}
      />
    </Modal>
  );
};
const Customer = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) { },
})(AddcustomerV2);

export default Customer;
