import React, { useContext } from 'react';
import { Card, Tabs, Dropdown, Menu, Button } from 'antd';
import { MemberExpenseProvider } from './context';
import MemberExpenseTable from './components/table';
import _ from 'lodash';
import Button01 from '../../components/v2/button_01';
import { FormattedMessage } from 'react-intl';
import { DownOutlined } from '@ant-design/icons';
import { PageSettings } from '../../config/page-settings';

const { TabPane } = Tabs;

const MemberExpenseContent = () => {
  const { state, setState, fnc } = useContext(MemberExpenseProvider);
  const { tabData, activeTab, selectedRowKeys, selectList } = state;
  const { setTrigger } = setState;
  const { handleChangeTab, handleOpenModal, handleChangeStatus, handleExport,handleStatus } = fnc;
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;
  const permissonPending = checkPermissionAction('P81PG1C1', 'P81PG1C1A3') 
  const permissonSendApp = checkPermissionAction('P81PG1C1', 'P81PG1C1A4') 
  const permissonPayment = checkPermissionAction('P81PG1C1', 'P81PG1C1A5') 
  const permissonPaid = checkPermissionAction('P81PG1C1', 'P81PG1C1A7') 

  const menuColumn = (
    <Menu>
          {activeTab === 'pending' && _.size(selectedRowKeys) > 0 && permissonSendApp  ? (
            <Menu.Item key='sendapproval' onClick={() => handleStatus('waiting', { statusCode: 'pending' }, handleChangeStatus)}>
              <Button
                style={{ padding: 'unset' }}
                ghost
                type="link"
                // onClick={() => handleStatus('waiting', { statusCode: 'pending' }, handleChangeStatus)}
              >
                {/* <Button style={{ padding: 'unset' }} ghost type="link" onClick={() => console.log('KeyKEY',selectList) */}

                <FormattedMessage id="btnSendApproval" defaultMessage="Send Approval" />
                <span className="spaceWord">{`(${_.size(selectedRowKeys)})`}</span>
              </Button>
            </Menu.Item>
          ) : null}
          {activeTab === 'waiting' || activeTab === 'waitingpaid' || activeTab === 'reject' ? (
            _.size(selectedRowKeys) > 0 && permissonPending ?
            <Menu.Item key='pending' onClick={() =>
              handleStatus(
                'pending',
                { statusCode: activeTab === 'reject' ? 'reject' : activeTab === 'waitingpaid' ? 'waitingpaid' : 'waiting' },
                handleChangeStatus
              )
            } >
              <Button
                style={{ padding: 'unset' }}
                ghost
                type="link"
                
              >
                <FormattedMessage id="btnPending" defaultMessage="Pending" />
                <span className="spaceWord">{` (${_.size(selectedRowKeys)})`}</span>
              </Button>
            </Menu.Item> : null 
          ) : null}
          {activeTab === 'waiting' && _.size(selectedRowKeys) > 0 && permissonPayment ? (
            <Menu.Item key='approve' onClick={() => handleStatus('waitingpaid', { statusCode: 'waiting' }, handleChangeStatus)} >
              <Button
                style={{ padding: 'unset' }}
                ghost
                type="link"
                
              >
                <FormattedMessage id="btnApprove" defaultMessage="Approve" />
                <span className="spaceWord">{` (${_.size(selectedRowKeys)})`}</span>
              </Button>
            </Menu.Item>
          ) : null}
          {activeTab === 'waitingpaid' && _.size(selectedRowKeys) > 0 && permissonPaid? (
            <Menu.Item key='paid'  onClick={() => handleStatus('paid', { statusCode: 'waitingpaid' }, handleChangeStatus)}>
              <Button
                style={{ padding: 'unset' }}
                ghost
                type="link"
               
              >
                <FormattedMessage id="btnPaid" defaultMessage="Paid" />
                <span className="spaceWord">{` (${_.size(selectedRowKeys)})`}</span>
              </Button>
            </Menu.Item>
          ) : null}

      
      <Menu.Item onClick={() => handleOpenModal({}, 'create')}>
        <Button style={{ padding: 'unset' }} ghost type="link" >
          <FormattedMessage id="btnCreateExpense" defaultMessage="Create Expense" />
        </Button>
      </Menu.Item>
      <Menu.Item onClick={() => handleExport()}>
        <Button style={{ padding: 'unset' }} ghost type="link" >
          <FormattedMessage id="expenseBtnExport" defaultMessage="Export Excel" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    checkPermissionAction('P81PG1C1', 'P81PG1C1A1') ? (
    <Card bodyStyle={{ padding: 'unset' }}>
      <Tabs
        className="monitor-main-tabs customer-tab-margin"
        animated={false}
        size="large"
        onTabClick={handleChangeTab}
        activeKey={activeTab}
        tabBarExtraContent={
          <div style={{ paddingRight: '45px' }} className="resourceAllowanceExtra">
            <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
              <Button01 style={{margin: '0px 0px 0px 10px'}} key="submit" type="primary" btnsize="wd_df">
                <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
              </Button01>
            </Dropdown>
          </div>
        }
      >
        {_.map(tabData, (item) => {
          return (
            <TabPane key={_.get(item, 'code')} tab={_.get(item, 'text')}>
              <MemberExpenseTable />
            </TabPane>
          );
        })}
      </Tabs>
    </Card>
  ) : null
  );
};

export default MemberExpenseContent;
