import React from 'react'
import { Table, Card, Popover, Row, Icon, Tag } from 'antd'
import Button01 from '../../../../components/v2/button_01'
import { PageSettings } from '../../../../config/page-settings'
import { useIntl, FormattedMessage } from 'react-intl';
import { saveOrderType } from '../../../../controllers/setting/task-type';
import { successNotification, errorNotification } from '../../../../components/v2/notification';
import _ from 'lodash'

export default ({ data, handleOpenModal, setTrigger }) => {
  const intl = useIntl();
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      className: 'onTop',
      key: 'index',
      width: '5%',
      render: (text, record, index) => record.index + 1
    },
    {
      title: intl.formatMessage({ id: 'settingTaskTypeColTaskType', defaultMessage: 'Task Type' }),
      dataIndex: 'name',
      className: 'onTop',
      width: '20%',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: `settingTaskTypeColDescription`, defaultMessage: 'Description' }),
      dataIndex: 'description',
      className: 'onTop',
      key: 'description',
      width: '30%',
    },
    {
      title: intl.formatMessage({ id: `planningColumnOrderType`, defaultMessage: 'Order Type' }),
      dataIndex: 'orderType',
      className: 'onTop',
      key: 'orderType',
      width: '40%',
      render: (text, record, index) => (
        <Row>
          <PageSettings.Consumer>
            {({ checkPermissionAction }) => (
              <div>
                {checkPermissionAction('P6S3PG1C1', 'P6S3PG1C1A3') ? (
                  <div>
                    {record.orderTypeList &&
                      record.orderTypeList.map((item) => (
                        <Tag
                          key={item}
                          closable
                          className="license-tag"
                          onClose={() => handleDelete(item, record)}
                        >
                          {item}
                        </Tag>
                      ))}
                  </div>
                ) : (
                  <div>
                    {record.orderTypeList &&
                      record.orderTypeList.map((item) => (
                        <Tag key={item} className="license-tag">
                          {item}
                        </Tag>
                      ))}
                  </div>
                )}
              </div>
            )}
          </PageSettings.Consumer>
        </Row>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: '5%',
      render: (text, record, index) => (
        <Popover
          placement="leftTop"
          content={
            <>
              <Row>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <>
                      {checkPermissionAction('P6S3PG1C1', 'P6S3PG1C1A3') ? (
                        <Button01
                          ghost
                          btnsize="wd_df"
                          type="link"
                          onClick={() => handleOpenModal(record)}
                        >
                          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                        </Button01>
                      ) : null}
                    </>
                  )}
                </PageSettings.Consumer>
              </Row>
            </>
          }
        >
          <Icon type="edit" />
        </Popover>
      ),
    },
  ];

  const handleDelete = async (item) => {
    const body = {
      taskTypeId: null
    }
    const response = await saveOrderType(item, body)
    if (response.status) {
      successNotification(_.get(response, 'data.status.message'))
      setTrigger(cur => !cur)
    } else {
      errorNotification(_.get(response, 'data.status.message'))
      setTrigger(cur => !cur)
    }
  }


  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div style={{ width: 'auto' }}>
          <Card
            className='hieght-auto'
            title={intl.formatMessage({ id: `menuTaskType`, defaultMessage: 'Task Type' }) }
          >
            <Table
              rowKey={record => record.index}
              dataSource={data}
              columns={columns}
              pagination={false}
            />
          </Card>
        </div>

      )
      }

    </PageSettings.Consumer>

  )
}