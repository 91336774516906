import React, { useEffect, useState } from 'react';
import Information from './information';
import Contact from './contact';
import Address from './address-book';
import TeamService from './team-service';
import { Row } from 'antd';
import style from './css/index.css';
import { useParams } from 'react-router';
import httpClient from '../../../components/axiosClient';
import _, { debounce } from 'lodash';
import { PageSettings } from '../../../config/page-settings';

export default () => {
  const comCode = localStorage.getItem('comCode');

  const paramCustomerId = useParams();
  const customerId = _.get(paramCustomerId, 'customer_id');
  const [informationData, setInformationData] = useState();
  const [contactData, setContactData] = useState();
  const [addressBookData, setAddressBookData] = useState();
  const [teamService, setTeamService] = useState();
  const [triggerAddContact, setTriggerAddContact] = useState(false);
  const [triggerAddToLocation, setTriggerAddToLocation] = useState(false);
  const [triggerTeamService, settriggerTeamService] = useState(false);
  const [triggerDeleteImg, settriggerDeleteImg] = useState(false);
  //-----------------Pagination-------------------------------------//
  const [pageCon, setPageCon] = useState(1);
  const [sizeCon, setSizeCon] = useState(10);
  const [pageAdd, setPageAdd] = useState(1);
  const [sizeAdd, setSizeAdd] = useState(10);
  const [pageTeam, setPageTeam] = useState(1);
  const [sizeTeam, setSizeTeam] = useState(10);
  const [totalCon, setTotalCon] = useState(0);
  const [totalAdd, setTotalAdd] = useState(0);
  const [totalTeam, setTotalTeam] = useState(0);
  const [selectSearchCon, setSelectSearchCon] = useState('all');
  const [selectObjCon, setSelectObjCon] = useState();
  const [selectSearchAdd, setSelectSearchAdd] = useState('all');
  const [selectObjAdd, setSelectObjAdd] = useState();
  const [sortCon, setSortCon] = useState([]);
  const [sortAdd, setSortAdd] = useState([]);
  const [sortTeam, setSortTeam] = useState([]);
  const [fieldChangeCon, setFieldChangeCon] = useState('');
  const [fieldChangeAdd, setFieldChangeAdd] = useState('');
  const [fieldChangeTeam, setFieldChangeTeam] = useState('');
  const [loadingCon, setLoadingCon] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingTeam, setLoadingTeam] = useState(false);


  useEffect(() => {
    getData();
  }, [customerId, triggerAddContact, triggerAddToLocation, triggerTeamService, triggerDeleteImg]);

  useEffect(() => {
    getContectData();
  }, [pageCon, sizeCon, triggerAddContact, fieldChangeCon, selectSearchCon,sortCon]);

  useEffect(() => {

    getAddressData()

  }, [pageAdd, sizeAdd,fieldChangeAdd,selectSearchAdd,sortAdd, triggerAddToLocation])

  const getData = async () => {
    try {
      const response = await httpClient.get(
        `/v3/business-partner/manager/company/${comCode}/customer-informations/${customerId}?disableContact=Y&disableAddress=Y`
      );

      if (response.status == 200) {
        setInformationData(response.data.data);
        // setContactData(response.data.data.contacts);
        // setAddressBookData(response.data.data.addressBooks);
        setTeamService(response.data.data.teamservice);
        setTotalTeam(response.data.data.teamservice.length);
      }
    } catch (error) {}
  };

  const getContectData = async () => {
    setLoadingCon(true);
    try {
      const body = {
        pageNumber: pageCon,
        limit: sizeCon,
        orderBy: _.get(sortCon, 'field') ,
        orderType: _.get(sortCon, 'order') === 'descend' ? 'desc' : 'asc',
        searchObj:
          selectSearchCon === 'all'
            ? {
                customerContactName: fieldChangeCon,
                customerContactEmail: fieldChangeCon,
                customerContactPhone: fieldChangeCon,
                position: fieldChangeCon,
              }
            : selectObjCon,
        customerId: customerId,
      };
      
      const response = await httpClient.post(`/v5/business-partner/manager/company/${comCode}/web/customer-contacts/search`, body);

      if (response.status == 200) {
        setContactData(response.data.data.list);
        setTotalCon(response.data.data.totalItem);
        setLoadingCon(false);
      }
    } catch (error) {}
  };

  const getAddressData = async () => {
    setLoadingAdd(true);
    try {
      const body = {
        pageNumber: pageAdd,
        limit: sizeAdd,
        orderBy: _.get(sortAdd, 'field') ,
        orderType: _.get(sortAdd, 'order') === 'descend' ? 'desc' : 'asc',
        searchObj:
          selectSearchAdd === 'all'
            ? {
              address: fieldChangeAdd,
              addressName: fieldChangeAdd,
              }
            : selectObjAdd,
        customerId: customerId,
      };

      
      const response = await httpClient.post(`/v5/business-partner/manager/company/${comCode}/web/customer-address/search`, body);
      if (response.status == 200) {
        setAddressBookData(response.data.data.list);
        setTotalAdd(response.data.data.totalItem);
        setLoadingAdd(false);
      }
    } catch (error) {}
  };

  const handleSearchCon = debounce((fieldChang) => {
    if (selectSearchCon === 'all') {
      setSelectObjCon({
        customerContactName: fieldChang,
        customerContactEmail: fieldChang,
        customerContactPhone: fieldChang,
        position: fieldChang,
      });
      setFieldChangeCon(fieldChang);
    }
    else {
      setSelectObjCon({
        [selectSearchCon]: fieldChang,
      });
      setFieldChangeCon(fieldChang);
    }
    
  }, 500);

  const handleSelectDataCon = (value) => {
    if (value === 'all') {
      setSelectObjCon({
        customerContactName: fieldChangeCon,
        customerContactEmail: fieldChangeCon,
        customerContactPhone: fieldChangeCon,
        position: fieldChangeCon,
      });
      setSelectSearchCon(value === undefined ? 'all' : value);
    }
    else {
      setSelectSearchCon(value === undefined ? 'all' : value);
      setSelectObjCon({
        [value]: fieldChangeCon,
      });
    }
  };

  const handleSearchAdd = debounce((fieldChang) => {
    if (selectSearchAdd === 'all') {
      setSelectObjAdd({
        address: fieldChang,
        addressName: fieldChang,
      });
      setFieldChangeAdd(fieldChang);
    }
    else {
      setSelectObjAdd({
        [selectSearchAdd]: fieldChang,
      });
      setFieldChangeAdd(fieldChang);
    }
  }, 500);

  const handleSelectDataAdd = (value) => {

    if (value === 'all') {
      setSelectObjAdd({
        address: fieldChangeAdd,
              addressName: fieldChangeAdd,
      });
      setSelectSearchAdd(value === undefined ? 'all' : value);
    }
    else {
      setSelectSearchAdd(value === undefined ? 'all' : value);
      setSelectObjAdd({
        [value]: fieldChangeAdd,
      });
    }
  };

  const onChangeTableCon = (value, index, sort) => {
    setSortCon(sort);
  };

  const onChangeTableAdd = (value, index, sort) => {
    setSortAdd(sort);
  };

  const onChangeTableTeam = (value, index, sort) => {
    setSortTeam(sort);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div className="padding-card-component-top">
          <Row className="padding-card-component">
            {checkPermissionAction('P8PG2C1', 'P8PG2C1A1') ? (
              <Information
                informationData={informationData}
                setTriggerAddContact={setTriggerAddContact}
                triggerAddContact={triggerAddContact}
                settriggerDeleteImg={settriggerDeleteImg}
                triggerDeleteImg={triggerDeleteImg}
              />
            ) : null}
          </Row>
          <Row className="padding-card-component">
            {checkPermissionAction('P8PG2C2', 'P8PG2C2A1') ? (
              <Contact
                contactData={contactData}
                informationData={informationData}
                setTriggerAddContact={setTriggerAddContact}
                triggerAddContact={triggerAddContact}
                setPageCon={setPageCon}
                setSizeCon={setSizeCon}
                selectSearchCon={selectSearchCon}
                onSelect={handleSelectDataCon}
                total={totalCon}
                onChange={handleSearchCon}
                onChangeTableCon={onChangeTableCon}
                loading={loadingCon}
                pageCon={pageCon}
              />
            ) : null}
          </Row>
          <Row className="padding-card-component">
            {checkPermissionAction('P8PG2C3', 'P8PG2C3A1') ? (
              <Address
                addressBookData={addressBookData}
                informationData={informationData}
                setTriggerAddToLocation={setTriggerAddToLocation}
                triggerAddToLocation={triggerAddToLocation}
                setPageAdd={setPageAdd}
                setSizeAdd={setSizeAdd}
                selectSearchAdd={selectSearchAdd}
                onSelect={handleSelectDataAdd}
                total={totalAdd}
                onChange={handleSearchAdd}
                onChangeTableAdd={onChangeTableAdd}
                loading={loadingAdd}
                pageAdd={pageAdd}
              />
            ) : null}
          </Row>
          <Row className="padding-card-component">
            {checkPermissionAction('P8PG2C4', 'P8PG2C4A1') ? (
              <TeamService
                customerId={customerId}
                settriggerTeamService={settriggerTeamService}
                triggerTeamService={triggerTeamService}
                teamService={teamService}
                setPageTeam={setPageTeam}
                setSizeTeam={setSizeTeam}
                total={totalTeam}
                onChangeTableTeam={onChangeTableTeam}
                loading={loadingTeam}
                pageTeam={pageTeam}
              />
            ) : null}
          </Row>
        </div>
      )}
    </PageSettings.Consumer>
  );
};
