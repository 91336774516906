import React from 'react';
import '../css/summary.css';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import { Button, Divider } from 'antd';

export default (props) => {
  console.log('tabChecked', props.tabChecked);
  return (
    <div>
      <div className={props.tabChecked === '3' ? 'summary-list-row-totals-task-3' : 'summary-list-row-totals-task'}>
        {props.tabChecked === '3' && (
          <>
            <div className="summary-list-row-totals-task-text">
              <FormattedMessage id={`monitorTabSummaryTitle${props.LangCode}`} defaultMessage="Summary" />
            </div>
          </>
        )}

        {/* <div className='summary-list-row-totals-task-text'>
          <FormattedMessage id={`summaryTaskAll${props.LangCode}`} defaultMessage='Tasks' />
        </div> */}
        {/* <div className='summary-list-row-totals-task-text'>
          <FormattedMessage id={`summaryTaskTotals${props.LangCode}`} defaultMessage='Totals' />: <NumberFormat value={_.get(props, 'summaryData.dataAll') || 0} displayType={'text'} thousandSeparator={true} />
        </div> */}
      </div>
      {props.tabChecked === '3' ? <Divider type="horizontal" className="divider-summary-list-custom" /> : null
      // <>
      //  <div className="summary-list-row-totals-task">
      //    <div className="summary-list-row-totals-task-text">
      //      <FormattedMessage id={`summaryTaskAll${props.LangCode}`} defaultMessage="Tasks" />
      //     </div>
      //   </div>{' '}
      //   <Divider type="horizontal" className="divider-summary-list-custom" />
      // </>
      }

      <div className="summary-list-row">
        <div className="summary-list-totals warning-color-summary">
          <Button style={{ color: '#f88512' }} type="link" onClick={() => props.handleLinkWarning()} ghost>
            <p className="summary-list-totals-number">
              {_.get(props, 'summaryData.dataWarning') > 99 ? '99+' : _.get(props, 'summaryData.dataWarning') || 0}
            </p>

            <p className="summary-list-totals-text">
              <FormattedMessage id={`summaryTaskWarning${props.LangCode}`} defaultMessage="Warning" />
            </p>
          </Button>
        </div>
        <div className="summary-list-totals delayed-color-summary">
          <Button style={{ color: '#f3442e' }} type="link" onClick={() => props.handleLinkDeley()} ghost>
            <p className="summary-list-totals-number">
              {_.get(props, 'summaryData.dataTimeout') > 99 ? '99+' : _.get(props, 'summaryData.dataTimeout') || 0}
            </p>
            <p className="summary-list-totals-text">
              <FormattedMessage id={`summaryTaskDelayed${props.LangCode}`} defaultMessage="Delayed" />
            </p>
          </Button>
        </div>
        <Button style={{ color: '#21723e' }} type="link" onClick={() => props.handleLinkCompleted()} ghost>
          <div className="summary-list-totals completed-color-summary">
            <p className="summary-list-totals-number">
              {_.get(props, 'summaryData.dataCompleted') > 99 ? '99+' : _.get(props, 'summaryData.dataCompleted') || 0}
            </p>
            <p className="summary-list-totals-text">
              <FormattedMessage id={`summaryTaskCompleted${props.LangCode}`} defaultMessage="Completed" />
            </p>
          </div>
        </Button>
      </div>
    </div>
  );
};
