import React, { useState, useEffect, useRef, useContext } from 'react';
import { Card, Col, Row, Tabs } from 'antd';
import { MonitorContext } from '../transportation-context';
import { getMemberMapView } from '../../../controllers/monitor/mapView';
import { getSearchResource } from '../../../controllers/monitor/initial';
import TaskPool from './gantt/taskpool';
import MemberTabs from './map/member-tabs';
import MemberSelectTabs from './list/member-select-tabs';
import Filter from './components/filter';
import SummaryTabs from './components/summary';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import './css/index.css';
import './css/summary.css';
import ActionDatepicker from './components/action-datepicker';

const { TabPane } = Tabs;

export default (props) => {
  const { tabChecked, scheduleObjNew, LangCode, orgData } = props;
  const intl = useIntl();
  const { state, setState, fnc } = useContext(MonitorContext);
  const [memberCount, setMemberCount] = useState(0);
  const [members, setMembers] = useState([]);
  const [membersView, setMembersView] = useState([]);
  const [membersSearch, setMembersSearch] = useState('');

  const [membersList, setMembersList] = useState([]);
  const [membersViewList, setMembersViewList] = useState([]);
  const [membersSearchList, setMembersSearchList] = useState('');

  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);

  const [countChecked, setCountChecked] = useState(0);
  const [memberCountList, setMemberCountList] = useState(0);

  const [warningCheck, setWarningCheck] = useState([]);
  const [deleyCheck, setDeleyCheck] = useState([]);
  const [compleCheck, setCompleCheck] = useState([]);

  useEffect(() => {
    if (membersViewList.length >= 0) {
      const arrMember = membersViewList
        .filter((item) => item.checked === true)
        .map((o) => {
          return {
            memComId: o.memComId,
          };
        });
      setState.setMemberListView(arrMember);
    }
  }, [membersViewList]);

  useEffect(() => {
    const searchResource = async () => {
      const body = {
        orgId: state.checkedListTeam,
        search: '',
        searchBy: 'assigneeName',
      };
      const type = 'mapview';
      const response = await getSearchResource(body, type);
      if (response.status === 200) {
        const member = _.get(response.data, 'data') || [];
        setMembers(member);
        setMembersView(member);
        setMemberCount(_.size(member));
      }
    };
    if (state.keyTabMonitor === '2') {
      searchResource();
    }
  }, [state.checkedListTeam, membersSearch, state.keyTabMonitor]);

  useEffect(() => {
    const searchResource = async () => {
      const body = {
        orgId: state.checkedListTeam,
        search: '',
        searchBy: 'assigneeName',
      };
      const type = 'mapview';
      const response = await getSearchResource(body, type);
      if (response.status === 200) {
        const member = _.get(response.data, 'data') || [];
        const memberNew = member.map((item) => {
          return {
            fullname: item.fullname,
            memComId: item.memComId,
            phone: item.phone,
            pathImage: item.pathImage,
            checked: true,
          };
        });
        setMembersList(memberNew);
        setMembersViewList(memberNew);
        setMemberCountList(_.size(member));
        setCountChecked(_.size(member));
      }
    };
    if (state.keyTabMonitor === '2') {
      searchResource();
    }
  }, [state.checkedListTeam, state.keyTabMonitor]);

  const onCheckAllChange = (e) => {
    const newMembersView = [...membersViewList];
    if (e.target.checked) {
      const newArr = newMembersView.map((item) => {
        return {
          fullname: item.fullname,
          memComId: item.memComId,
          phone: item.phone,
          pathImage: item.pathImage,
          checked: true,
        };
      });
      setMembersViewList(newArr);
    } else {
      const newArr = newMembersView.map((item) => {
        return {
          fullname: item.fullname,
          memComId: item.memComId,
          phone: item.phone,
          pathImage: item.pathImage,
          checked: false,
        };
      });
      setMembersViewList(newArr);
    }
    let countMember = e.target.checked === true ? _.size(newMembersView) : 0;
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    setMemberCountList(_.size(newMembersView));
    setCountChecked(countMember);
  };

  const handleClickMemberList = (value) => {
    const memComId = value.memComId;
    const checked = value.checked;
    let newMembersView = [...membersViewList];
    let nCheckAll = false;
    let nIndeterminate = false;

    if (checkAll === true) {
      newMembersView = _.map(membersViewList, (item) => {
        return {
          fullname: item.fullname,
          memComId: item.memComId,
          phone: item.phone,
          pathImage: item.pathImage,
          checked: item.memComId === memComId ? true : false,
        };
      });
      nIndeterminate = true;
    } else {
      const findIndex = _.findIndex(membersViewList, (o) => {
        return o.memComId === memComId;
      });
      if (findIndex > -1) {
        newMembersView[findIndex].checked = !checked;
      }
    }

    let countMember = _.size(newMembersView);
    let countMemberChecked = _.sumBy(newMembersView, (o) => {
      return o.checked ? 1 : 0;
    });

    if (countMember === countMemberChecked) {
      nCheckAll = true;
      nIndeterminate = false;
    } else {
      if (countMemberChecked > 0) {
        nIndeterminate = true;
      }
    }
    setMemberCountList(countMember);
    setCountChecked(countMemberChecked);
    setCheckAll(nCheckAll);
    setIndeterminate(nIndeterminate);
    setMembersViewList(newMembersView);
  };

  const handleClearMemberList = () => {
    const newMembersView = [...membersViewList];
    const newArr = newMembersView.map((item) => {
      return {
        fullname: item.fullname,
        memComId: item.memComId,
        phone: item.phone,
        pathImage: item.pathImage,
        checked: false,
      };
    });
    setCountChecked(0);
    setIndeterminate(false);
    setMembersViewList(newArr);
    setCheckAll(false);
  };

  const TabTaskPool = (
    <div>
      <span>
        {intl.formatMessage({ id: `monitorTabPendingTitle${LangCode}`, defaultMessage: 'Pending' })}
        {` (${state.totalTaskPool})`}
      </span>
    </div>
  );

  const TabMember = (
    <div>
      <span>
        {intl.formatMessage({ id: `monitorTabMembersTitle${LangCode}`, defaultMessage: 'Members' })}
        {` (${memberCount})`}
      </span>
    </div>
  );

  const TabMemberList = (
    <div>
      <span>
        {intl.formatMessage({ id: `monitorTabMembersTitle${LangCode}`, defaultMessage: 'Members' })}
        {` (${memberCountList})`}
      </span>
    </div>
  );

  const handleLinkWarning = () => {
    setWarningCheck(['Warning']);
  };

  const handleLinkDeley = () => {
    setDeleyCheck(['Delay']);
  };

  const handleLinkCompleted = () => {
    setCompleCheck(['Completed']);
  };

  return (
    <Card className="left-card">
      {tabChecked !== '3' ? (
        <Tabs className="left-tabs-manu-monitor" defaultActiveKey="1">
          <TabPane
            style={{ width: '100%' }}
            tab={intl.formatMessage({ id: `monitorTabSummaryTitle${LangCode}`, defaultMessage: 'Summary' })}
            key="Summary"
          >
            <div className="total-text-summary">
              <SummaryTabs
                summaryData={state.summaryData}
                LangCode={LangCode}
                handleLinkWarning={handleLinkWarning}
                handleLinkDeley={handleLinkDeley}
                handleLinkCompleted={handleLinkCompleted}
              />
            </div>
            <div className="filter-text-summary-div scroll-sm">
              <div className="filter-text-summary-text">
                <span className="filter-text-summary-text-size">
                  {intl.formatMessage({ id: `monitorTabFilterTitle${LangCode}`, defaultMessage: 'Filter' })}
                </span>
              </div>
              <Filter
                type={tabChecked === '1' ? 'ganttView' : tabChecked === '2' ? 'mapView' : 'listView'}
                data={state.initialData}
                mainOrgId={state.mainOrgId}
                checkedListStage={state.checkedListStage}
                checkedListSla={state.checkedListSla}
                checkedListAssign={state.checkedListAssign}
                checkedListMap={state.checkedListMap}
                setCheckedListStage={setState.setCheckedListStage}
                setCheckedListSla={setState.setCheckedListSla}
                setCheckedListAssign={setState.setCheckedListAssign}
                setCheckedListMap={setState.setCheckedListMap}
                checkedListStageCount={state.checkedListStageCount}
                checkedListAssignCount={state.checkedListAssignCount}
                checkedListSlaCount={state.checkedListSlaCount}
                summaryData={state.summaryData}
                setPaginationPage={setState.setPaginationPage}
                LangCode={LangCode}
                orgData={orgData}
                setCheckedListTeam={setState.setCheckedListTeam}
                checkedListTeam={state.checkedListTeam}
                setMainOrgId={setState.setMainOrgId}
                setOrgIdRaw={setState.setOrgIdRaw}
                orgIdRaw={state.orgIdRaw}
                selectGroupBy={state.selectGroupBy}
                keyTabMonitor={state.keyTabMonitor}
                warningCheck={warningCheck}
                deleyCheck={deleyCheck}
                compleCheck={compleCheck}
                setEventSelectGroup={setState.setEventSelectGroup}
              />
              <div>
                {tabChecked === '3' && (
                  <ActionDatepicker
                    handleChangeDateStart={fnc.handleChangeStartDate}
                    handleChangeDateEnd={fnc.handleChangeDueDate}
                    startDate={state.startDate}
                    endDate={state.dueDate}
                    setCustomerListView={setState.setCustomerListView}
                    LangCode={LangCode}
                  />
                )}
              </div>
            </div>
          </TabPane>
          {tabChecked === '1' && (
            <TabPane tab={TabTaskPool} key="Pending">
              <TaskPool
                scheduleObjNew={scheduleObjNew}
                taskPoolData={state.taskPoolData}
                setTriggerTaskPool={setState.setTriggerTaskPool}
                setMainTaskId={setState.setMainTaskId}
                setVisibleTaskDetailModal={setState.setVisibleTaskDetailModal}
                LangCode={LangCode}
                selectGroupBy={state.selectGroupBy}
                resultCheckTaskNo={state.resultCheckTaskNo}
              />
            </TabPane>
          )}
          {tabChecked === '2' && (
            <TabPane tab={TabMember} key="members">
              <MemberTabs
                setMembersSearch={setMembersSearch}
                mainOrgId={state.mainOrgId}
                members={members}
                membersView={membersView}
                date={state.startDate}
                onClick={fnc.handleClickMember}
                onClickTrack={fnc.onClickTrack}
                setMembersView={setMembersView}
                LangCode={LangCode}
              />
            </TabPane>
          )}
          {/* {tabChecked === '3' && (
          <TabPane tab={TabMemberList} key="members-list">
            <MemberSelectTabs
              setMembersSearch={setMembersSearchList}
              mainOrgId={state.mainOrgId}
              members={membersList}
              membersView={membersViewList}
              date={state.startDate}
              onClick={handleClickMemberList}
              setMembersView={setMembersViewList}
              onCheckAllChange={onCheckAllChange}
              handleClearMemberList={handleClearMemberList}
              indeterminate={indeterminate}
              checkAll={checkAll}
              countChecked={countChecked}
              memberCountList={memberCountList}
              LangCode={LangCode}
            />
          </TabPane>
        )} */}
        </Tabs>
      ) : (
        <>
          <div className="total-text-summary">
            <SummaryTabs summaryData={state.summaryData} LangCode={LangCode} tabChecked={tabChecked} />
          </div>
          <div className="filter-text-summary-div scroll-sm">
            <div className="filter-text-summary-text">
              <span className="filter-text-summary-text-size">
                {intl.formatMessage({ id: `monitorTabFilterTitle${LangCode}`, defaultMessage: 'Filter' })}
              </span>
            </div>
            <Filter
              type={tabChecked === '1' ? 'ganttView' : tabChecked === '2' ? 'mapView' : 'listView'}
              data={state.initialData}
              mainOrgId={state.mainOrgId}
              checkedListStage={state.checkedListStage}
              checkedListSla={state.checkedListSla}
              checkedListAssign={state.checkedListAssign}
              checkedListMap={state.checkedListMap}
              setCheckedListStage={setState.setCheckedListStage}
              setCheckedListSla={setState.setCheckedListSla}
              setCheckedListAssign={setState.setCheckedListAssign}
              setCheckedListMap={setState.setCheckedListMap}
              checkedListStageCount={state.checkedListStageCount}
              checkedListAssignCount={state.checkedListAssignCount}
              checkedListSlaCount={state.checkedListSlaCount}
              summaryData={state.summaryData}
              setPaginationPage={setState.setPaginationPage}
              LangCode={LangCode}
              orgData={orgData}
              setCheckedListTeam={setState.setCheckedListTeam}
              checkedListTeam={state.checkedListTeam}
              setMainOrgId={setState.setMainOrgId}
              setOrgIdRaw={setState.setOrgIdRaw}
              orgIdRaw={state.orgIdRaw}
              selectGroupBy={state.selectGroupBy}
              keyTabMonitor={state.keyTabMonitor}
              warningCheck={warningCheck}
              deleyCheck={deleyCheck}
              compleCheck={compleCheck}
              setEventSelectGroup={setState.setEventSelectGroup}
            />
            <div>
              {tabChecked === '3' && (
                <ActionDatepicker
                  handleChangeDateStart={fnc.handleChangeStartDate}
                  handleChangeDateEnd={fnc.handleChangeDueDate}
                  startDate={state.startDate}
                  endDate={state.dueDate}
                  setCustomerListView={setState.setCustomerListView}
                  LangCode={LangCode}
                />
              )}
            </div>
          </div>
        </>
      )}
    </Card>
  );
};
