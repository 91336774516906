import React, { useState, useCallback, useRef } from 'react';
import { Card, Table, Popover, Button, Icon, Modal, Row, Col, Input, Select } from 'antd';
import Button03 from '../../../components/v2/button_03';
import Button01 from '../../../components/v2/button_01';
import CreateModal from '../../../components/modal-create-adress-map-v2/index';
import EditModal from '../../../components/modal-edit-adress-map-v2/index';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { successNotification, errorNotification, warningNotification } from '../../../components/v2/notification';
import httpClient from '../../../components/axiosClient';
import { PageSettings } from '../../../config/page-settings';
import moment from 'moment';

const { Option } = Select;

export default ({
  addressBookData,
  informationData,
  setTriggerAddToLocation,
  triggerAddToLocation,
  setPageAdd,
  setSizeAdd,
  selectSearchAdd,
  onSelect,
  total,
  onChange,
  onChangeTableAdd,
  loading,
  pageAdd,
}) => {
  const intl = useIntl();
  const { confirm } = Modal;
  const comCode = localStorage.getItem('comCode');

  const [visiblePopover, setVisiblePopover] = useState(false);
  const [valueDefaultEdit, setValueDefaultEdit] = useState();
  const [latlngToEdit, setLatlngToEdit] = useState({});
  const [visibleModalCreate, setvisibleModalCreate] = useState(false);
  const [visibleModalEdit, setvisibleModalEdit] = useState(false);
  const [formRef, setFormRef] = useState(null);
  const [formRefEditLocation, setFormRefEditLocation] = useState(null);

  const customerName = _.get(informationData, 'customerName');
  const customerId = _.get(informationData, 'customerId');

  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const ignoreSearchColumn = ['index', 'serviceTime'];

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
    },
    {
      title: intl.formatMessage({ id: 'customerAddressBookTableColumnAddressName', defaultMessage: 'Address Name' }),
      dataIndex: 'addressName',
      key: 'addressName',
      width: '10%',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'ModalLocationLocationAddress', defaultMessage: 'Address' }),
      dataIndex: 'address',
      key: 'address',
      width: '30%',
      sorter: true,
    },
    // {
    //   title: intl.formatMessage({ id: 'ModalLocationLocationMapLocation', defaultMessage: 'Map Location' }),
    //   dataIndex: 'fullAddress',
    //   key: 'fullAddress',
    //   width: '30%',
    // },
    {
      title: intl.formatMessage({ id: 'ModalLocationLocationServiceTime', defaultMessage: 'Service Time' }),
      dataIndex: 'serviceTime',
      key: 'serviceTime',
      width: '20%',
      render: (text, record, index) => {
        const mapRecord = record.serviceTime.map((item, index) => {
          return `${moment(item.startTime, 'HH:mm:ss').format('HH:mm')} - ${moment(item.endTime, 'HH:mm:ss').format('HH:mm')}  ${
            index !== record.serviceTime.length - 1 ? ', ' : ''
          }`;
        });
        return <span>{mapRecord}</span>;
      },
    },
    // {
    //   title: '',
    //   key: 'options',
    //   dataIndex: 'options',
    //   align: 'center',
    //   width: '5%',
    //   render: (text, record, index) => fncActionTable(record, index),
    // },
  ];

  const selectSearchColumn = columns.filter((col) => !ignoreSearchColumn.includes(col.key));

  const handleEditCancel = () => {
    formRefEditLocation.resetFields();
    setvisibleModalEdit(false);
    setValueDefaultEdit();
    // setValueAddressEdit("");
    setLatlngToEdit({});
  };

  const saveFormRefEditLocation = useCallback((node) => {
    if (node !== null) {
      setFormRefEditLocation(node);
    }
  }, []);

  const handleEdit = (valueDefaultEdit) => {
    formRefEditLocation.validateFields(async (err, values) => {
      if (err) {
        return;
      } else {
        const customerAddressBookId = _.get(valueDefaultEdit, 'customerAddressBookId');
        const body = {
          customerId: customerId,
          addressName: values.locationName,
          customerName: customerName,
          contactName: undefined,
          fullAddress: values.location,
          address: values.location,
          subDistrict: undefined,
          district: undefined,
          province: undefined,
          postCode: undefined,
          lat: latlngToEdit.lat,
          lng: latlngToEdit.lng,
          description: values.description,
        };
        try {
          const response = await httpClient.put(
            `/v3/business-partner/manager/company/${comCode}/customer-address-books/${customerAddressBookId}`,
            body
          );
          if (response.status == 200) {
            formRefEditLocation.resetFields();
            setvisibleModalEdit(false);
            setLatlngToEdit({});
            setTriggerAddToLocation(!triggerAddToLocation);
            successNotification(response.data.status.message);
          } else {
            formRefEditLocation.resetFields();
            setvisibleModalEdit(false);
            setLatlngToEdit({});
          }
        } catch (error) {
          formRefEditLocation.resetFields();
          setvisibleModalEdit(false);
          setLatlngToEdit({});
          errorNotification(error.response.data.status.message);
        }
      }
    });
  };

  const handleDelete = (customerAddressBookId) => {
    confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'customerAddressBookDeleteConfirmText', defaultMessage: 'Are you sure to delete address book ?' }),
      okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await httpClient.delete(
            `/v3/business-partner/manager/company/${comCode}/customer-address-books/${customerAddressBookId}`
          );
          if (response.status == 200) {
            setTriggerAddToLocation(!triggerAddToLocation);
            if (response.data.status.message !== '') {
              successNotification(response.data.status.message);
            } else {
              successNotification('Delete Success');
            }
          } else {
            errorNotification(response.data.status.message);
          }
        } catch (error) {
          return;
        }
      },
      onCancel() {
        return;
      },
    });
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          // zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P8PG2C3', 'P8PG2C3A3') ? (
                        <Button
                          style={{ width: 100 }}
                          type="link"
                          ghost
                          onClick={() => {
                            setvisibleModalEdit(true);
                            setValueDefaultEdit(record);
                            handleMouseLeave();
                          }}
                        >
                          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P8PG2C3', 'P8PG2C3A4') ? (
                        <Button
                          style={{ width: 100 }}
                          type="link"
                          ghost
                          onClick={() => {
                            handleDelete(record.customerAddressBookId);
                            handleMouseLeave();
                          }}
                        >
                          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  // const fncActionTable = (record, index) => {
  //   return (
  //     <div>
  //       <Popover
  //         key={index}
  //         placement="leftTop"
  //         trigger="hover"
  //         visiblePopover={visiblePopover}
  //         onVisibleChange={handleOnVisiblePopover}
  //         zIndex={999}
  //         content={
  //           <div style={{ display: 'grid' }}>
  //             <>
  //               <PageSettings.Consumer>
  //                 {({ checkPermissionAction }) => (
  //                   <div>
  //                     {checkPermissionAction('P8PG2C3', 'P8PG2C3A3') ? (
  //                       <Button
  //                         style={{ width: 100 }}
  //                         type="link"
  //                         ghost
  //                         onClick={() => {
  //                           setvisibleModalEdit(true);
  //                           setValueDefaultEdit(record);
  //                         }}
  //                       >
  //                         <FormattedMessage id="btnEdit" defaultMessage="Edit" />
  //                       </Button>
  //                     ) : null}
  //                   </div>
  //                 )}
  //               </PageSettings.Consumer>
  //             </>
  //             <>
  //               <PageSettings.Consumer>
  //                 {({ checkPermissionAction }) => (
  //                   <div>
  //                     {checkPermissionAction('P8PG2C3', 'P8PG2C3A4') ? (
  //                       <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record.customerAddressBookId)}>
  //                         <FormattedMessage id="btnDelete" defaultMessage="Delete" />
  //                       </Button>
  //                     ) : null}
  //                   </div>
  //                 )}
  //               </PageSettings.Consumer>
  //             </>
  //           </div>
  //         }
  //       >
  //         <Icon type="more" />
  //       </Popover>
  //     </div>
  //   );
  // };

  const handlePagination = (page) => {
    setPageAdd(page);
  };

  const handleSizeChange = (current, size) => {
    setPageAdd(current);
    setSizeAdd(size);
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px', float: 'left' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page:" />
      </span>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Card
            title={intl.formatMessage({ id: 'customerAddressBookCardHeader', defaultMessage: 'Address Book' })}
            extra={
              checkPermissionAction('P8PG2C3', 'P8PG2C3A2') ? (
                <Button01 type="primary" onClick={() => setvisibleModalCreate(true)}>
                  <FormattedMessage id="btnAdd" defaultMessage="Add" />
                </Button01>
              ) : null
            }
          >
            <Row className="padding-row">
              <Col span={7}>
                <Input
                  className="input-search action-input border-searth-allcol"
                  onChange={(e) => onChange(e.target.value)}
                  allowClear
                  placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
                  autoComplete="off"
                ></Input>
                <Select className="select-search action-select" value={selectSearchAdd} onSelect={(value) => onSelect(value)}>
                  <Option value="all">
                    {<FormattedMessage id="reportTimeAttendanceHintSeleteAllColumns" defaultMessage="All columns" />}
                  </Option>
                  {selectSearchColumn.map((item, i) => (
                    <Option key={i} value={item.key}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <div className="containerTables" onMouseLeave={handleMouseLeave}>
              <Table
                dataSource={addressBookData}
                columns={columns}
                onChange={onChangeTableAdd}
                loading={loading}
                pagination={{
                  showTotal: showTotal,
                  defaultCurrent: 1,
                  total: total,
                  current: pageAdd,
                  pageSizeOptions: ['10', '20', '30', '40', '50'],
                  showSizeChanger: true,
                  locale: { items_per_page: '' },
                  onChange: handlePagination,
                  onShowSizeChange: handleSizeChange,
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      if (!event.target.href) {
                        const { x, y } = ref.current.getBoundingClientRect();
                        setVisiblePopoverTable(true);
                        setOffsetPopover([event.pageX - x, event.pageY - y]);
                        setRecordPopover(record);
                      }
                    },
                  };
                }}
              />
              {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
            </div>
            {addressBookData && addressBookData.length !== 0 ? (
              <div className="total-items-timeAttendance">
                <span style={{ fontSize: '13px' }}>
                  <FormattedMessage id="lblTotal" defaultMessage="Total" />
                  {` ${total || 0} `}
                  <FormattedMessage id="lblitems" defaultMessage="items" />
                </span>
              </div>
            ) : null}
          </Card>

          <CreateModal
            visible={visibleModalCreate}
            setVisible={setvisibleModalCreate}
            customerId={customerId}
            customerName={customerName}
            setTrigger={setTriggerAddToLocation}
          />

          <EditModal
            visible={visibleModalEdit}
            setVisible={setvisibleModalEdit}
            setTrigger={setTriggerAddToLocation}
            valueDefault={valueDefaultEdit}
            setValueDefault={setValueDefaultEdit}
            customerId={customerId}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
};
