import React, { useRef, useState, useEffect } from 'react';
import { Table, Popover, Button } from 'antd';
import Button_01 from '../../v2/button_01';
import './css/index.css';
import { FormattedMessage } from 'react-intl';


const UploadTable = (props) => {
  const { setVisible, checkedType, attData, setTypeAtt, setDefaultAtt, handleDeleteIMG, handlePreview } = props;
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const ref = useRef();

  const columns = [
    {
      title: '#',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'File Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '150px' }}>{record.name}</div>
    },
    {
      title: 'Size (MB)',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      render: (text, record, index) => <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' }}>{record.remark !== "undefined" ? record.remark : ''}</div>
    },
  ];

  const handleUpload = () => {
    setTypeAtt("add")
    setVisible(true);
  };

  const handleEdit = (record) => {
    setTypeAtt("edit")
    setDefaultAtt(record)
    setVisible(true)
  }


  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  console.log("attData :", attData)

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          // key={index}
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          // visiblePopover={visiblePopover}
          // onVisibleChange={handleOnVisiblePopover}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handlePreview(record)}>
                  <FormattedMessage id="btnView" defaultMessage="View" />
                </Button>
              </div>
              {
                checkedType !== "view" ? (
                  <div>
                    <div>
                      <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEdit(record)}>
                        <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                      </Button>
                    </div>
                    <div>
                      <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDeleteIMG(record.fileHash)}>
                        <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                      </Button>
                    </div>
                  </div>
                ) : null
              }
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  return (
    <div>
      <div className="spaceTitle">
        <div className="title">Attachment</div>
        <div>
          {checkedType === 'edit' || checkedType === 'add' ? (
            <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleUpload} style={{ margin: 'unset' }}>
              <FormattedMessage id="btnUpload" defaultMessage="Upload" />
            </Button_01>
          ) : null}
        </div>
      </div>
      <div onMouseLeave={handleMouseLeave}>
        <Table
          size="middle"
          columns={columns}
          dataSource={attData}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href) {
                  const { x, y } = ref.current.getBoundingClientRect();
                  setVisiblePopoverTable(true);
                  setOffsetPopover([event.pageX - x, event.pageY - y]);
                  setRecordPopover(record);
                }
              },
            };
          }}
        />
        {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
      </div>
    </div>
  );
};

export default UploadTable;
