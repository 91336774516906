import React, { useContext, useState } from 'react';
import { PageSettings } from '../../config/page-settings';
import { QuotationContext } from './context';
import { StickyContainer } from 'react-sticky';
import { Button, Card, Col, Dropdown, Menu, Row, Tabs } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';

import Button_01 from '../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import WaitingQuotation from './component-waiting/waiting';
import ApprovedQuotation from './component-approved/approved';
import RejectedQuotation from './component-rejected/rejected';
import CanceledQuotation from './component-canceled/canceled';
// import QuotationModal from './modal/quotation/quotation-modal';
import ModalCustomerQuotationForm from './modal/quotation/quotation-modal';
import UploadFileModal from './modal/upload-file/upload-modal';
import _ from 'lodash';

const { TabPane } = Tabs;

const QuotationPage = () => {
  const intl = useIntl();
  const { state, setState, func } = useContext(QuotationContext);
  const {
    isOpenQuotation,
    openUpload,
    attachmentData,
    typeAttachment,
    defaultAttachment,
    initialMemberList,
    record,
    reportDataWaiting,
  } = state;
  const { seIsOpenQuotation, setAttachmentData, setTypeAttachment, setDefaultAttachment, setOpenUpload, setTabKey } = setState;
  const { handleOpenModalQuotation, handleSaveQuotation, handleCancelQuotation,refreshModalQuotation, handleExport } = func;
  const { quotationList, quotationTotal, paginationPage, loading, rangePickerDate } = reportDataWaiting;

  const [visible, setVisible] = useState(false);

  console.log('record', record);
  console.log('intl', intl)

  const handleOpenModal = () => {
    handleOpenModalQuotation();
    setVisible(!visible);
  };

  const menuColumn = () => {
    return (
      // <PageSettings.Consumer>
      //   {({ checkPermissionAction }) => (
      <Menu>
        {/* {checkPermissionAction(`P49PG2C2`, `P49PG2C2A2`) ? ( */}
        <Menu.Item key="1">
          <Button key="1" ghost type="link" onClick={() => handleOpenModal()}>
            <FormattedMessage id="quotationLabelCreateOuotation" defaultMessage="Create Quotation" />
          </Button>
        </Menu.Item>
        {/* ) : null} */}
        {/* {checkPermissionAction(`P49PG2C2`, `P49PG2C2A3`) ? ( */}
        <Menu.Item key="2">
          <Button key="2" ghost type="link" onClick={() => handleExport()}>
            <FormattedMessage id="quotationLabelExportExcel" defaultMessage="Export Excel" />
          </Button>
        </Menu.Item>
        {/* ) : null} */}
      </Menu>
      //   )}
      // </PageSettings.Consumer>
    );
  };

  const callback = (key) => {
    setTabKey(key);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              {/* {checkPermissionAction(`P22PG1C2`, `P22PG1C2A1`) ||
                checkPermissionAction(`P22PG1C3`, `P22PG1C3A1`) ||
                checkPermissionAction(`P22PG1C4`, `P22PG1C4A1`) ||
                checkPermissionAction(`P22PG1C5`, `P22PG1C5A1`) ? ( */}
              <Card className="monitor-main-card">
                <StickyContainer>
                  <Tabs
                    className="monitor-main-tabs customer-tab-margin"
                    size="large"
                    defaultActiveKey="waiting"
                    onChange={callback}
                    animated={false}
                    tabBarExtraContent={
                      <div>
                        <Dropdown
                          overlay={menuColumn}
                          trigger={['click']}
                          onVisibleChange={setVisible}
                          visible={visible}
                          placement="bottomRight"
                        >
                          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                            <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                            <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                          </Button_01>
                        </Dropdown>
                      </div>
                    }
                  >
                    {/* {checkPermissionAction(`P22PG1C3`, `P22PG1C3A1`) ? ( */}
                    <TabPane
                      tab={intl.formatMessage({ id: `quotationLabelWaiting`, defaultMessage: 'Waiting' }) + ` (${quotationTotal})`}
                      key="waiting"
                    >
                      <WaitingQuotation />
                    </TabPane>
                    {/* ) : null} */}

                    {/* {checkPermissionAction(`P22PG1C4`, `P22PG1C4A1`) ? ( */}
                    <TabPane tab={intl.formatMessage({ id: `quotationLabelApproved`, defaultMessage: 'Approved' })} key="approved">
                      <ApprovedQuotation />
                    </TabPane>
                    {/* ) : null} */}

                    {/* {checkPermissionAction(`P22PG1C5`, `P22PG1C5A1`) ? ( */}
                    <TabPane tab={intl.formatMessage({ id: `quotationLabelRejected`, defaultMessage: 'Rejected' })} key="rejected">
                      <RejectedQuotation />
                    </TabPane>
                    {/* ) : null} */}

                    {/* {checkPermissionAction(`P22PG1C5`, `P22PG1C5A1`) ? ( */}
                    <TabPane tab={intl.formatMessage({ id: `quotationLabelCanceled`, defaultMessage: 'Canceled' })} key="canceled">
                      <CanceledQuotation />
                    </TabPane>
                    {/* ) : null} */}
                  </Tabs>
                </StickyContainer>
              </Card>
              {/* ) : null} */}
            </Col>
          </Row>

          <ModalCustomerQuotationForm
            title={
              _.get(record, 'code') === 'view'
                ? `${intl.formatMessage({ id: `quotationLabelQuotation`, defaultMessage: 'Quotation' })} ${record.quotationNo ? '·' : ''} ${
                    record.quotationNo ? record.quotationNo : ''
                  }`
                : _.get(record, 'code') === 'edit'
                ? `${intl.formatMessage({ id: `quotationModalTitleEditQuotation`, defaultMessage: 'Edit Quotation' })} ${
                    record.quotationNo ? '·' : ''
                  } ${record.quotationNo ? record.quotationNo : ''}`
                : intl.formatMessage({ id: `quotationLabelCreateQuotation`, defaultMessage: 'Create Quotation' })
            }
            visible={isOpenQuotation}
            onOk={handleSaveQuotation}
            onCancel={handleCancelQuotation}
            initialMemberList={initialMemberList}
            attData={attachmentData}
            setAttData={setAttachmentData}
            record={record}
            refreshModalQuotation={refreshModalQuotation}
          />

          <UploadFileModal
            visible={openUpload}
            setVisible={setOpenUpload}
            setAttData={setAttachmentData}
            attData={attachmentData}
            typeAtt={typeAttachment}
            defaultAtt={defaultAttachment}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
};

export default QuotationPage;
