import httpClient from '../../components/axiosClient';

const getTaskInfo = async (taskId) => {

  const memComId = localStorage.getItem('memComId');
  const comId = localStorage.getItem('comId');

  try {
    const response = await httpClient.get(`/v4/task/manager/task-information/${memComId}/taskinform/${taskId}/information?comId=${comId}&orgId=undefined`);
    if (response.status === 200) {
      return response
    } else {
      return false
    }
  } catch (error) {
    return error.response
  }

}

const changeTeam = async (taskId, orgId) => {

  const body = {
    org_id: orgId,
  };

  try {
    const response = await httpClient.post(`/v3/task/manager/taskteam/update/taskid/${taskId}?menuType=transportation`, body);
    if (response.status === 200) {
      return response
    } else {
      return false
    }
  } catch (error) {
    return error.response
  }

}

const subTaskApi = async (taskId) => {

  const memComId = localStorage.getItem('memComId');
  const comId = localStorage.getItem('comId');

  try {
    const response = await httpClient.get(`/v4/task/manager/task-information/${memComId}/taskinform/${taskId}/subtask?comId=${comId}`);
    if (response.status === 200) {
      return response
    } else {
      return false
    }
  } catch (error) {
    return error.response
  }
}

const EditTruckWeight = async (body) => {
  const comCode = localStorage.getItem('comCode');
  const {id, subtaskCode, currentNumber} = body
  try {
    const response = await httpClient.put(`/v4/task/manager/company/${comCode}/subtask/${id}/subtask-code/${subtaskCode}`, {currentNumber});
    if (response.status === 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    //
  }
}

export { getTaskInfo, changeTeam, subTaskApi, EditTruckWeight };