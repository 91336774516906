import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Row } from 'antd';
import { PageSettings } from '../../config/page-settings';
import { MonitorContext } from './transportation-context';
import LeftCard from './left-card';
import RightCard from './right-card';
import { v4 as uuidv4 } from 'uuid';
import _, { debounce } from 'lodash';
import {
  getInitializeMonitorData,
  getSearchResource,
  getSummary,
  getSummaryCount,
  getDateDefault,
} from '../../controllers/monitor/initial';
import { getinitialTeamApi } from '../../controllers/organizationTeam/get-orgTeam-api';
import { getTaskGantt, getTaskPoolData, getTaskPoolDataNew } from '../../controllers/monitor/ganttView';
import { getTaskMapVeiw } from '../../controllers/monitor/mapView';
import { DataManager, Query } from '@syncfusion/ej2-data';
import TaskDetail from '../../components/monitor-component/transportation/task-detail/index';
import CreateTask from '../../components/monitor-component/transportation/create-task/index';
import AssignResource from '../../components/monitor-component/transportation/assignee-resource-modal/assignee-new';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';
import MemberInformation from '../../components/member-information';
import { getListViewData, exportListViewData, getURLexportTask } from '../../controllers/monitor/listView';
import httpClient from '../../components/axiosClient';
import { errorNotification, successNotification } from '../../components/v2/notification';
import { loadmoreCustomer } from '../../controllers/orders-controller/api';
import './css/index.css';
// import { dataOrg, dataResource, dataTask, dataTaskPool } from './constants'

const today = new Date();
const dateApi = 'YYYY-MM-DD';
const dateTitlelModal = 'ddd, MMM DD, YYYY';
let dataTaskGantt = null;
let dataResource = null;
let dataTaskPool = null;
let dataOrg = null;
let count = 0;

const comId = localStorage.getItem('comId');

export default () => {
  const LangCode = '';
  const scheduleObjNew = useRef(null);
  const intl = useIntl();
  const dateTimeApi = 'YYYY-MM-DD HH:mm';
  const [statusSummary, setStatusSummary] = useState(false);
  const [keyTabMonitor, setKeyTabMonitor] = useState('1');
  const [show, setShow] = useState(true);
  const [spanRight, setSpanRight] = useState(18);
  const [spanLeft, setSpanLeft] = useState(6);
  const [summaryData, setSummaryData] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [searchValueList, setSearchValueList] = useState('');
  const [selectBy, setSelectBy] = useState('all');
  const [selectByList, setSelectByList] = useState('all');
  const [startDate, setStartDate] = useState(today);
  const [dueDate, setDueDate] = useState(today);
  // const [startDate, setStartDate] = useState(moment(today).format("YYYY-MM-DD"));
  // const [dueDate, setDueDate] = useState(moment(today).format("YYYY-MM-DD"));
  const [mainOrgId, setMainOrgId] = useState([]);
  const [mainCustomerId, setMainCustomerId] = useState([]);
  const [customerIdRaw, setCustomerIdRaw] = useState([]);
  const [mainOrgIdStr, setMainOrgIdStr] = useState([]);
  const [orgIdRaw, setOrgIdRaw] = useState([]);
  const [initialData, setInitialData] = useState();
  const [orgData, setOrgData] = useState([]);
  const [orgGanttData, setOrgGanttData] = useState([]);
  const [taskDataMapView, setTaskDataMapView] = useState([]);
  const [taskDataMap, setTaskDataMap] = useState([]);
  const [resourceId, setResourceId] = useState(null);
  const [resourceIdChecked, setResourceIdChecked] = useState(false);
  const [selectResourceData, setSelectResourceData] = useState(null);
  const [selectTaskData, setSelectTaskData] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const [trackingResource, setTrackingResource] = useState(null);
  const [areaChecked, setAreaChecked] = useState(false);
  const [satelliteChecked, setSatelliteChecked] = useState(false);

  const [taskDataGanttView, setTaskDataGanttView] = useState([]);
  const [resourceData, setResourceData] = useState([]);
  const [resourceGanttData, setResourceGanttData] = useState([]);

  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [conutrowForExcel, setConutrowForExcel] = useState(1000);
  const [triggerDragDrop, setTriggerDragDrop] = useState(false);

  const [checkedListStage, setCheckedListStage] = useState([]);
  const [checkedListSla, setCheckedListSla] = useState([]);
  const [checkedListAssign, setCheckedListAssign] = useState([]);
  const [checkedListStageCount, setCheckedListStageCount] = useState([]);
  const [checkedListSlaCount, setCheckedListSlaCount] = useState([]);
  const [checkedListAssignCount, setCheckedListAssignCount] = useState([]);
  const [checkedListMap, setCheckedListMap] = useState([]);
  const [taskPoolData, setTaskPoolData] = useState([]);
  const [totalTaskPool, setTotalTaskPool] = useState('0');
  const [triggerTaskPool, setTriggerTaskPool] = useState(false);
  const [nowDate, setNowDate] = useState(moment(today).unix());
  const [visibleMemberInfor, setVisibleMemberInfor] = useState(false);
  const [dataMemberInfor, setDataMemberInfor] = useState(null);
  const [exportTemp, setExportTemp] = useState(0);
  //---------------- task detail -----------------------
  const [visibleTaskDetailModal, setVisibleTaskDetailModal] = useState(false);
  const [visibleTaskStatusModal, setVisibleTaskStatusModal] = useState(false);
  const [mainTaskId, setMainTaskId] = useState();
  const [triggerTaskDetail, setTriggerTaskDetail] = useState(false);
  const [mainVisibleCreateTask, setMainVisibleCreateTask] = useState(false);

  const [mainTaskIdAssign, setMainTaskIdAssign] = useState();
  const [visibleAssignee, setVisibleAssignee] = useState(false);
  const [mainResourceMemComId, setMainResourceMemComId] = useState(null);
  const [mainStatusTaskTitle, setMainStatusTaskTitle] = useState();
  const [mainStatusTaskObject, setMainStatusTaskObject] = useState(null);
  const [mainMapViewResourceData, setMainMapViewResourceData] = useState([]);
  const [listView, setListView] = useState([]);

  const [memberListView, setMemberListView] = useState([]); //เอาไปส่ง member หน้า list
  const [customerListView, setCustomerListView] = useState([]); //เอาไปส่ง customer หน้า list
  const [loadingListView, setLoadingListView] = useState(false);

  const [selectGroupBy, setSelectGroupBy] = useState('team');
  const [eventSelectGroup, setEventSelectGroup] = useState(false);
  const [eventDelete, setEventDelete] = useState(false);

  const [dateDefault, setDateDefault] = useState({
    dateList: [],
    dateDF: undefined,
  });
  const [selectDateDefault, setSelectDateDefault] = useState();
  const [checkedListTeam, setCheckedListTeam] = useState([]);
  const [typeGantt, setTypeGantt] = useState('team');
  const [statusDragDrop, setStatusDragDrop] = useState(false);
  //--------------------------Customer -------------------------------
  const [initialCustomerList, setInitialCustomerList] = useState([]);
  const [customerPage, setCustomerPage] = useState(0);
  const [customerSearch, setCustomerSearch] = useState();
  const [customerSearchPage, setCustomerSearchPage] = useState(0);
  const [initialCustomerListMaster, setInitialCustomerListMaster] = useState([]);
  const [initialCustomerLoading, setInitialCustomerLoading] = useState(false);
  const [isCustomerLoadMore, setIsCustomerLoadMore] = useState(true);
  const [isCustomerSearchLoadMore, setIsCustomerSearchLoadMore] = useState(true);
  const [customerPageSize, setCustomerPageSize] = useState(10);

  //---------------------------------- Customer -----------------------------------------------
  // console.log('initialCustomerList :', initialCustomerList)
  const [checkTaskNo, setCheckTaskNo] = useState(false);
  const [resultCheckTaskNo, setResultCheckTaskNo] = useState(false);

  useEffect(() => {
    const getData = async () => {
      let payload = {
        indexStart: customerPage * customerPageSize + 1,
        indexEnd: customerPage * customerPageSize + 10,
        customerName: customerSearch,
      };
      const result = await loadmoreCustomer(payload);
      const setAssetClassListData = _.map(_.get(result, 'customers'), (o) => {
        return o;
      });
      const customers = _.uniqBy(_.concat(initialCustomerList, setAssetClassListData), 'customerId');
      setInitialCustomerList(customers);
      setInitialCustomerListMaster(customers);
      setIsCustomerLoadMore(_.size(setAssetClassListData) > 0 ? true : false);
      setInitialCustomerLoading(false);
    };

    if (isCustomerLoadMore) {
      getData();
    }
  }, [customerPage]);

  useEffect(() => {
    const getData = async () => {
      if (!customerSearch) {
        setInitialCustomerList(initialCustomerListMaster);
        setInitialCustomerLoading(false);
      } else {
        let payload = {
          indexStart: customerSearchPage * customerPageSize + 1,
          indexEnd: customerSearchPage * customerPageSize + 10,
          customerName: customerSearch,
        };
        const result = await loadmoreCustomer(payload);
        const setAssetClassListData = _.map(_.get(result, 'customers'), (o) => {
          return o;
        });
        const Customer = _.uniqBy(_.concat(initialCustomerList, setAssetClassListData), 'customerId');
        setInitialCustomerList(Customer);
        setIsCustomerSearchLoadMore(_.size(setAssetClassListData) > 0 ? true : false);
        setInitialCustomerLoading(false);
      }
    };

    if (isCustomerSearchLoadMore) {
      getData();
    }
  }, [customerSearch, customerSearchPage]);

  const handleCustomerPopupScroll = (e) => {
    let target = e.target;
    if (
      !initialCustomerLoading &&
      (_.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight ||
        _.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight - 1)
    ) {
      setInitialCustomerLoading(true);
      if (customerSearch) {
        setCustomerSearchPage(customerSearchPage + 1);
      } else {
        setCustomerPage(customerPage + 1);
      }
    }
  };

  const handleCustomerChangeSearch = (value) => {
    setInitialCustomerLoading(true);
    setIsCustomerSearchLoadMore(true);
    setInitialCustomerList([]);
    setCustomerSearchPage(0);
    setCustomerSearch(value);
  };

  //---------------------------------- Customer -----------------------------------------------

  // useEffect(() => {
  //   const getApi = () => {
  //     const response = getDateDefault();
  //     setDateDefault(response);
  //   };

  //   getApi();
  // }, []);

  useEffect(() => {
    const getApi = async () => {
      const dataStageDefault = _.get(initialData, 'statusStage.stageList');
      const dataSlaDefault = _.get(initialData, 'sla.slaList');
      const dataAssignDefault = _.get(initialData, 'assignment.assignmentList');
      const mapStageData =
        checkedListStage &&
        checkedListStage.map((item) => {
          const Default =
            dataStageDefault &&
            dataStageDefault.filter((e) => {
              return e.statusGroupName === item;
            });
          return {
            statusGroupId: _.get(Default, '[0].statusGroupId'),
            statusGroupCode: _.get(Default, '[0].statusGroupCode'),
          };
        });
      const mapSlaData =
        checkedListSla &&
        checkedListSla.map((item) => {
          const Default =
            dataSlaDefault &&
            dataSlaDefault.filter((e) => {
              return e.name === item;
            });
          return {
            code: _.get(Default, '[0].code'),
          };
        });
      const mapAssignData =
        checkedListAssign &&
        checkedListAssign.map((item) => {
          const Default =
            dataAssignDefault &&
            dataAssignDefault.filter((e) => {
              return e.name === item;
            });
          return {
            code: _.get(Default, '[0].code'),
          };
        });
      const payload = {
        search: searchValue,
        searchBy: selectBy,
        orgId: checkedListTeam,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(startDate).format('YYYY-MM-DD'),
        filter: {
          stage: mapStageData,
          sla: mapSlaData,
          assignment: mapAssignData,
          mapoption: [],
          selectdatelimit: _.get(selectDateDefault, 'value') || undefined,
        },
        manuType: ['transportation'],
      };
      const response = await getTaskMapVeiw(payload);
      if (keyTabMonitor === '2') {
        await SummaryData(payload);
      }
      setTaskDataMapView(_.get(response.data, 'data'));
      setTaskDataMap(_.get(response.data, 'data'));
    };

    if (keyTabMonitor === '2') {
      getApi();
    }

    const interval = setInterval(
      async () => {
        if (keyTabMonitor === '2') {
          await getApi();
        }
      },
      _.get(initialData, 'companyConfig.interval') !== 0 ? _.get(initialData, 'companyConfig.interval') : 180000
    );
    return () => window.clearInterval(interval);
  }, [
    checkedListTeam,
    _.size(checkedListStage),
    _.size(checkedListSla),
    _.size(checkedListAssign),
    _.get(selectDateDefault, 'value'),
    keyTabMonitor,
    startDate,
    searchValue,
    selectBy,
    triggerTaskDetail,
  ]);

  useEffect(() => {
    const dataMapDefault = _.get(initialData, 'mapOption.mapOptionList');
    const mapOptionData =
      checkedListMap &&
      checkedListMap.map((item) => {
        const Default =
          dataMapDefault &&
          dataMapDefault.filter((e) => {
            return e.name === item;
          });
        return {
          code: _.get(Default, '[0].code'),
        };
      });
    const findIndexArea = mapOptionData.findIndex((item) => item.code === 'area');
    if (findIndexArea > -1) {
      setAreaChecked(true);
    } else {
      setAreaChecked(false);
    }
    const findIndexSatellite = mapOptionData.findIndex((item) => item.code === 'satellite');
    if (findIndexSatellite > -1) {
      setSatelliteChecked(true);
    } else {
      setSatelliteChecked(false);
    }
  }, [_.size(checkedListMap)]);

  //-------------------------------- Gantt -------------------------------------------------------

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (_.size(mainOrgIdStr) !== 0) {
          getApi();
          getTaskPool();
        }
      },
      180000
      // _.get(initialData, 'companyConfig.interval') !== 0 ? _.get(initialData, 'companyConfig.interval') : 180000
    );
    return () => window.clearInterval(interval);
  }, [
    mainOrgIdStr,
    mainCustomerId,
    _.size(checkedListStage),
    _.size(checkedListSla),
    keyTabMonitor,
    selectBy,
    searchValue,
    nowDate,
    triggerTaskDetail,
    taskPoolData,
    triggerDragDrop,
    resultCheckTaskNo,
  ]);

  useEffect(() => {
    if (selectGroupBy === 'team') {
      if (keyTabMonitor === '1') {
        setTimeout(() => {
          getApi();
        }, 500);
      }
    } else {
      if (keyTabMonitor === '1') {
        setTimeout(() => {
          getApi();
        }, 500);
      }
    }
  }, [keyTabMonitor, selectBy, nowDate, triggerTaskDetail, mainOrgIdStr, mainCustomerId, triggerDragDrop, resultCheckTaskNo]);

  useEffect(() => {
    setResourceId(null);
    setTrackingResource({
      resId: null,
      openSpeedTrack: false,
      dataSpeedTrack: null,
      dataTask: [],
      statusColor: null,
    });
  }, [mainOrgId, selectBy, searchValue, startDate, keyTabMonitor]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getApi();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [_.size(checkedListStage), _.size(checkedListSla)]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getApi();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setMainCustomerId(customerIdRaw);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [customerIdRaw]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setResultCheckTaskNo(checkTaskNo);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [checkTaskNo]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getApi();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [eventDelete]);

  const getApi = async () => {
    if (scheduleObjNew.current !== null) {
      const dataStageDefault = _.get(initialData, 'statusStage.stageList');
      const dataSlaDefault = _.get(initialData, 'sla.slaList');
      const mapStageData =
        checkedListStage &&
        checkedListStage.map((item) => {
          const Default =
            dataStageDefault &&
            dataStageDefault.filter((e) => {
              return e.statusGroupName === item;
            });
          return {
            statusGroupId: _.get(Default, '[0].statusGroupId'),
            statusGroupCode: _.get(Default, '[0].statusGroupCode'),
          };
        });
      const mapSlaData =
        checkedListSla &&
        checkedListSla.map((item) => {
          const Default =
            dataSlaDefault &&
            dataSlaDefault.filter((e) => {
              return e.name === item;
            });
          return {
            code: _.get(Default, '[0].code'),
          };
        });

      let startDate_new = '';
      let endDate_new = '';
      if (scheduleObjNew.current && scheduleObjNew.current.activeViewOptions.option === 'TimelineDay') {
        let currentDate = scheduleObjNew.current && scheduleObjNew.current.activeView.renderDates[0];
        startDate_new = moment(currentDate).format('YYYY-MM-DD');
        endDate_new = moment(currentDate).format('YYYY-MM-DD');
      } else if (scheduleObjNew.current && scheduleObjNew.current.activeViewOptions.option === 'TimelineWeek') {
        let currentDate = scheduleObjNew.current && scheduleObjNew.current.activeView.renderDates;
        startDate_new = moment(currentDate[0]).format('YYYY-MM-DD');
        endDate_new = moment(currentDate[currentDate.length - 1]).format('YYYY-MM-DD');
      } else if (scheduleObjNew.current && scheduleObjNew.current.activeViewOptions.option === 'TimelineMonth') {
        let currentDate = scheduleObjNew.current && scheduleObjNew.current.activeView.monthDates;
        startDate_new = moment(currentDate.start).format('YYYY-MM-DD');
        endDate_new = moment(currentDate.end).format('YYYY-MM-DD');
      }

      const payload = {
        search: searchValue,
        searchBy: selectBy,
        orgId: mainOrgIdStr,
        customerId:
          selectGroupBy === 'customer'
            ? mainCustomerId.map((item) => {
              return item.customerId;
            })
            : [],
        startDate: startDate_new,
        endDate: endDate_new,
        filter: {
          stage: mapStageData,
          sla: mapSlaData,
        },
        manuType: ['transportation'],
      };

      const payload2 = {
        search: searchValue,
        searchBy: selectBy,
        orgId: mainOrgIdStr,
        customerId:
          selectGroupBy === 'customer'
            ? mainCustomerId.map((item) => {
              return item.customerId;
            })
            : [],
        startDate: startDate_new,
        endDate: endDate_new,
        filter: {
          stage: mapStageData,
          sla: mapSlaData,
          assignment: [{ code: 'assigned' }],
        },
        manuType: ['transportation'],
      };
      const response = await getTaskGantt(payload);
      if (keyTabMonitor === '1') {
        SummaryData(payload2);
      }
      const mapTaskData =
        _.get(response, 'data.data') &&
        _.get(response, 'data.data').map((item, index) => {
          let data = new DataManager(scheduleObjNew.current && scheduleObjNew.current.getCurrentViewEvents()).executeLocal(
            new Query().where('TaskId', 'equal', item.taskId)
          );
          const filterData =
            _.size(data) > 1
              ? data &&
              data.filter((e) => {
                return e.ResourceId === parseInt(item.memComId);
              })
              : data;
          return {
            memComOldId: parseInt(item.memComId),
            orgIdOld: parseInt(item.orgId),
            Id: _.get(filterData, '[0].Id') ? _.get(filterData, '[0].Id') : uuidv4(),
            TaskId: item.taskId,
            TaskNo: item.taskNo,
            Subject: item.taskType.taskTypeName,
            CustomerName: item.customerName,
            AssigneeName: item.assigneeName,
            Color: item.status.statusGroupColor,
            OrgId: parseInt(item.orgId),
            ResourceId: parseInt(item.memComId),
            StartTime: moment.unix(item.startDate).format(dateTimeApi),
            EndTime: moment.unix(item.endDate).format(dateTimeApi),
            StartDate: item.startDateView,
            DueDate: item.endDateView,
            Status: item.status.statusGroupName,
            Reference: item.reference,
            ToAddress: item.deliveryAddress,
            Remark: item.remark,
            TaskName: item.taskName,
            PriorityName: item.priority.priorityName,
            ContactName: _.get(item, 'contact[0].name'),
            ContactPhone: _.get(item, 'contact[0].phone'),
            ResourceOrgId: item.memOrgId,
            ResourceOrgIdOld: item.memOrgId,
            CustomerId: item.customerId,
            TagTask: resultCheckTaskNo
              ? `${item.taskNo +
              ' ' +
              `<span style='width: 5px; height: 5px; background-color: #ffffff; border-radius: 50%; display: inline-flex; margin: 0 4px 2px 3px;'></span>` +
              item.taskName} `
              : item.taskName,
          };
        });

      const newTaskDate =
        mapTaskData &&
        mapTaskData.filter((item) => {
          return item.ResourceOrgId !== null;
        });
      const datasource = scheduleObjNew.current && scheduleObjNew.current.dataModule.dataManager.dataSource.json;
      if (_.size(newTaskDate) !== 0) {
        if (_.size(datasource) === 0) {
          scheduleObjNew.current && scheduleObjNew.current.addEvent(newTaskDate);
        } else if (_.size(datasource) !== 0) {
          const deleteResult = _.differenceBy(datasource, newTaskDate, 'Id');
          const addResult = _.differenceBy(newTaskDate, datasource, 'Id');
          if (_.size(deleteResult) !== 0) {
            const filterUpdate =
              newTaskDate &&
              newTaskDate.filter((item) => {
                return item.Id !== _.get(deleteResult, '[0].Id');
              });
            deleteResult.map((item) => {
              return scheduleObjNew.current.deleteEvent(item.Id);
            });
            scheduleObjNew.current.saveEvent(filterUpdate);
          } else {
            scheduleObjNew.current.saveEvent(newTaskDate);
          }

          if (_.size(addResult) !== 0) {
            const filterUpdate =
              newTaskDate &&
              newTaskDate.filter((item) => {
                return item.Id !== _.get(deleteResult, '[0].Id');
              });
            scheduleObjNew.current.addEvent(addResult);
            scheduleObjNew.current.saveEvent(filterUpdate);
          } else {
            scheduleObjNew.current.saveEvent(newTaskDate);
          }
        }
      } else {
        scheduleObjNew.current && scheduleObjNew.current.deleteEvent(scheduleObjNew.current.eventsData);
      }

      // dataTaskGantt = _.size(datasource) !== 0 ? datasource : []
      console.log('datasource ::1', datasource);
      dataTaskGantt = datasource;
    } else {
      const dataStageDefault = _.get(initialData, 'statusStage.stageList');
      const dataSlaDefault = _.get(initialData, 'sla.slaList');
      if (keyTabMonitor === '1' && dataStageDefault && dataSlaDefault) {
        const mapStageData =
          checkedListStage &&
          checkedListStage.map((item) => {
            const Default =
              dataStageDefault &&
              dataStageDefault.filter((e) => {
                return e.statusGroupName === item;
              });
            return {
              statusGroupId: _.get(Default, '[0].statusGroupId'),
              statusGroupCode: _.get(Default, '[0].statusGroupCode'),
            };
          });
        const mapSlaData =
          checkedListSla &&
          checkedListSla.map((item) => {
            const Default =
              dataSlaDefault &&
              dataSlaDefault.filter((e) => {
                return e.name === item;
              });
            return {
              code: _.get(Default, '[0].code'),
            };
          });
        const payload2 = {
          search: searchValue,
          searchBy: selectBy,
          orgId: mainOrgIdStr,
          customerId:
            selectGroupBy === 'customer'
              ? mainCustomerId.map((item) => {
                return item.customerId;
              })
              : [],
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(startDate).format('YYYY-MM-DD'),
          filter: {
            stage: mapStageData,
            sla: mapSlaData,
            assignment: [{ code: 'assigned' }],
          },
          manuType: ['transportation'],
        };
        await SummaryData(payload2);
      }
    }
  };

  useEffect(() => {
    if (dataOrg !== null && dataResource !== null) {
      updateData({
        dataTaskGantt,
        dataOrg,
        dataResource,
        taskPoolData,
        scheduleObjNew,
      });
    }
  }, [dataTaskGantt, dataOrg, _.size(dataResource), taskPoolData]);

  const updateData = (props) => {
    if (props.scheduleObjNew.current !== null) {
      if (selectGroupBy === 'team') {
        console.log('-----updateData-------');
        let newDataOrg = [];
        const mapOrgId =
          mainOrgId &&
          mainOrgId.map((item) => {
            return {
              OrgId: parseInt(item),
            };
          });
        const resultsAddGroup = mapOrgId.map((item) => {
          const filter = props.dataOrg.filter((e) => {
            return e.orgId === item.OrgId ? item : null;
          });
          return filter[0];
        });
        _.forEach(resultsAddGroup, (obj) => {
          const sumResourceByOrgId = _.sumBy(props.dataResource, (e) => (e.OrgId === obj.orgId ? 1 : 0));
          const sumTaskPoolByOrgId = _.sumBy(props.taskPoolData, (e) => (e.orgId === obj.orgId ? 1 : 0));
          let txt = '';
          if (sumResourceByOrgId > 2) {
            txt = intl.formatMessage({
              id: 'monitorGanttViewTxtMembers',
              defaultMessage: 'Members',
            });
          } else {
            txt = intl.formatMessage({
              id: 'monitorGanttViewTxtMember',
              defaultMessage: 'Member',
            });
          }

          newDataOrg.push({
            orgId: parseInt(obj.orgId),
            orgName: `<divUnassigned><span>${obj.orgName
              }</span> <br /> <span style='font-size: 12px; color: #1D3557;' id=${`count-taskpool${parseInt(
                obj.orgId
              )}`}>${sumResourceByOrgId}&nbsp;${txt}
          &nbsp;${`${intl.formatMessage({
                id: 'monitorTextUnassignee',
                defaultMessage: 'Unassigned',
              })} ${sumTaskPoolByOrgId} ${intl.formatMessage({ id: 'monitorTextTask', defaultMessage: 'Task' })}`}</span></div>`,
            orgSelected: obj.selected,
          });
        });

        let newDataOrgResource = [];
        _.forEach(dataResource, (item) => {
          const sumResourceByOrgId = _.sumBy(props.dataTaskGantt, (e) =>
            e.ResourceId === parseInt(item.memComId) && e.OrgId === parseInt(item.OrgId) ? 1 : 0
          );

          newDataOrgResource.push({
            fullname:
              `<div  style='cursor: pointer;' class='fullname text-dot' orgId='${parseInt(item.OrgId)}' memComId='${parseInt(
                item.memComId
              ) || 999999}'><img src=${item.pathImage} class="fullname-img gantt-avatar" orgId='${parseInt(
                item.OrgId
              )}' memComId='${parseInt(item.memComId) || 999999}' />` +
              `<p class='fullname-p text-p' orgId='${parseInt(item.OrgId)}' memComId='${parseInt(item.memComId) || 999999}'>${item.fullname
              }</p>` +
              `<span id=${`count-task${parseInt(item.OrgId)}-${parseInt(item.memComId)}`} class='fullname-c text-count' orgId='${parseInt(
                item.OrgId
              )}' memComId='${parseInt(item.memComId) || 999999}'>(${sumResourceByOrgId})</span></div>`,
            pathImage: item.pathImage,
            memId: item.memId,
            memComId: parseInt(item.memComId) || 999999,
            OrgId: item.OrgId,
            assigneeName: item.fullname,
            memOrgId: item.memOrgId,
          });
        });

        const groupTeam = scheduleObjNew.current.resourceCollection;

        if (_.size(groupTeam[1].dataSource) !== 0) {
          const resultsDeleteGroup = _.get(groupTeam, '[0].dataSource').filter(
            ({ orgId: OrgId1 }) => !newDataOrg.some(({ orgId: OrgId2 }) => OrgId1 === OrgId2)
          );
          const resultsDelete = _.get(groupTeam, '[1].dataSource').filter(
            ({ OrgId: OrgId1 }) => !newDataOrgResource.some(({ OrgId: OrgId2 }) => OrgId1 === OrgId2)
          );
          const resultsAddGroup = newDataOrg.filter(
            ({ orgId: OrgId1 }) => !_.get(groupTeam, '[0].dataSource').some(({ orgId: OrgId2 }) => OrgId1 === OrgId2)
          );
          const resultsAdd = newDataOrgResource.filter(
            ({ OrgId: OrgId1 }) => !_.get(groupTeam, '[1].dataSource').some(({ OrgId: OrgId2 }) => OrgId1 === OrgId2)
          );

          const resultsDeleteMemcom = _.get(groupTeam, '[1].dataSource').filter(
            ({ OrgId: OrgId1, memComId: memComId1 }) =>
              !newDataOrgResource.some(({ OrgId: OrgId2, memComId: memComId2 }) => OrgId1 === OrgId2 && memComId1 === memComId2)
          );
          const resultsAddMemcom = newDataOrgResource.filter(
            ({ OrgId: OrgId1, memComId: memComId1 }) =>
              !_.get(groupTeam, '[1].dataSource').some(
                ({ OrgId: OrgId2, memComId: memComId2 }) => OrgId1 === OrgId2 && memComId1 === memComId2
              )
          );

          if (_.size(resultsAdd) !== 0) {
            scheduleObjNew.current.addResource(resultsAddGroup, 'Groupteam');
            scheduleObjNew.current.addResource(resultsAdd, 'Resources');
          }
          if (_.size(resultsDelete) !== 0) {
            scheduleObjNew.current.removeResource(resultsDeleteGroup, 'Groupteam');
            scheduleObjNew.current.removeResource(resultsDelete, 'Resources');
          }
          if (searchValue !== '' && selectBy === 'assigneeName') {
            scheduleObjNew.current.removeResource(resultsDeleteMemcom, 'Resources');
            scheduleObjNew.current.addResource(resultsAddMemcom, 'Resources');
          } else {
            scheduleObjNew.current.removeResource(newDataOrgResource, 'Resources');
            scheduleObjNew.current.addResource(newDataOrgResource, 'Resources');
          }
        } else {
          scheduleObjNew.current.addResource(newDataOrgResource, 'Resources');
        }

        if (_.size(groupTeam[0].dataSource) === 0) {
          scheduleObjNew.current.addResource(newDataOrg, 'Groupteam');
        } else {
          scheduleObjNew.current.removeResource(newDataOrg, 'Groupteam');
          scheduleObjNew.current.addResource(newDataOrg, 'Groupteam');
        }
      }
    }
  };

  useEffect(() => {
    if (_.size(mainCustomerId) !== 0 && scheduleObjNew && _.size(orgIdRaw) !== 0) {
      mapMemOrgId(scheduleObjNew);
    }
  }, [selectGroupBy, mainCustomerId, dataTaskPool, _.size(dataResource), triggerDragDrop, _.size(dataTaskGantt)]);

  const mapMemOrgId = (props) => {
    if (selectGroupBy === 'customer' && _.size(mainCustomerId) !== 0) {
      // if (props.scheduleObjNew.current !== null) {

      setTimeout(() => {
        const customerData =
          mainCustomerId &&
          mainCustomerId.map((item) => {
            const sumTaskpool = dataTaskPool && dataTaskPool.filter((e) => item.customerId.includes(e.CustomerId));
            return {
              IdCustomer: item.customerId,
              customerName: `<div><span>${item.customerName}</span> <br /> 
            <span style='font-size: 12px; color: #1D3557;' id=${`count-taskpool${item.customerId}`}>${`${intl.formatMessage({
                id: 'monitorTextUnassignee',
                defaultMessage: 'Unassigned',
              })} ${_.size(sumTaskpool)} ${intl.formatMessage({ id: 'monitorTextTask', defaultMessage: 'Task' })}`}</span></div>`,
            };
          });

        const findTaskCustomer =
          mainCustomerId &&
          mainCustomerId.map((item) => {
            const returnData = dataTaskGantt && dataTaskGantt.filter((e) => item.customerId.includes(e.CustomerId.toString()));
            const mapDataResource =
              returnData &&
              returnData.map((el) => {
                const returnResource =
                  dataResource && dataResource.filter((ele) => el.ResourceOrgId && el.ResourceOrgId.includes(ele.memOrgId.toString()));
                const sumdata =
                  returnData &&
                  returnData.filter((su) => {
                    return su.ResourceOrgId === el.ResourceOrgId;
                  });

                return {
                  GroupCustomerId: item.customerId,
                  ResourceName:
                    `<div  style='cursor: pointer;' class='fullname text-dot' orgId='${_.get(
                      returnResource,
                      '[0].OrgId'
                    )}' memOrgId='${_.get(returnResource, '[0].memOrgId') || 999999}' memComId='${_.get(returnResource, '[0].memComId')}'>
                  <img src=${_.get(returnResource, '[0].pathImage')} class="fullname-img gantt-avatar" orgId='${_.get(
                      returnResource,
                      '[0].OrgId'
                    )}' memOrgId='${_.get(returnResource, '[0].memOrgId') || 999999}' memComId='${_.get(
                      returnResource,
                      '[0].memComId'
                    )}'/>` +
                    `<p class='fullname-p text-p' style="width:135px;" orgId='${_.get(returnResource, '[0].OrgId')}' memOrgId='${_.get(
                      returnResource,
                      '[0].memOrgId'
                    ) || 999999} memComId='${_.get(returnResource, '[0].memComId')}'>${_.get(returnResource, '[0].fullname')}<br/>${_.get(
                      returnResource,
                      '[0].orgName'
                    )}</p>` +
                    `<span id=${`count-task${_.get(returnResource, '[0].OrgId')}-${_.get(
                      returnResource,
                      '[0].memOrgId'
                    )}`} class='fullname-c text-count' orgId='${_.get(returnResource, '[0].OrgId')}' memOrgId='${_.get(
                      returnResource,
                      '[0].memOrgId'
                    ) || 999999}' memComId='${_.get(returnResource, '[0].memComId')}'>(${_.size(sumdata)})</span>
              </div>`,
                  resourceOrgId: _.get(returnResource, '[0].memOrgId') && _.get(returnResource, '[0].memOrgId').toString(),
                  OrgId: _.get(returnResource, '[0].OrgId'),
                  memComId: _.get(returnResource, '[0].memComId'),
                };
              });
            const uniqData = _.uniqBy(mapDataResource, 'resourceOrgId');
            return uniqData;
          });

        let memOrgData = [];
        _.map(findTaskCustomer && findTaskCustomer, (el) => {
          memOrgData.push(...el);
        });
        const filterData =
          memOrgData &&
          memOrgData.filter((item) => {
            return item.resourceOrgId !== undefined;
          });

        const groupTeam = scheduleObjNew.current.resourceCollection;

        if (_.size(groupTeam[1].dataSource) !== 0) {
          const resultsDeleteGroup = _.get(groupTeam, '[0].dataSource').filter(
            ({ IdCustomer: OrgId1 }) => !customerData.some(({ IdCustomer: OrgId2 }) => OrgId1 === OrgId2)
          );
          const resultsDelete = _.get(groupTeam, '[1].dataSource').filter(
            ({ GroupCustomerId: OrgId1 }) => !filterData.some(({ GroupCustomerId: OrgId2 }) => OrgId1 === OrgId2)
          );
          const resultsAddGroup = customerData.filter(
            ({ IdCustomer: OrgId1 }) => !_.get(groupTeam, '[0].dataSource').some(({ IdCustomer: OrgId2 }) => OrgId1 === OrgId2)
          );
          const resultsAdd = filterData.filter(
            ({ GroupCustomerId: OrgId1 }) => !_.get(groupTeam, '[1].dataSource').some(({ GroupCustomerId: OrgId2 }) => OrgId1 === OrgId2)
          );

          const resultsDeleteMemcom = _.get(groupTeam, '[1].dataSource').filter(
            ({ GroupCustomerId: OrgId1, resourceOrgId: memComId1 }) =>
              !filterData.some(({ GroupCustomerId: OrgId2, resourceOrgId: memComId2 }) => OrgId1 === OrgId2 && memComId1 === memComId2)
          );
          const resultsAddMemcom = filterData.filter(
            ({ GroupCustomerId: OrgId1, resourceOrgId: memComId1 }) =>
              !_.get(groupTeam, '[1].dataSource').some(
                ({ GroupCustomerId: OrgId2, resourceOrgId: memComId2 }) => OrgId1 === OrgId2 && memComId1 === memComId2
              )
          );

          if (_.size(resultsAdd) !== 0) {
            scheduleObjNew.current.addResource(resultsAddGroup, 'GroupCustomers');
            scheduleObjNew.current.addResource(resultsAdd, 'MemOrg');
          }
          if (_.size(resultsDeleteMemcom) !== 0) {
            scheduleObjNew.current.removeResource(resultsDeleteMemcom, 'MemOrg');
          }
          if (_.size(resultsDelete) !== 0) {
            scheduleObjNew.current.removeResource(resultsDeleteGroup, 'GroupCustomers');
            scheduleObjNew.current.removeResource(resultsDelete, 'MemOrg');
          }
          if (searchValue !== '' && selectBy === 'assigneeName') {
            scheduleObjNew.current.removeResource(resultsDeleteMemcom, 'MemOrg');
            scheduleObjNew.current.addResource(resultsAddMemcom, 'MemOrg');
          } else {
            scheduleObjNew.current.removeResource(filterData, 'MemOrg');
            scheduleObjNew.current.addResource(filterData, 'MemOrg');
          }
        } else {
          // scheduleObjNew.current.addResource(newDataOrgResource, 'Resources');
          scheduleObjNew.current.addResource(filterData, 'MemOrg');
        }

        if (_.size(groupTeam[0].dataSource) === 0) {
          scheduleObjNew.current.addResource(customerData, 'GroupCustomers');
        } else {
          scheduleObjNew.current.removeResource(customerData, 'GroupCustomers');
          scheduleObjNew.current.addResource(customerData, 'GroupCustomers');
        }
      }, 1000);

      // }
    }
  };

  useEffect(() => {
    if (_.size(mainOrgIdStr) >= 0 && keyTabMonitor === '3') {
      getListView();
    }
  }, [paginationSize, paginationPage, mainOrgIdStr, startDate, dueDate, searchValueList, selectByList]);

  useEffect(() => {
    if (_.size(mainOrgIdStr) >= 0 && keyTabMonitor === '3') {
      getListView();
    }
  }, [
    mainOrgIdStr,
    _.size(checkedListStage),
    _.size(checkedListSla),
    _.size(checkedListAssign),
    _.size(checkedListTeam),
    keyTabMonitor,
    triggerTaskDetail,
    customerListView,
    memberListView,
  ]);

  const getListView = async (aa) => {
    setLoadingListView(true);
    const dataStageDefault = _.get(initialData, 'statusStage.stageList');
    const dataSlaDefault = _.get(initialData, 'sla.slaList');
    const dataAssignDefault = _.get(initialData, 'assignment.assignmentList');
    const mapStageData =
      checkedListStage &&
      checkedListStage.map((item) => {
        const Default =
          dataStageDefault &&
          dataStageDefault.filter((e) => {
            return e.statusGroupName === item;
          });
        return {
          statusGroupId: _.get(Default, '[0].statusGroupId'),
          statusGroupCode: _.get(Default, '[0].statusGroupCode'),
        };
      });
    const mapSlaData =
      checkedListSla &&
      checkedListSla.map((item) => {
        const Default =
          dataSlaDefault &&
          dataSlaDefault.filter((e) => {
            return e.name === item;
          });
        return {
          code: _.get(Default, '[0].code'),
        };
      });
    const mapAssignData =
      checkedListAssign &&
      checkedListAssign.map((item) => {
        const Default =
          dataAssignDefault &&
          dataAssignDefault.filter((e) => {
            return e.name === item;
          });
        return {
          code: _.get(Default, '[0].code'),
        };
      });
    const payload = {
      search: searchValueList,
      searchBy: selectByList,
      orgId: mainOrgIdStr,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(dueDate).format('YYYY-MM-DD'),
      filter: {
        stage: mapStageData,
        sla: mapSlaData,
        assignment: mapAssignData,
        mapoption: [],
      },
      manuType: ['transportation'],
      customer: customerListView,
      member: memberListView,
      paging: paginationPage,
      rowsPerPages: paginationSize,
      ordertype: orderSort,
      orderby: fieldSort,
    };

    const response = await getListViewData(payload);
    if (response.status === 200) {
      if (_.size(dataOrg) !== 0 && keyTabMonitor === '3') {
        await SummaryData(payload);
      }
      setListView(_.take(_.get(response.data, 'data'), paginationSize));
      setTotal(_.get(response.data, 'total'));
      setLoadingListView(false);
    }
  };

  useEffect(() => {
    console.log('couttttttt', dataTaskGantt, dataResource);
    if (_.size(dataTaskGantt) !== 0 && _.size(dataResource) !== 0) {
      _.forEach(dataResource, (item) => {
        const sumResourceByOrgId = _.sumBy(dataTaskGantt, (e) =>
          e.ResourceId === parseInt(item.memComId) && e.OrgId === parseInt(item.OrgId) ? 1 : 0
        );
        const byId = document.getElementById(`count-task${item.OrgId}-${item.memComId}`);
        if (byId) {
          byId.innerHTML = `(${sumResourceByOrgId})`;
        }
      });
    } else {
      _.forEach(dataResource, (item) => {
        // const sumResourceByOrgId = _.sumBy(dataTaskGantt, (e) => e.ResourceId === parseInt(item.memComId) ? 1 : 0);
        const byId = document.getElementById(`count-task${item.OrgId}-${item.memComId}`);
        if (byId) {
          byId.innerHTML = `(0)`;
        }
      });
    }
  }, [_.size(dataTaskGantt), _.size(dataResource)]);

  useEffect(() => {
    if (_.size(dataOrg) !== 0 && _.size(dataResource) !== 0) {
      const mapOrgId =
        mainOrgId &&
        mainOrgId.map((item) => {
          return {
            OrgId: parseInt(item),
          };
        });
      const resultsAddGroup = mapOrgId.map((item) => {
        const filter = dataOrg.filter((e) => {
          return e.orgId === item.OrgId ? item : null;
        });
        return filter[0];
      });
      _.forEach(resultsAddGroup, (obj) => {
        const sumTaskPoolByOrgId = _.sumBy(taskPoolData, (e) => (e.orgId === obj.orgId ? 1 : 0));
        const sumResourceByOrgId = _.sumBy(dataResource, (e) => (e.OrgId === obj.orgId ? 1 : 0));
        const byId = document.getElementById(`count-taskpool${obj.orgId}`);
        if (byId) {
          let txt = '';
          if (sumResourceByOrgId > 2) {
            txt = intl.formatMessage({
              id: 'monitorGanttViewTxtMembers',
              defaultMessage: 'Members',
            });
          } else {
            txt = intl.formatMessage({
              id: 'monitorGanttViewTxtMember',
              defaultMessage: 'Member',
            });
          }
          byId.innerHTML = `${sumResourceByOrgId}&nbsp;${txt}&nbsp;${`${intl.formatMessage({
            id: 'monitorTextUnassignee',
            defaultMessage: 'Unassigned',
          })} ${sumTaskPoolByOrgId} ${intl.formatMessage({ id: 'monitorTextTask', defaultMessage: 'Task' })}`}`;
        }
      });
    }
  }, [_.size(dataOrg), _.size(taskPoolData), _.size(dataResource)]);

  const SummaryData = async (body) => {
    console.log('------SummaryData-------');
    const type = keyTabMonitor === '1' ? 'ganttview' : keyTabMonitor === '2' ? 'mapview' : keyTabMonitor === '3' ? 'listview' : 'listview';
    console.log('selectGroupBy',);
    if (_.size(checkedListTeam) > 0 || eventSelectGroup === true) {
      const response = await getSummary(body, type);
      if (_.get(response, 'status') === 200) {
        setSummaryData(_.get(response, 'data.data'));
      }
    }

  };

  useEffect(() => {
    // if (_.size(mainOrgId) > 0 || _.size(mainCustomerId) > 0) {
    if (_.size(checkedListTeam) > 0 || eventSelectGroup === true) {
      getTaskPool();
    }


    // }
  }, [mainOrgId, triggerTaskPool, triggerTaskDetail, mainCustomerId, selectGroupBy, eventDelete]);

  const getTaskPool = async () => {
    let newTaskPoolData = [];
    const payload = {
      menuType: ['transportation'],
      orgId: mainOrgId,
      customerId:
        selectGroupBy === 'customer'
          ? mainCustomerId.map((item) => {
            return item.customerId;
          })
          : [],
    };
    if (selectGroupBy === 'customer' && _.size(mainCustomerId) === 0) {
      setTaskPoolData([]);
      dataTaskPool = null;
      setTotalTaskPool('0');
    } else {
      const res = await getTaskPoolDataNew(payload);
      // const response = await getTaskPoolData(mainOrgId, 'transportation');
      if (_.get(res, 'status') === 200) {
        setTotalTaskPool(res.data.total);
        console.log('resPool', res.data);
        _.map(res.data.data, (item, index) => {
          newTaskPoolData.push({
            Index: index,
            Id: item.taskId ? item.taskId : '',
            TaskNo: item.task_no ? item.task_no : '',
            OrgName: item.orgName ? item.orgName : '',
            CustomerName: item.customerName ? item.customerName : '',
            Color: item.statusColor ? item.statusColor : '',
            TaskTypeName: item.taskTypeName ? item.taskTypeName : '',
            TaskName: item.taskName ? item.taskName : '',
            StartDateView: item.startdate ? item.startdate : '',
            EndDateView: item.enddate ? item.enddate : '',
            StartTimeView: item.startTime ? item.startTime : '',
            EndTimeView: item.endTime ? item.endTime : '',
            Planstart: item.planstart ? item.planstart : '',
            Planfinish: item.planfinish ? item.planfinish : '',
            StatusName: item.statusName ? item.statusName : '',
            Address: item.address ? item.address : '',
            FromAddress: item.from_address ? item.from_address : '',
            ToAddress: item.to_address ? item.to_address : '',
            Planstart1: item.planstart1 ? item.planstart1 : '',
            Planfinish1: item.planfinish1 ? item.planfinish1 : '',
            orgId: item.orgId ? parseInt(item.orgId) : '',
            ReferenceDefault: item.reference ? item.reference : '',
            CustomerId: item.customerId ? item.customerId : '',
          });
        });
        setTaskPoolData(newTaskPoolData);
        dataTaskPool = newTaskPoolData;
      }
    }
  };

  useEffect(() => {
    const getApi = async () => {
      const response = await getinitialTeamApi('menuType=transportation');
      const dataInitial = response.data.team.map((i) => {
        return {
          orgId: parseInt(i.orgId),
          orgName: i.name,
          selected: i.selected,
          checked: true,
        };
      });
      setOrgData(dataInitial || []);
      dataOrg = dataInitial;
    };
    getApi();
  }, []);

  useEffect(() => {
    if (keyTabMonitor === '1') {
      if (statusSummary) {
        setSpanRight(24);
        setSpanLeft(0);
      } else {
        setSpanRight(18);
        setSpanLeft(6);
        setShow(true);
      }
    } else if (keyTabMonitor === '2') {
      if (statusSummary) {
        setSpanRight(24);
        setSpanLeft(0);
      } else {
        setSpanRight(18);
        setSpanLeft(6);
        setShow(true);
      }
    } else if (keyTabMonitor === '3') {
      if (statusSummary) {
        setSpanRight(24);
        setSpanLeft(0);
      } else {
        setSpanRight(18);
        setSpanLeft(6);
        setShow(true);
      }
    }
  }, [statusSummary, keyTabMonitor]);

  useEffect(() => {
    const getDataInitial = async () => {
      const response = await getInitializeMonitorData('transportation');
      if (response.status === 200) {
        setInitialData(response.data);

        const filterDate =
          _.get(response.data.selectDate, 'selectDateList') &&
          _.get(response.data.selectDate, 'selectDateList').filter((item) => item.select !== false);
        setDateDefault({
          dateList: _.get(response.data.selectDate, 'selectDateList') || [],
          dateDF: _.get(filterDate, `[0]`) || undefined,
        });
        setSelectDateDefault(_.get(filterDate, `[0]`) || undefined);
      }
    };
    getDataInitial();
  }, []);

  useEffect(() => {
    const searchResource = async () => {
      const body = {
        orgId: mainOrgIdStr,
        search: searchValue,
        searchBy: selectBy,
        shiftDate: moment(startDate).format('YYYY-MM-DD'),
      };
      const type = keyTabMonitor === '1' ? 'ganttview' : keyTabMonitor === '2' ? 'mapview' : 'listview';
      const response = await getSearchResource(body, type);
      if (response.status === 200) {
        console.log("transport", (_.get(response.data, 'data')).reverse())
        setResourceData((_.get(response.data, 'data')).sort(compareByAgeDesc));
        dataResource = (_.get(response.data, 'data')).sort(compareByAgeDesc);
      }
    };
    if ((selectBy === 'all' || selectBy === 'assigneeName') && _.size(mainOrgIdStr) !== 0) {
      searchResource();
    }
  }, [mainOrgIdStr, searchValue, selectBy, keyTabMonitor, triggerDragDrop]);

  const compareByAgeDesc = (a, b) => {
    return b.age - a.age;
  }


  //-------------------------------defaul taskdetail map-----------------------------//
  useEffect(() => {
    const searchResource = async () => {
      const body = {
        orgId: mainOrgIdStr,
        search: '',
        searchBy: 'assigneeName',
      };
      const type = 'mapview';
      const response = await getSearchResource(body, type);
      if (response.status === 200) {
        setMainMapViewResourceData(_.get(response.data, 'data'));
      }
    };
    if (keyTabMonitor === '2') {
      searchResource();
    }
  }, [mainOrgIdStr, keyTabMonitor]);
  //-------------------------------defaul taskdetail map-----------------------------//

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const keyValue = [];
      orgIdRaw.forEach((element) => {
        keyValue.push(element);
      });

      console.log('orgIdRaw', orgIdRaw);

      if (_.size(orgIdRaw) === 0) {
        setResourceGanttData([]);
        setResourceData([]);
        scheduleObjNew.current && scheduleObjNew.current.deleteEvent(scheduleObjNew.current.eventsData);
      }
      const strArr = keyValue.map((e) => {
        return e.toString();
      });
      setMainOrgId(keyValue);
      setMainOrgIdStr(strArr);
      dataTaskGantt = null;
      dataResource = null;
      dataTaskPool = null;
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [orgIdRaw]);

  //-------------------------------defaul taskdetail map-----------------------------//

  // useEffect(() => {

  //   console.log('ExportTempData', exportTemp);
  //   if (_.get(exportTemp, 'exportSuccess') === true) {
  //     console.log('ExportTempData1', _.get(exportTemp, 'url'));

  //     setLoadingListView(false);
  //   }
  // }, [exportTemp]);

  const handleChangeOption = (key) => {
    // const keyValue = [];
    // key.forEach((element) => {
    //   keyValue.push(element);
    // });
    // if (_.size(key) === 0) {
    //   setResourceGanttData([])
    //   setResourceData([])
    //   scheduleObjNew.current && scheduleObjNew.current.deleteEvent(scheduleObjNew.current.eventsData);
    // }
    // const strArr = keyValue.map((e) => { return e.toString() });
    // setMainOrgId(keyValue);
    // setMainOrgIdStr(strArr)
    // dataTaskGantt = null
    // dataResource = null
    // dataTaskPool = null
  };

  const handleSearch = debounce((value) => {
    setSearchValue(value ? value : '');
  });

  const handleSearchList = debounce((value) => {
    setSearchValueList(value ? value : '');
    setPaginationPage(1);
  });

  const handleSelect = (value) => {
    setSelectBy(value);
  };

  const handleSelectList = (value) => {
    setSelectByList(value);
  };

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  };

  const handleChangeDueDate = (date) => {
    setDueDate(date);
  };

  const handleClickMember = (e) => {
    setSelectResourceData(e);
    setResourceId(e.memComId);
    setResourceIdChecked(true);
  };

  const onClickTrack = (memComId) => {
    setTrackingResource({
      resId: memComId,
      openSpeedTrack: true,
      dataSpeedTrack: null,
      dataTask: [],
      statusColor: null,
    });
  };

  const handleOpenModal = (dataAPI) => {
    setDataMemberInfor(dataAPI);
    setVisibleMemberInfor(true);
  };

  const handleCloseModal = () => {
    setVisibleMemberInfor(false);
    setTimeout(() => {
      setDataMemberInfor(null);
    }, 1000);
  };

  const handleExport = async () => {
    if (mainOrgId) {
      var mainOrgIdArrStringNew = mainOrgId.map(function (e) {
        return e.toString();
      });
      try {
        const dataStageDefault = _.get(initialData, 'statusStage.stageList');
        const dataSlaDefault = _.get(initialData, 'sla.slaList');
        const dataAssignDefault = _.get(initialData, 'assignment.assignmentList');
        const mapStageData =
          checkedListStage &&
          checkedListStage.map((item) => {
            const Default =
              dataStageDefault &&
              dataStageDefault.filter((e) => {
                return e.statusGroupName === item;
              });
            return {
              statusGroupId: _.get(Default, '[0].statusGroupId'),
              statusGroupCode: _.get(Default, '[0].statusGroupCode'),
            };
          });
        const mapSlaData =
          checkedListSla &&
          checkedListSla.map((item) => {
            const Default =
              dataSlaDefault &&
              dataSlaDefault.filter((e) => {
                return e.name === item;
              });
            return {
              code: _.get(Default, '[0].code'),
            };
          });
        const mapAssignData =
          checkedListAssign &&
          checkedListAssign.map((item) => {
            const Default =
              dataAssignDefault &&
              dataAssignDefault.filter((e) => {
                return e.name === item;
              });
            return {
              code: _.get(Default, '[0].code'),
            };
          });
        const body = {
          search: searchValueList,
          searchBy: selectByList,
          orgId: mainOrgIdStr,
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(dueDate).format('YYYY-MM-DD'),
          filter: {
            stage: mapStageData,
            sla: mapSlaData,
            assignment: mapAssignData,
            mapoption: [],
          },
          manuType: ['transportation'],
          customer: customerListView,
          member: memberListView,
          paging: paginationPage,
          rowsPerPages: total,
          ordertype: orderSort,
          orderby: fieldSort,
          viewFormat: [
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableTaskNo',
                defaultMessage: 'Task No',
              }),
              sequence: 1,
              colCode: 'taskNo',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableTaskType',
                defaultMessage: 'Task Type',
              }),
              sequence: 2,
              colCode: 'taskType',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableCustomer',
                defaultMessage: 'Customer',
              }),
              sequence: 3,
              colCode: 'customerName',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableContactName',
                defaultMessage: 'Contact Name',
              }),
              sequence: 4,
              colCode: 'contactName',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableContactPhone',
                defaultMessage: 'Contact Phone',
              }),
              sequence: 5,
              colCode: 'contactPhone',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableFromLocation',
                defaultMessage: 'From Location',
              }),
              sequence: 6,
              colCode: 'fromAddress',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableToLocation',
                defaultMessage: 'To Location',
              }),
              sequence: 7,
              colCode: 'deliveryAddress',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableStatus',
                defaultMessage: 'Status',
              }),
              sequence: 8,
              colCode: 'status',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableStartDate',
                defaultMessage: 'Start Date',
              }),
              sequence: 9,
              colCode: 'startDateView',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableDueDate',
                defaultMessage: 'Due Date',
              }),
              sequence: 10,
              colCode: 'endDateView',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableActualStartDate',
                defaultMessage: 'Actual Start Date',
              }),
              sequence: 11,
              colCode: 'actualStartDate',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableActualCompletedDate',
                defaultMessage: 'Actual Completed Date',
              }),
              sequence: 12,
              colCode: 'actualCompletedDate',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableRemark',
                defaultMessage: 'Remark',
              }),
              sequence: 13,
              colCode: 'remark',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableReference',
                defaultMessage: 'Reference',
              }),
              sequence: 14,
              colCode: 'reference',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableReference1',
                defaultMessage: 'Reference1',
              }),
              sequence: 15,
              colCode: 'reference1',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableReference2',
                defaultMessage: 'Reference2',
              }),
              sequence: 16,
              colCode: 'reference2',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableReference3',
                defaultMessage: 'Reference3',
              }),
              sequence: 17,
              colCode: 'reference3',
            },

            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableCreatedDate',
                defaultMessage: 'Created Date',
              }),
              sequence: 18,
              colCode: 'createdDate',
            },
            {
              colName: intl.formatMessage({
                id: 'reportTaskReportViewTableAssigneeName',
                defaultMessage: 'Assignee Name',
              }),
              sequence: 19,
              colCode: 'assignee',
            },
          ],
        };

        setLoadingListView(true);
        const responseId = await exportListViewData(body);
        const exId = _.get(responseId.data.data, 'export_task_list_id');
        let intervalId = setInterval(async () => {
          const response = await getURLexportTask(exId);
          setExportTemp(exportTemp + 1);
          count = count + 1;
          console.log('countExport', count);
          if (_.get(response.data.data, 'exportSuccess') === true) {
            console.log('TestIntervval');

            window.open(_.get(response.data.data, 'url'));
            successNotification(response.data.status.message);
            clearInterval(intervalId);
            count = 0;
            setLoadingListView(false);
          }
          if (count === 20) {
            errorNotification(response.data.status.message);
            clearInterval(intervalId);
            count = 0;
            setLoadingListView(false);
          }
        }, 3000);

      } catch (error) {
        errorNotification(error.response.status.message);
      }
    }
  };

  const handleOnClickTaskListView = (record) => {
    setVisibleTaskDetailModal(true);
    setMainTaskId(record.taskId);
  };

  const handleSelectGroupBy = (key) => {
    if (key !== selectGroupBy) {
      setSelectGroupBy(key);
      setOrgIdRaw([]);
      setMainOrgId([]);
      setMainCustomerId([]);
      setCustomerIdRaw([]);
    }
  };

  const handleChangeTeam = (value, e) => {
    if (selectGroupBy === 'team') {
      setOrgIdRaw(value);
    } else {
      const mapData =
        e &&
        e.map((item) => {
          return {
            customerId: item.props.value,
            customerName: item.props.children,
          };
        });
      setCustomerIdRaw(mapData);
      // setInitialCustomerList(initialCustomerListMaster)
      setCustomerSearch('');
    }
  };

  useEffect(() => {
    if (selectGroupBy === 'customer' && statusDragDrop === true) {
      dataTaskGantt = null;
      dataResource = null;
      setStatusDragDrop(false);
    }
  }, [statusDragDrop]);

  return (
    <MonitorContext.Provider
      value={{
        state: {
          statusSummary,
          keyTabMonitor,
          show,
          spanRight,
          spanLeft,
          mainOrgId,
          mainOrgIdStr,
          initialData,
          checkedListStage,
          checkedListSla,
          checkedListAssign,
          checkedListMap,
          resourceData,
          orgData,
          taskDataGanttView,
          taskDataMapView,
          taskDataMap,
          searchValue,
          selectBy,
          startDate,
          dueDate,
          resourceId,
          selectResourceData,
          selectTaskData,
          taskPoolData,
          totalTaskPool,
          summaryData,
          inputValue,
          trackingResource,
          resourceIdChecked,
          satelliteChecked,
          areaChecked,
          listView,
          checkedListStageCount,
          checkedListAssignCount,
          checkedListSlaCount,
          total,
          orgIdRaw,
          selectByList,
          searchValueList,
          loadingListView,
          paginationPage,
          LangCode,
          selectGroupBy,
          checkedListTeam,
          dateDefault,
          selectDateDefault,
          resultCheckTaskNo,
          eventSelectGroup,
        },
        setState: {
          setSpanRight,
          setSpanLeft,
          setShow,
          setStatusSummary,
          setKeyTabMonitor,
          setCheckedListStage,
          setCheckedListAssign,
          setCheckedListSla,
          setCheckedListMap,
          setSearchValue,
          setSelectBy,
          setStartDate,
          setDueDate,
          setOrgData,
          setResourceId,
          setSelectResourceData,
          setSelectTaskData,
          setTriggerTaskPool,
          setInputValue,
          setTrackingResource,
          setMainVisibleCreateTask,
          setMainTaskId,
          setVisibleTaskDetailModal,
          setResourceIdChecked,
          setSatelliteChecked,
          setAreaChecked,
          setMainTaskIdAssign,
          setVisibleAssignee,
          setMainResourceMemComId,
          setMainStatusTaskTitle,
          setMainStatusTaskObject,
          setVisibleTaskStatusModal,
          setTaskDataMap,
          setMemberListView,
          setCustomerListView,
          setCheckedListStageCount,
          setCheckedListSlaCount,
          setCheckedListAssignCount,
          setFieldSort,
          setOrderSort,
          setPaginationPage,
          setPaginationSize,
          setTotal,
          setOrgIdRaw,
          setSelectByList,
          setSearchValueList,
          setCheckedListTeam,
          setDateDefault,
          setSelectDateDefault,
          setMainOrgId,
          setOrgIdRaw,
          setEventSelectGroup
        },
        fnc: {
          handleChangeOption,
          handleSearch,
          handleSelect,
          handleSelectList,
          handleChangeStartDate,
          handleChangeDueDate,
          handleClickMember,
          onClickTrack,
          handleOnClickTaskListView,
          handleExport,
          handleSearchList,
          handleChangeTeam,
        },
      }}
    >
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div>
            <Row gutter={[16, 16]}>
              <Col span={spanLeft}>
                <LeftCard
                  tabChecked={keyTabMonitor}
                  mainOrgId={mainOrgId}
                  scheduleObjNew={scheduleObjNew}
                  LangCode={LangCode}
                  orgData={orgData}
                />
              </Col>
              <Col span={spanRight}>
                <RightCard
                  valueState={{
                    keyTabMonitor,
                    scheduleObjNew,
                    initialData,
                    mainOrgId,
                    searchValue,
                    selectBy,
                    orgData,
                    startDate,
                    resourceData,
                    taskDataGanttView,
                    orgGanttData,
                    taskPoolData,
                    resourceGanttData,
                    inputValue,
                    orgIdRaw,
                    keyTabMonitor,
                    LangCode,
                    selectGroupBy,
                    initialCustomerLoading,
                    initialCustomerList,
                    mainCustomerId,
                    dataResource,
                    customerIdRaw,
                    checkTaskNo,

                  }}
                  valueSetState={{
                    setOrgData,
                    setOrgGanttData,
                    setResourceGanttData,
                    setInputValue,
                    setSearchValue,
                    setNowDate,
                    setVisibleTaskDetailModal,
                    setMainTaskId,
                    setStartDate,
                    setOrgIdRaw,
                    setTriggerDragDrop,
                    setSelectGroupBy,
                    setMainCustomerId,
                    setTypeGantt,
                    setStatusDragDrop,
                    setCheckTaskNo,
                    setDueDate,

                  }}
                  valueFnc={{
                    handleChangeOption,
                    handleSearch,
                    handleSelect,
                    handleOpenModal,
                    handleSelectGroupBy,
                    handleCustomerPopupScroll,
                    handleCustomerChangeSearch,
                    handleChangeTeam,
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
      </PageSettings.Consumer>

      <TaskDetail
        visible={visibleTaskDetailModal}
        setVisible={setVisibleTaskDetailModal}
        visibleResource={visibleTaskStatusModal}
        setVisibleResource={setVisibleTaskStatusModal}
        mainTaskId={mainTaskId}
        teamData={orgData}
        scheduleObjNew={scheduleObjNew}
        mainMapViewResourceData={mainMapViewResourceData}
        mainResourceMemComId={mainResourceMemComId}
        mainDate={moment(startDate).format(dateApi)}
        mainStatusTaskTitle={mainStatusTaskTitle}
        mainStatusTaskObject={mainStatusTaskObject}
        mainDateTitleModal={moment(startDate).format(dateTitlelModal)}
        setTriggerTaskDetail={setTriggerTaskDetail}
        triggerTaskDetail={triggerTaskDetail}
        checkedListTeam={checkedListTeam}
        keyTabMonitor={keyTabMonitor}
        dateCount={_.get(selectDateDefault, 'value')}
        setEventDelete={setEventDelete}
        setTriggerTaskPool={setTriggerTaskPool}
      />
      <AssignResource
        setTriggerTaskDetail={setTriggerTaskDetail}
        triggerTaskDetail={triggerTaskDetail}
        scheduleObjNew={scheduleObjNew}
        setMainTaskIdAssign={setMainTaskIdAssign}
        setVisibleAssignee={setVisibleAssignee}
        mainTaskIdAssign={mainTaskIdAssign}
        visibleAssignee={visibleAssignee}
      />

      <CreateTask visible={mainVisibleCreateTask} setVisible={setMainVisibleCreateTask} setTriggerTaskDetail={setTriggerTaskDetail} />

      <MemberInformation
        dataAPI={dataMemberInfor}
        title={<FormattedMessage id={`memberInformationModalTitleMemberInformationWorkForce`} defaultMessage="Member Information" />}
        visible={visibleMemberInfor}
        onCancel={handleCloseModal}
        LangCode=""
      />
    </MonitorContext.Provider>
  );
};
