import React, { useState, useEffect, createContext, useContext } from 'react';
import httpClient from '../components/axiosClient';

import { useKeycloak, withKeycloak } from '@react-keycloak/web';
import _ from 'lodash';
import axios from 'axios';
import { getGeoCompanyApi } from '../controllers/api-map';

const AppContext = createContext();

export const AppProvider = (props) => {
  const { children } = props;
  const { keycloak } = useKeycloak();

  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const email = localStorage.getItem('email');
  const comId = localStorage.getItem('comId');
  const comName = localStorage.getItem('comName');
  const comCode = localStorage.getItem('comCode');
  const memId = localStorage.getItem('memId');
  // localStorage.getItem('GEO_APIKEY')
  const tokenKey = keycloak.token;
  const userId = localStorage.getItem('userId');

  const memComId = localStorage.getItem('memComId');
  const langValue = localStorage.getItem('langValue') === undefined ? 'EN' : localStorage.getItem('langValue');
  const fullName = firstName + ' ' + lastName;
  const [dataUser, setDataUser] = useState();
  const [latlngCompany, setlatlngCompany] = useState()
  const [pageSelect, setPageSelect] = useState('');
  const [actions, setActions] = useState([]);
  const [testSetPerm, setTestSetPerm] = useState(true);
  const [triggerApiCompany, setTriggerApiCompany] = useState(false);
  const [geoKey, setGeoKey] = useState('');

  // const [tabKeysMenu, setTabKeysMenu] = useState('3');
  // console.log('GEO_APIKEY_ENV', localStorage.getItem('GEO_APIKEY'));
  // console.log('GEO_APIKEY_LOCAL', localStorage.getItem('GEO_APIKEY'));

  const show = {
    userEdit: false,
    userInvite: true,
    switch: true,
    leftmenu: true,
    leftmenuUsersList: true,
    forgotPassword: false,
    buttonUploadDoc: true,
  };

  const getDataUser = async (token) => {
    const url = `${process.env.REACT_APP_URL_MANAGER}`;
    const port = `${process.env.REACT_APP_RESOURCE_MANAGER_OLD_PORT}`;
    const baseURL = `${url}:${port}`;
    try {
      const response = await httpClient.get(`/v2/member/profile/keycloak/${userId}`, { params: { setTokens: token } });
      if (response.status === 200) {
        const findLatlng = _.find(_.get(response, 'data.companies'), function (o) { return o.com_code === comCode; });
        setDataUser(_.get(response, 'data'));
        setlatlngCompany({
          lat: parseFloat(_.get(findLatlng, 'location_lat') || 0),
          lng: parseFloat(_.get(findLatlng, 'location_lng') || 0)
        })
      } else {
        setDataUser();
      }
    } catch (error) {
      return;
    }
  };

  const getGeoApiKey = async (token) => {
    try {
      const response = await getGeoCompanyApi();
      if (response.status === 200) {
        const apiKey = _.get(response.data.data, 'GEO_APIKEY');
        if (apiKey) {
          localStorage.setItem('GEO_APIKEY', apiKey);
        } else {
          localStorage.setItem('GEO_APIKEY', '');
        }
      } else {
        localStorage.setItem('GEO_APIKEY', '');
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    if (keycloak.token) {
      getDataUser(keycloak.token);
      getGeoApiKey(keycloak.token);
    }
  }, [keycloak.token, triggerApiCompany]);



  useEffect(() => {
    // const getAction = async () => {
    //   try {
    //     const getActionList = await httpClient.get(`/company/${comId}/permission/action/P2S1`);
    //     if(getActionList.status === 200) {
    //       setActions(getActionList.data);
    //     }
    //   }
    //   catch (error) {
    //   }
    // }
    // if(pageSelect) {
    //   getAction();
    //   checkPermission();
    // }
  }, [pageSelect]);

  const checkPermission = (code) => {
    const found = actions.findIndex((elements) => elements.code === 'P2S1PG1');
    if (found > -1) {
      const found1 = actions[found].action.findIndex((elements) => elements.code === 'P2S1PG1A1');
      if (found1 > -1) {
        actions[found].action[found1].permission[0].users.indexOf(memComId) > -1 ? setTestSetPerm(true) : setTestSetPerm(false);
      }
    }
  };

  return (
    <AppContext.Provider
      value={{
        state: {
          userId,
          firstName,
          lastName,
          fullName,
          email,
          comId,
          comName,
          comCode,
          memId,
          dataUser,
          langValue,
          show,
          pageSelect,
          testSetPerm,
          latlngCompany
        },
        fnc: {
          setDataUser,
          setPageSelect,
          setTriggerApiCompany,
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
