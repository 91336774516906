import { Icon, Modal, Spin, Tag } from 'antd';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import Button_02 from '../../../../components/v2/button_02';
import { getPreviewFileApi, saveFile } from '../../../../controllers/orders-controller/api';
import { DeliveryContext } from '../..';
import ActionOrdersPreview from './action-import';
import OrderSourcePreview from './source-import';
import './css/index.css';
import { errorNotification, successNotification } from '../../../../components/v2/notification';

const ModalImportPreview = ({ open, setOpen, setOpenImport }) => {
  const intl = useIntl();
  const { fileHash, setFileHash, setTriggerOrder, selectBranchData, setTrigger } = useContext(DeliveryContext);
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => record.index,
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColCustomer`, defaultMessage: 'Customer' }),
      dataIndex: 'customerName',
      key: 'customerName',
      width: 250,
      sorter: true,
      // ellipsis: true,
      fixed: 'left',
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'customerName') ? '#e73845' : null }}>{record.customerName}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: `planningColumnOrderType`, defaultMessage: 'Order Type' }),
      dataIndex: 'orderType',
      key: 'orderType',
      ellipsis: true,
      width: 150,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColStatus`, defaultMessage: 'Status' }),
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      width: 150,
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return (
          <div className="tag-center-div-order">
            <Tag className="tag-center-style-order" color={record.statusColor}>
              {record.statusText}
            </Tag>
          </div>
        );
      },
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColSize`, defaultMessage: 'Size' }),
      dataIndex: 'size',
      key: 'size',
      width: 130,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <div style={{ color: _.get(record.highlightCol, 'size') ? '#e73845' : null }}>{record.size}</div>,
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColWeight`, defaultMessage: 'Weight (kg)' }),
      dataIndex: 'weight',
      key: 'weight',
      width: 160,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <div style={{ color: _.get(record.highlightCol, 'weight') ? '#e73845' : null }}>{record.weight}</div>,
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColParcel`, defaultMessage: 'Parcels' }),
      dataIndex: 'parcel',
      key: 'parcel',
      width: 100,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColContact`, defaultMessage: 'Contact' }),
      dataIndex: 'contactName',
      key: 'contactName',
      width: 220,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'contactName') ? '#e73845' : null }}>{record.contactName}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColPhone`, defaultMessage: 'Phone' }),
      dataIndex: 'contactPhone',
      key: 'contactPhone',
      width: 150,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'contactPhone') ? '#e73845' : null }}>{record.contactPhone}</div>
      ),
    },
    // {
    //   title: intl.formatMessage({ id: `transportationOrdersColDestinationName`, defaultMessage: 'Destination Name' }),
    //   dataIndex: 'destinationName',
    //   key: 'destinationName',
    //   width: 150,
    //   sorter: true,
    //   ellipsis: true,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (text, record) => (
    //     <div style={{ color: _.get(record.highlightCol, 'destinationName') ? '#e73845' : null }}>{record.destinationName}</div>
    //   ),
    // },
    {
      title: intl.formatMessage({ id: `transportationOrdersColDestination`, defaultMessage: 'Destination' }),
      dataIndex: 'destinationAddress',
      key: 'destinationAddress',
      sorter: true,

      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'destinationAddress') ? '#e73845' : null }}>{record.destinationAddress}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColDeliveryDate`, defaultMessage: 'Delivery Date' }),
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      width: 150,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'deliveryDate') ? '#e73845' : null }}>{record.deliveryDate}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColDeliveryTime`, defaultMessage: 'Delivery Time' }),
      dataIndex: 'deliveryTime',
      key: 'deliveryTime',
      width: 150,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'deliveryTime') ? '#e73845' : null }}>{record.deliveryTime}</div>
      ),
    },
    // {
    //   title: intl.formatMessage({ id: `transportationOrdersColSourceName`, defaultMessage: 'Source Name' }),
    //   dataIndex: 'sourceName',
    //   key: 'sourceName',
    //   width: 150,
    //   sorter: true,
    //   ellipsis: true,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (text, record) => (
    //     <div style={{ color: _.get(record.highlightCol, 'sourceName') ? '#e73845' : null }}>{record.sourceName}</div>
    //   ),
    // },
    {
      title: intl.formatMessage({ id: `transportationOrdersColSource`, defaultMessage: 'Source' }),
      dataIndex: 'sourceAddress',
      key: 'sourceAddress',
      ellipsis: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'sourceAddress') ? '#e73845' : null }}>{record.sourceAddress}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColReferenceNo`, defaultMessage: 'Reference No.' }),
      dataIndex: 'referenceNo',
      key: 'referenceNo',
      width: 150,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'referenceNo') ? '#e73845' : null }}>{record.referenceNo}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColRemark`, defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
      width: 200,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <div style={{ color: _.get(record.highlightCol, 'remark') ? '#e73845' : null }}>{record.remark}</div>,
    },
  ];

  const setShowColumn = {
    index: true,
    customerName: true,
    orderType: true,
    orderStatus: true,
    size: true,
    weight: true,
    parcel: true,
    contactName: true,
    contactPhone: true,
    // destinationName: true,
    destinationAddress: true,
    deliveryDate: true,
    deliveryTime: true,
    // sourceName: true,
    sourceAddress: true,
    referenceNo: false,
    remark: false,
  };

  const setShowColumnArr = [
    'index',
    'customerName',
    'orderType',
    'orderStatus',
    'size',
    'weight',
    'parcel',
    'contactName',
    'contactPhone',
    // 'destinationName',
    'destinationAddress',
    'deliveryDate',
    'deliveryTime',
    // 'sourceName',
    'sourceAddress',


    // 'referenceNo',
    // 'remark',
  ];

  const ignoreColumn = ['referenceNo', 'remark'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const ignoreSearchColumn = ['index', 'deliveryDate', 'deliveryTime'];
  const columnNewSearch = columns.filter((col) => !ignoreSearchColumn.includes(col.key));

  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [scrollTableX, setScrollTableX] = useState(3000);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);

  const [fliterByColumn, setFliterByColumn] = useState('all');
  const [fieldChange, setFieldChange] = useState('');
  const [total, setTotal] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [mainTriggerPage, setMainTriggerPage] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [verified, setVerified] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [isValid, setIsValid] = useState(true);
  useEffect(() => {
    setLoading(true);
    if (fileHash && verified === false) {
      try {
        const payload = {
          fileHash: fileHash,
          pageNumber: paginationPage,
          limit: paginationSize,
          orderBy: fieldSort,
          orderType: orderSort,
        };
        const interval = setInterval(async () => {
          const response = await getPreviewFileApi(payload);
          setVerified(_.get(response.data.data, 'verified'));
        }, 3000);
        return () => clearInterval(interval);
      } catch (error) { }
    } else {
    }
  }, [fileHash, verified]);

  useEffect(() => {
    setLoading(true);
    const getApi = async () => {
      let search = {};
      if (fliterByColumn !== 'all') {
        const result = { [fliterByColumn]: fieldChange };
        search = result;
      } else {
        const filterData = newDataColumns.filter((item) => ['index', 'deliveryDate', 'deliveryTime'].indexOf(item.key) === -1);
        const result = filterData.reduce((obj, cur) => ({ ...obj, [cur.key]: fieldChange }), {});
        search = result;
      }
      try {
        const payload = {
          fileHash: fileHash,
          pageNumber: paginationPage,
          limit: paginationSize,
          orderBy: fieldSort,
          orderType: orderSort,
          search: search,
        };
        const response = await getPreviewFileApi(payload);
        setDataSource(_.get(response.data.data, 'list'));
        setTotal(_.get(response.data.data, 'orderCount'));
        setVerified(_.get(response.data.data, 'verified'));
        setIsValid(_.get(response.data.data, 'isValid'));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    if (fileHash && verified === true) {
      getApi();
    }
  }, [fileHash, verified, paginationPage, paginationSize, fieldSort, orderSort, fieldChange, fliterByColumn]);

  console.log('datasouece', dataSource);
  const handleSave = async () => {
    setIsLoad(true);
    try {
      const payload = {
        branchDetail: {
          "branchId": _.get(selectBranchData, 'id'),
          "branchCode": _.get(selectBranchData, 'branchCode'),
          "branchName": _.get(selectBranchData, 'branchName'),
          "branchPhone": _.get(selectBranchData, 'branchPhone'),
          "branchAddressName": _.get(selectBranchData, 'branchAddressName'),
          "branchAddress": _.get(selectBranchData, 'branchAddressDescription'),
          "branchAddressLat": _.get(selectBranchData, 'branchAddressLat'),
          "branchAddressLng": _.get(selectBranchData, 'branchAddressLng'),
          "branchRemark": _.get(selectBranchData, 'branchRemark')
        },
        fileHash: fileHash,
      };
      const response = await saveFile(payload);
      successNotification(_.get(response.data.status, 'message'));
      setTriggerOrder((current) => !current);
      setTrigger(cur => !cur)
      setOpen(false);
      setDataSource([]);
      setVerified(false);
      setFileHash();
      setFliterByColumn('all');
      setTotal();
      setFieldChange('');
      setOrderSort();
      setFieldSort();
      setPaginationPage(1);
      setPaginationSize(10);
      resetColumn();
      setIsLoad(false);
      setOpenImport(false);
      setIsValid(false);
    } catch (error) {
      errorNotification(_.get(error.response.status, 'message'));
      setIsLoad(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setDataSource([]);
    setVerified(false);
    setFileHash();
    setFliterByColumn('all');
    setTotal();
    setFieldChange('');
    setOrderSort();
    setFieldSort();
    setPaginationPage(1);
    setPaginationSize(10);
    resetColumn();
  };

  const resetColumn = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    setVisible(false);
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
    setScrollTableX(3000);
  };

  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  return (
    <Modal
      title={intl.formatMessage({ id: 'modalOrderImportDeliveryOrder', defaultMessage: 'Import Delivery Orders' })}
      visible={open}
      width={1250}
      bodyStyle={{ height: 560 }}
      centered={true}
      onCancel={() => handleCancel()}
      footer={
        <div className="layout-footer-import">
          <div className="warning-layout-footer">
            {isValid === false ? (
              <>
                <Icon type="info-circle" style={{ fontSize: '16px', color: '#e73845' }} />{' '}
                <FormattedMessage
                  id="warningImportPreview"
                  defaultMessage="This file has data errors. Please edit CSV file and re-import."
                />
              </>
            ) : null}
          </div>
          <div>
            <Button_02 key="back" btnsize="wd_df" style={{ margin: 'unset' }} onClick={() => handleCancel()} disabled={isLoad}>
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button_02>
            <Button_01 key="submit" type="primary" btnwidth="wd_at" onClick={() => handleSave()} disabled={isLoad}>
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button_01>
          </div>
        </div>
      }
    >
      <Spin indicator={antIcon} tip="Loading..." spinning={isLoad}>
        <div>
          <ActionOrdersPreview
            columns={columns}
            setShowColumn={setShowColumn}
            setShowColumnArr={setShowColumnArr}
            columnNewSearch={columnNewSearch}
            newDataColumns={newDataColumns}
            listArrayColumns={listArrayColumns}
            defaultShowColumn={defaultShowColumn}
            newColumns={newColumns}
            fliterByColumn={fliterByColumn}
            fieldChange={fieldChange}
            setNewDataColumns={setNewDataColumns}
            setListArrayColumns={setListArrayColumns}
            setDefaultShowColumn={setDefaultShowColumn}
            setScrollTableX={setScrollTableX}
            setFliterByColumn={setFliterByColumn}
            setFieldChange={setFieldChange}
            textErrorSelectColumn={textErrorSelectColumn}
            setTextErrorSelectColumn={setTextErrorSelectColumn}
            visible={visible}
            setVisible={setVisible}
          />
        </div>
        <div>
          <OrderSourcePreview
            newDataColumns={newDataColumns}
            scrollTableX={scrollTableX}
            paginationPage={paginationPage}
            total={total}
            loading={loading}
            dataSource={dataSource}
            setPaginationPage={setPaginationPage}
            setPaginationSize={setPaginationSize}
            setOrderSort={setOrderSort}
            setFieldSort={setFieldSort}
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default ModalImportPreview;
