import React from 'react'
import {
  Input,
  Select,
  Row,
  Col,
  Icon,
  Dropdown,
  Menu,
  Checkbox,
  Button
} from 'antd'
import style from './css//action-customer.css'
import Button01 from '../../../components/v2/button_01'
import { useIntl, FormattedMessage } from 'react-intl';
import { PageSettings } from '../../../config/page-settings';


const { Option } = Select;
export default ({
  onChange,
  onSelect,
  selectSearchData,
  langValue,
  labelShow,
  onSelectMonthPicker,
  onSelectRangePicker,
  columns,
  checkedValuecolumns,
  checked,
  handleReset,
  handleCheckOk,
  visible,
  handleOnVisiblecolumns,
  handleVisibleChange,
  textErrorSelectColumn,
  setvisibleCustomer,
  visibleCustomer,
}) => {
  const intl = useIntl();
  const ignoreColumn = ['index', 'profileImg', 'customerCode', 'customerName', 'test'];
  const columnNew = columns.filter((col) => !ignoreColumn.includes(col.key));

  const ignoreSearchColumn = ['index', 'profileImg', 'test'];
  const selectSearchColumn = columns.filter((col) =>
    !ignoreSearchColumn.includes(col.key),
  );


  const menuColumn = () => {
    return (
      <Menu>
        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          {columnNew.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox
                value={item.key}
                onChange={checkedValuecolumns}
                checked={checked[item.key] ? true : false}
              >
                <span style={{ fontSize: '13px' }}>{item.title}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>
          {textErrorSelectColumn}
        </Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleCheckOk}
              style={{ width: '50%', fontSize: '13px' }}
            >
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button
              onClick={handleReset}
              style={{ width: '50%', fontSize: '13px' }}
            >
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        <div>
          <Row className='padding-row'>
            <Col span={7}>
              <Input
                className='input-search action-input border-searth-allcol'
                onChange={(e) => onChange(e.target.value)}
                placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
                autoComplete="off"
              ></Input>
              <Select className='select-search action-select'
                value={selectSearchData}
                onSelect={(value) => onSelect(value)}
              >
                <Option value="all">
                  {
                    <FormattedMessage
                      id="reportTimeAttendanceHintSeleteAllColumns"
                      defaultMessage="All columns"
                    />
                  }
                </Option>
                {selectSearchColumn.map((item, i) => (
                  <Option key={i} value={item.key}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={17}>
              <Row className='position-right'>

                <Dropdown
                  className="customer-dropdown"
                  overlay={menuColumn}
                  trigger={['click']}
                  onVisibleChange={handleVisibleChange}
                  visible={visible}
                  placement="bottomRight"
                >
                  <Button01
                    type="primary"
                    btnsize="wd_at"
                    fontSize="sm"
                    onClick={handleOnVisiblecolumns}
                  >
                    <FormattedMessage id="reportTimeAttendanceBtnColumns" defaultMessage="Columns" />{" "}
                    <Icon type="down" />
                  </Button01>
                </Dropdown>
                {/* {
                  checkPermissionAction('P8PG1C1', 'P8PG1C1A2') ?
                    (
                      <Button01 fontsize="sm" type="primary" btnsize="wd_at" onClick={() => {
                        setvisibleCustomer(true)
                      }} >  <FormattedMessage id="customerActionButtonAddCustomer" defaultMessage="Add Customer" /></Button01>
                    ) : null
                } */}

              </Row>


            </Col>
          </Row>
        </div>}
    </PageSettings.Consumer>
  )
}
