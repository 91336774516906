import { Button, Col, Form, Icon, Modal, Row, Spin, Upload } from 'antd';
import _ from 'lodash';
import React from 'react';
import { useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import LabeRequire from '../../../../components/v2/label-require';
import { errorNotification, successNotification } from '../../../../components/v2/notification';
import { uploadFileApi } from '../../../../controllers/orders-controller/api';
import { DeliveryContext } from '../..';
import ModalImportPreview from '../import-preview';

const ModalImportOrder = ({ open, setOpen, handleDownLoadTemplate }) => {
  const intl = useIntl();
  const { setFileHash } = useContext(DeliveryContext)
  const [openPreview, setOpenPreview] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const props = {
    multiple: false,
    showUploadList: false,
    action: '',
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onChange(info) {
      const isXlxs = info.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      if (!isXlxs) {
        errorNotification('You can only upload Xlxs file!');
        return false
      }

      if (info.file) {
        fileUpload(info.file)
      }

      info = null
    },
    beforeUpload() {
      return false;
    },
  };

  const fileUpload = async (file) => {
    setIsLoad(true);
    setOpenPreview(true);
    if (file) {
      let formFile = new FormData();
      formFile.append('file', file);
      try {
        const response = await uploadFileApi(formFile)
        if (_.get(response.data.data, 'fileHash')) {
          setFileHash(_.get(response.data.data, 'fileHash'));
          setOpenPreview(true);
          successNotification(_.get(response.data.status, 'message'));
        }
        else {
          errorNotification(_.get(response.data.status, 'message'));
          setOpenPreview(false);
        }
      } catch (error) {
        errorNotification(_.get(error.response.data.status, 'message'))
      }
    }
    setIsLoad(false);
  }

  const handleCancel = () => {
    setFileHash()
    setOpen(false)
  }



  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  return (
    <Modal
      title={intl.formatMessage({ id: 'modalOrderImportDeliveryOrder', defaultMessage: 'Import Delivery Order' })}
      visible={open}
      onCancel={() => handleCancel()}
      width={480}
      centered={true}
      footer={[
        <Button_01 key="close" type="primary" btnwidth="wd_at" onClick={() => handleCancel()} disabled={isLoad}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_01>,
      ]}
    >
      <Spin indicator={antIcon} spinning={isLoad}>
        <Form colon={false}>
          <Row gutter={[12, 24]}>
            <Col span={12}>
              <Form.Item
                label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderImportFile', defaultMessage: 'File' })} req={true} />}
              >
                <Upload {...props}>
                  <Button style={{ width: '100%' }}>
                    <Icon type="upload" /> <FormattedMessage id="modalOrderClicktoupload" defaultMessage="Click to upload" />
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequire
                    text={intl.formatMessage({ id: 'modalOrderImportTemplateCSV', defaultMessage: 'Template CSV' })}
                    req={false}
                  />
                }
              >
                <Button_01 key="upload" type="primary" style={{ margin: 'unset', width: '100%' }} onClick={() => handleDownLoadTemplate()}>
                  <FormattedMessage id="modalOrderDownloadTemplate" defaultMessage="Download Template" />
                </Button_01>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
      <ModalImportPreview open={openPreview} setOpen={setOpenPreview} setOpenImport={setOpen} />
    </Modal>
  );
};

export default ModalImportOrder;
