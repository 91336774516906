import React, { useState, useEffect, memo, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Transfer, Table, notification, Icon, Spin } from 'antd';
import Modal from '../../../components/v2/modal';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import Input from '../../../components/v2/input';
import httpClient from '../../../components/axiosClient';
import difference from 'lodash/difference';
import { PageSettings } from '../../../config/page-settings';
import { fncGetUsers } from '../../../controllers/user/users';
import _ from 'lodash';
import cssStyle from './css/modalMember.css';

const filterOption = (inputValue, items) => {
  if (!inputValue) {
    return items;
  }
  const lowercasedFilter = inputValue.toLowerCase();
  const filteredData = items.filter((item) => {
    return Object.keys(item).some((key) => typeof item[key] === 'string' && item[key].toLowerCase().includes(lowercasedFilter));
  });
  return filteredData;
};

// Customize Table Transfer
const TableTransfer = memo(({ leftColumns, rightColumns, intl, ...restProps }) => (
  <Transfer className="transfer-member" {...restProps} showSelectAll={false}>
    {({ direction, filteredItems, onItemSelectAll, onItemSelect, selectedKeys: listSelectedKeys, disabled: listDisabled }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;
      const rowSelection = {
        getCheckboxProps: (item) => ({
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
          const diffKeys = selected ? difference(treeSelectedKeys, listSelectedKeys) : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      let isLeft = direction === 'left';
      let defalutData = [];
      defalutData = filterOption(restProps.search[isLeft ? 0 : 1], filteredItems);

      return (
        <div>
          <Input
            className="input-search-member"
            value={restProps.search[isLeft ? 0 : 1]}
            onChange={(e) => {
              restProps.handleSearch(direction, e.target.value);
            }}
            placeholder={intl.formatMessage({ id: 'orgTabMemberModalHintSearch', defaultMessage: 'Search' })}
            prefix={<Icon className="input-search-member-prefix" type="search" />}
            autoComplete="off"
          />
          <Table
            className="transfer-table-member"
            rowKey={(record) => record.key}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={defalutData}
            size="small"
            pagination={false}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
            scroll={{ y: 300 }}
          />
        </div>
      );
    }}
  </Transfer>
));

export default memo((props) => {
  const mApp = useContext(PageSettings);
  const intl = useIntl();
  const members = props.dataMembers;

  let orgId = props.orgId;
  let created_by = localStorage.getItem('memComId');

  const [loading, setLoading] = useState(false);
  const [mockData, setMockData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [selected, setSelected] = useState([]);
  const [inputSearch, setInputSearch] = useState(['', '']);
  const [disButton, setDisButton] = useState(false);
  const [spin, setSpin] = useState(false);

  // console.log('Transfer mockData::: ', mockData);
  // console.log('Transfer targetKeys::: ', targetKeys);
  // console.log('Transfer members data::: ', members);

  const leftTableColumns = [
    {
      dataIndex: 'fullname',
      title: intl.formatMessage({ id: 'orgTabMemberColumnContactName', defaultMessage: 'Contact Name' }),
    },
    {
      dataIndex: 'email',
      title: intl.formatMessage({ id: 'orgTabMemberColumnEmail', defaultMessage: 'Email' }),
    },
  ];

  const rightTableColumns = [
    {
      dataIndex: 'fullname',
      title: intl.formatMessage({ id: 'orgTabMemberColumnContactName', defaultMessage: 'Contact Name' }),
    },
    {
      dataIndex: 'email',
      title: intl.formatMessage({ id: 'orgTabMemberColumnEmail', defaultMessage: 'Email' }),
    },
  ];

  useEffect(() => {
    getMock();
    getMember(members);
  }, [props.orgId, props.visible]);

  const handleSave = async () => {
    setDisButton(true);
    setSpin(true);
    const body = {
      user_id: targetKeys,
      created_by: created_by,
      org_id: orgId,
    };

    try {
      const response = await httpClient.post('/v2/manageteammember', body);

      if (response.status === 200) {
        if (response.data.data.length > 0) {
          // setTimeout(()=>{
          infoNotification(response.data.data);
          props.setRefreshTable((curren) => !curren);
          props.setTriggerMember((curren) => !curren);
          props.setPaginationSize(10);
          // },3000)
          // props.setPaginationPage(1);
          props.setVisible(false);
          setSelected([]);
          setInputSearch(['', '']);
          setSpin(false);
          setDisButton(false);
        } else {
          successNotification(response.data.message);
          setSpin(false);
          setDisButton(false);
        }
      }
    } catch (error) {
      setSpin(false);
      props.setVisible(false);
      setSelected([]);
      setInputSearch(['', '']);
      setDisButton(false);
      return;
    }
    setDisButton(false);
  };

  const handleCancel = () => {
    props.setVisible(false);
    setLoading(false);
    setInputSearch(['', '']);
    setSelected([]);
    getMember(props.dataMembers);
    getMock();
  };

  const getMock = async () => {
    const mockData = [];
    const users = await fncGetUsers(created_by, mApp.comId, true);
    for (let i = 0; i < users.length; i++) {
      const data = {
        key: users[i].mem_com_id.toString(),
        fullname: users[i].fullname,
        email: users[i].email,
      };
      mockData.push(data);
    }
    setMockData(mockData);
  };

  const getMember = (dataMembers) => {
    const targetKeys = [];
    if (dataMembers.members) {
      for (let i = 0; i < dataMembers.members.length; i++) {
        targetKeys.push(dataMembers.members[i].mem_com_id.toString());
      }
    }
    setTargetKeys(targetKeys);
  };

  const handleChange = (targetKeys, direction, moveKeys) => {
    setTargetKeys(targetKeys);
  };

  const handleSearch = (dir, value) => {
    let option = [...inputSearch];
    if (dir === 'left') {
      option[0] = value;
    } else {
      option[1] = value;
    }
    setInputSearch(option);
  };

  const selectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelected([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const successNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const infoNotification = (data) => {
    data.map((item, index) => {
      return (
        <div key={index}>
          {notification[item.type]({
            message: item.message,
            description: item.data ? item.data.map((item) => item.name) : null,
          })}
        </div>
      );
    });
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Modal
            className="member"
            width={'85%'}
            visible={props.visible}
            title={<FormattedMessage id="orgTabMemberModalTitleMember" defaultMessage="Member" />}
            onOk={handleSave}
            onCancel={handleCancel}
            footer={[
              <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" fontsize="sm" btnsize="wd_df" onClick={handleCancel} margin=" unset">
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button02>,
              <Button01
                type="primary"
                key="submit"
                fontsize="sm"
                btnsize="wd_df"
                loading={loading}
                onClick={handleSave}
                disabled={disButton}
              >
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button01>,
            ]}
          >
            <Spin spinning={spin} tip="Waiting...">
              <TableTransfer
                titles={[
                  intl.formatMessage({ id: 'orgTabMemberModalTxtUsers', defaultMessage: 'Users' }),
                  intl.formatMessage({ id: 'orgTabMemberModalTxtMember', defaultMessage: 'Member' }),
                ]}
                dataSource={mockData}
                targetKeys={targetKeys}
                onChange={handleChange}
                selectedKeys={selected}
                leftColumns={leftTableColumns}
                rightColumns={rightTableColumns}
                onSelectChange={selectChange}
                search={inputSearch}
                onSearch={handleSearch}
                handleSearch={handleSearch}
                intl={intl}
              />
            </Spin>
          </Modal>
        </div>
      )}
    </PageSettings.Consumer>
  );
});
