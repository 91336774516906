import httpCiient from '../../components/axiosClient';
import axios from 'axios';

const comCode = localStorage.getItem('comCode');

const loadmoreCustomer = async (payload) => {
  try {
    const response = await httpCiient.post(`/v4/business-partner/manager/company/${comCode}/customer-informations/get/load-more`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const loadmoreItemType = async (payload) => {
  try {
    const response = await httpCiient.post(`/v4/item/manager/company/${comCode}/item-type/loadmore`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const loadmoreDestination = async (payload, customerId) => {
  try {
    const response = await httpCiient.post(
      `/v4/business-partner/manager/company/${comCode}/customer-address/get/load-more/${customerId}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const loadmoreSource = async (payload) => {
  try {
    const response = await httpCiient.post(
      `/v4/business-partner/manager/company/${comCode}/company-address/latest/match/destination`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const durationTime = async () => {
  try {
    const response = await httpCiient.get(`/v3/order/manager/company/${comCode}/delivery-time/order`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createOrder = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/order/manager/company/${comCode}/web/deliveryorder`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const editOrder = async (payload, id, status) => {
  try {
    const response = await httpCiient.put(`/v5/order/manager/company/${comCode}/web/deliveryorder/${id}`, payload);
    return status ? response : response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getStatus = async (orderId = '') => {
  try {
    const response = await httpCiient.get(`/v4/order/manager/company/${comCode}/order/status?orderId=${orderId}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getOrdersList = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/order/manager/company/${comCode}/web/deliveryorder/report`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getOrdersListParcel = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/order/manager/company/${comCode}/web/deliveryorder/report?parcelDetail=true`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getOrdersListExport = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/order/manager/company/${comCode}/web/deliveryorder/reportexport`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getOrdersById = async (id) => {
  try {
    const response = await httpCiient.get(`/v5/order/manager/company/${comCode}/web/deliveryorder/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getOrdersByIdParcel = async (id) => {
  try {
    const response = await httpCiient.get(`/v5/order/manager/company/${comCode}/web/deliveryorder/${id}?parcelDetail=true`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getOrdersTracking = async (body) => {
  try {
    const response = await httpCiient.post(`/v5/order/manager/company/${comCode}/web/public/order/tracking`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

const deleteById = async (id) => {
  try {
    const response = await httpCiient.delete(`/v5/order/manager/company/${comCode}/web/deliveryorder/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getSize = async () => {
  try {
    const response = await httpCiient.get(`/v5/order/manager/company/${comCode}/web/ordersize`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getReferancetype = async (search = '') => {
  try {
    const response = await httpCiient.get(`/v5/resource/manager/company/${comCode}/web/referancetype?refTypeName=${search}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const uploadFileApi = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/order/manager/company/${comCode}/web/deliveryorder/import-exceldata`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getPreviewFileApi = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/order/manager/company/${comCode}/web/deliveryorder/preview-exceldata`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const saveFile = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/order/manager/company/${comCode}/web/deliveryorder/create-dataimport`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const updateParcel = async (id, payload) => {
  try {
    const response = await httpCiient.post(
      `/v5/order/manager/company/${comCode}/delivery-order/${id}/upsert-delivery-order-detail`,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

const payOrder = async (id, payload) => {
  try {
    const response = await httpCiient.post(`/v5/order/manager/company/${comCode}/web/deliveryorder/${id}/paid`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getWeight = async () => {
  // try {
  //   const response = await fetch('http://127.0.0.1:8000/weigh/live')
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log(data);
  //     });
  //   // const response = await axios.get('http://127.0.0.1:8000/weigh/live');
  //   return response;
  // } catch (error) {
  //   return error.response;
  // }

  try {
    const response = await fetch('https://localhost:8000/weigh/live', {
      mode: 'cors',
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error('There was a problem with your fetch operation:', error);
  }
};

export {
  loadmoreCustomer,
  loadmoreItemType,
  loadmoreDestination,
  loadmoreSource,
  durationTime,
  createOrder,
  getStatus,
  getOrdersList,
  getOrdersListExport,
  getOrdersById,
  getOrdersByIdParcel,
  deleteById,
  editOrder,
  getSize,
  getReferancetype,
  uploadFileApi,
  getPreviewFileApi,
  saveFile,
  getOrdersListParcel,
  updateParcel,
  getOrdersTracking,
  payOrder,
  getWeight,
};
