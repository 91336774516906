import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Divider, Input, Modal, Progress } from 'antd';
import _ from 'lodash';
import Button_02 from '../../../../../components/v2/button_02';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import Button_01 from '../../../../../components/v2/button_01';
import CustomTableComponent from '../../../components/table';
import { handleKeyDownNumber } from '../../../../../component-function/fnc-number';
import NoDataBackground from '../../../../../components/no-data-page';
import ModalAddARItem from './add-remove';
import { v4 as uuidv4 } from 'uuid';

const ModelAddItemIN = ({
  form,
  visible,
  selectItem,
  attData,
  setAttData,
  title,
  onCancel,
  pageTable,
  sizeTable,
  setPageTable,
  setSizeTable,
  warehouseList,
  saleOrderQTY,
  setWarehouseList,
  setSaleOrderQTY,
  handleCleckSaleOrder,
  recordList,
  setRecordList,
  setSelectItem,
  totalWarehouseList,
  setTotalWarehouseList,
}) => {
  const intl = useIntl();
  // const { getFieldDecorator } = form;
  const [maxRemain, setMaxRemain] = useState(0);
  const [selectWHData, setSelectWHData] = useState([]);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [typeQty, setTypeQty] = useState(false);
  const [tempSelectItem, setTempSelectItem] = useState([]);
  // const [tempSelectItemQTY, setTempSelectItemQTY] = useState(saleOrderQTY);
  const [tempQTYTest, setTempQTYTest] = useState([]);

  // const { visible, selectItem, attData, setAttData, title, onCancel } = property;

  console.log('warehouseList', warehouseList, '/', tempSelectItem);
  console.log('saleOrderQTY', saleOrderQTY, '/', tempQTYTest);
  console.log('maxRemain', maxRemain, '/', _.get(recordList, 'remainingQty'), '/', _.get(recordList, 'qtyDeliver'));

  useEffect(() => {
    if (recordList) {
      if (_.get(recordList, 'qtyDeliver')) {
        const minus = _.get(recordList, 'remainingQty') - _.get(recordList, 'qtyDeliver');
        setMaxRemain(minus);
      } else {
        setMaxRemain(_.get(recordList, 'remainingQty'));
      }
    }
  }, [recordList]);

  useEffect(() => {
    if (visible === true) {
      console.log('TestVisible');
      const saletemp = [...saleOrderQTY];
      setTempSelectItem(selectItem);
      setTempQTYTest(_.cloneDeep(saletemp));
    }
  }, [visible]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemMasterDetail.item_code',
      key: 'itemMasterDetail.item_code',
      sorter: true,
      width: 150,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'ARCreditDays', defaultMessage: 'Credit (days)' }),
      dataIndex: 'credit',
      key: 'credit',
      sorter: true,
      // render: (text, record, index) => record.qty + ' ' + record.uomName,
    },
    {
      title: intl.formatMessage({ id: 'ARDeliveredQty', defaultMessage: 'Delivered Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      render: (text, record, index) => record.invoicedQty + '/' + record.qty,
    },
    {
      title: intl.formatMessage({ id: 'ARRemainingQty', defaultMessage: 'Remaining Qty' }),
      dataIndex: 'remainingQty',
      key: 'remainingQty',
      sorter: true,
      // render: (text, record, index) => numberFormatter(record.pricePerUnit) + ' ' + 'THB',
    },
    {
      title: intl.formatMessage({ id: 'ARQtyToDeliver', defaultMessage: 'Qty to Deliver' }),
      dataIndex: 'qtyDeliver',
      key: 'qtyDeliver',
      sorter: true,
      // render: (text, record, index) => _.sumBy(record.qtyDeliver,'qty'),
    },
    {
      title: intl.formatMessage({ id: 'ARUoM', defaultMessage: 'UoM' }),
      dataIndex: 'unitName',
      key: 'unitName',
      // render: (text, record, index) => ?  :'Sack',
    },
  ];

  const columnsAttach = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'ARWarehouseCode', defaultMessage: 'Warehouse Code' }),
      dataIndex: 'warehouseCode',
      key: 'warehouseCode',
      sorter: true,
      width: 170,
    },
    {
      title: intl.formatMessage({ id: 'ARWarehouse', defaultMessage: 'Warehouse' }),
      dataIndex: 'warehouseName',
      key: 'warehouseName',
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'ARInStock', defaultMessage: 'In Stock' }),
      dataIndex: 'qtyDisplay',
      key: 'qtyDisplay',
      // width: 200,
      render: (text, record, index) => renderProgress(record),
    },
    {
      title: intl.formatMessage({ id: 'ARUoM', defaultMessage: 'UoM' }),
      dataIndex: 'uom',
      key: 'uom',
      width: 150,
    },
    {
      title: intl.formatMessage({ id: 'ARSelectedQty', defaultMessage: 'Selected Qty' }),
      dataIndex: 'selectQty',
      key: 'selectQty',
      width: 150,
      render: (text, record, index) => (
        <Button_01
          key="submit"
          type="primary"
          btnsize="wd_df"
          style={{ margin: 'unset' }}
          onClick={() =>
            _.get(record, 'selectQty')
              ? handleOpenAddARItem(record, 'edit', index, _.get(record, 'selectQty'))
              : handleOpenAddARItem(record, 'add', index)
          }
        >
          {_.get(record, 'selectQty') ? (
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          ) : (
            <FormattedMessage id="btnAdd" defaultMessage="Add" />
          )}
        </Button_01>
      ),
    },
  ];

  const handleOpenAddARItem = (record, type, index, qty) => {
    console.log('temprecord', record);
    if (type === 'edit') {
      let tempMax = [...warehouseList];
      console.log('editItem', tempMax[index], '/', _.get(record, 'selectQty'), '/index: ', index);
      tempMax[index].selectQty = undefined;
      tempMax[index].selectQtyEdit = qty;
      console.log('tempAfter', tempMax);
      setWarehouseList(tempMax);
    }
    setVisibleAdd(true);
    setSelectWHData(record);
    setTypeQty(type);
  };

  const renderProgress = (record) => {
    let color = '';
    let percent = 0;

    if (record.qty === 0) {
      color = '#e73845';
      percent = 100;
    } else if (record.qty < record.minQty) {
      color = '#febb02';
      percent = (record.qty / record.maxQty) * 100;
    } else if (record.qty > record.minQty) {
      color = '#6490cf';
      percent = (record.qty / record.maxQty) * 100;
    }

    return (
      <div className="progress-item">
        <Progress className="bar-progress-custom" strokeColor={color} percent={percent} showInfo={false} />{' '}
        <span className="progress-qty-value">{record.qtyDisplay}</span>
      </div>
    );
  };

  const handleSave = (value, type) => {
    console.log('mapSelect', saleOrderQTY, '/', tempQTYTest);
    let tempqty = 0;
    let tempwh = [];
    let edittemp = 0;

    const tempft = _.filter(tempSelectItem, (i) => {
      return i.saleOrderItemId === _.get(recordList, 'saleOrderItemId');
    });

    const ftSO = tempft[0];
    const ftsoindex = _.get(ftSO, 'warehouseSeletedList');
    console.log('ftSO', ftSO, '/', tempft);
    console.log('WQTY', warehouseList, '/', ftsoindex);

    _.map(warehouseList, (i) => {
      const fineIn = _.findIndex(ftsoindex, (o) => {
        return _.get(o, 'warehouseId') === _.get(i, 'warehouseId');
      });

      console.log('fineIn', fineIn, '/', i.warehouseId, '/', value, '/', _.size(ftsoindex),'/',tempft);

      if (value === i.warehouseId && fineIn > -1 && i.selectQty) {
        if (type === 'edit' && _.get(ftsoindex[fineIn], 'warehouseId') === value) {
          edittemp = parseInt(parseInt(i.selectQty));
          tempqty = parseInt(_.get(ftsoindex[fineIn], 'qty')) + parseInt(i.selectQty);
        } else {
          tempqty = parseInt(_.get(ftsoindex[fineIn], 'qty')) + parseInt(i.selectQty);
        }

        console.log('tempqty', parseInt(tempqty), '/', parseInt(_.get(ftsoindex[fineIn], 'qty')), '/', i.selectQty, '/', fineIn);
        tempwh.push({
          warehouseId: i.warehouseId,
          stockId: i.stockId,
          qty: edittemp > 0 ? edittemp : tempqty,
        });
      } else {
        if (i.selectQty !== undefined && i.warehouseId === value) {
          console.log('SQF', tempqty, '/', parseInt(i.selectQty), '/', parseInt(_.sumBy(ftsoindex, 'qty')));
          console.log('warehouseDD', ftSO && i.warehouseId === value, '/', value);
          if (ftSO && i.warehouseId === value) {
            tempqty = parseInt(_.sumBy(ftsoindex, 'qty')) + parseInt(i.selectQty);
          } else {
            if (type === 'edit') {
              tempqty = tempqty;
            } else {
              tempqty = parseInt(i.selectQty);
            }
          }

          if (_.size(ftsoindex) > 0 )  {
            tempwh = ftsoindex
          }

          tempwh.push({
            warehouseId: i.warehouseId,
            stockId: i.stockId,
            qty: parseInt(i.selectQty),
          });
        }
        else {
          return i
        }
      }
    });


    let tempChangemax = [...tempQTYTest];
    const fineInRecord = _.findIndex(tempQTYTest, ['saleOrderItemId', _.get(recordList, 'saleOrderItemId')]);
    console.log('mapSelect1', tempqty, '/tempwh', tempwh);
    // tempChangemax[fineInRecord].qtyDeliver = parseInt(tempqty);
    tempChangemax[fineInRecord].qtyDeliver = type === 'edit'? _.sumBy(tempwh, 'qty') : tempqty;
    console.log('mapSelect2', saleOrderQTY, '/', tempChangemax[fineInRecord]);
    // setTempSelectItemQTY(tempChangemax);
    setTempQTYTest(tempChangemax);

    const amoutdiscount = parseInt(tempqty) * _.get(recordList, 'price') * (_.get(recordList, 'discountPercent').toFixed(2) / 100);
    const amount = parseInt(tempqty) * _.get(recordList, 'price') - amoutdiscount;

    const payload = {
      index: _.size(tempft) > 0 ? _.get(ftSO, 'index') : _.size(tempSelectItem) + 1,
      itemCode: _.get(recordList, 'itemMasterDetail.item_code'),
      itemName: _.get(recordList, 'itemName'),
      qtyTxt: tempqty + ' ' + _.get(recordList, 'unitName'),
      totaQty: parseInt(tempqty),
      pricePerUnit: numberFormatter(_.get(recordList, 'price')) + ' ' + 'THB',
      price: _.get(recordList, 'price'),
      credit: _.get(recordList, 'credit'),
      discountTHB: numberFormatter(amoutdiscount) + ' ' + 'THB',
      discountPercent: _.get(recordList, 'discountPercent'),
      discount: parseInt(amoutdiscount),
      totalTxt: numberFormatter(amount) + ' ' + 'THB',
      amount: parseInt(amount),
      warehouseSeletedList: tempwh,
      // warehouseSeletedList: ftSO ? tempsetWh : tempwh,
      saleOrderItemId: _.get(recordList, 'saleOrderItemId'),
      subtotal: _.get(recordList, 'discountPercent') === 0 ? amount : amount,
      tax: _.get(recordList, 'tax'),
      taxPercent: _.get(recordList, 'taxPercent'),
      discountPercent: _.get(recordList, 'discountPercent'),
      uuid: uuidv4(),
    };




    if (_.size(tempft) > 0) {
      let temprecord = [...tempSelectItem];

      const fineIn = _.findIndex(tempSelectItem, ['saleOrderItemId', _.get(recordList, 'saleOrderItemId')]);

      temprecord[fineIn] = payload;

      // console.log('temprecord', tempChangemax);
      setTempSelectItem(temprecord);
    } else {
      console.log('QtyItem', payload);

      let temprecord = [...tempSelectItem, payload];

      setTempSelectItem(temprecord);
    }
  };

  const SaveSelecItem = () => {
    setSelectItem(tempSelectItem);
    setSaleOrderQTY(tempQTYTest);
    oncancelthismodal();
  };

  const SaveandContinueSelecItem = () => {
    setSelectItem(tempSelectItem);
    setSaleOrderQTY(tempQTYTest);
    setTotalWarehouseList(0);
    setWarehouseList([]);
    setRecordList([]);
  };

  const oncancelthismodal = () => {
    setTotalWarehouseList(0);
    setWarehouseList([]);
    setRecordList([]);
    setTempSelectItem([]);
    setTempQTYTest([]);
    // setSaleOrderQTY(tempReset);
    // setTempSelectItemQTY([]);
    onCancel();
  };

  const actionFooter = [
    <Button_02
      style={{ margin: '0px 0px 0px 10px' }}
      key="back"
      btnsize="wd_df"
      onClick={() => {
        oncancelthismodal();
      }}
    >
      <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
    </Button_02>,
    <Button_01
      style={{ margin: '0px 0px 0px 10px' }}
      key="save"
      type="primary"
      btnsize="wd_df"
      onClick={() => {
        SaveSelecItem();
      }}
    >
      <FormattedMessage id="btnSave" defaultMessage="Save" />
    </Button_01>,
    <Button_01
      style={{ margin: '0px 0px 0px 10px', width: '18%' }}
      key="save"
      type="primary"
      btnsize="wd_df"
      onClick={() => {
        SaveandContinueSelecItem();
      }}
    >
      <FormattedMessage id="btnSaveandContinue" defaultMessage="Save and Continue" />
    </Button_01>,
  ];

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={() => {}}
      onCancel={() => {
        oncancelthismodal();
      }}
      width={1000}
      centered={true}
      bodyStyle={{ padding: 'unset' }}
      footer={actionFooter}
    >
      <div className="scroll-sm scroll-height">
        <div className="item-title"></div>
        {/* <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelItem" defaultMessage="Item" />
          </span>
          <div>
            <Button_01 key="add" style={{ margin: '0px 0px 0px 10px' }} type="primary" btnsize="wd_df" onClick={() => setVisibleItem(true)}>
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button_01>
          </div>
        </div> */}
        {/* <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} /> */}
        <div>
          <div className="content-body">
            <CustomTableComponent
              onRow={true}
              role={true}
              columns={columns}
              dataSource={tempQTYTest}
              scroll={{ x: true }}
              onRowClick={(record) => {
                console.log('ABCD', record);
                if (_.get(record, 'qtyDeliver') < _.get(record, 'remainingQty')) {
                  handleCleckSaleOrder(record, tempSelectItem);
                } else {
                  setWarehouseList([]);
                }
              }}
              // contentAction={contentActionItem}
            />
          </div>
        </div>
        <div>
          <div className="item-title">
            <span className="item-title-text">
              <FormattedMessage id="ARStockBalance" defaultMessage="Stock Balance" />
              {_.get(recordList, 'itemMasterDetail.item_code')
                ? ' · ' +
                  intl.formatMessage({ id: 'ARItemCode', defaultMessage: 'Item Code' }) +
                  ' ' +
                  _.get(recordList, 'itemMasterDetail.item_code')
                : null}
            </span>
          </div>
          <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
          <div className="content-body">
            {_.size(warehouseList) > 0 ? (
              <CustomTableComponent
                onRow={false}
                role={false}
                columns={columnsAttach}
                dataSource={warehouseList}
                scroll={{ x: true }}
                paginationPage={pageTable}
                setPaginationPage={setPageTable}
                paginationShow={sizeTable}
                setPaginationSize={setSizeTable}
                total={totalWarehouseList}
                // contentAction={contentAction}
              />
            ) : (
              <div style={{ padding: '24px', backgroundColor: '#fff' }}>
                <NoDataBackground
                  text1={{ id: 'ARPleaseSelectItem', text: 'Please Select Item' }}
                  text2={{ id: 'ARStockBalancewillappearhere.', text: 'Stock Balance will appear here.' }}
                  paddingCustom={0}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <ModalAddARItem
        visible={visibleAdd}
        setVisible={setVisibleAdd}
        record={selectWHData}
        setSelectWHData={setSelectWHData}
        maxRemain={maxRemain}
        setMaxRemain={setMaxRemain}
        warehouseList={warehouseList}
        setWarehouseList={setWarehouseList}
        recordList={recordList}
        typeQty={typeQty}
        handleSaveSale={handleSave}
      />
    </Modal>
  );
};

export default ModelAddItemIN;
