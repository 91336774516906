import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import Cookies from 'universal-cookie';
import * as moment from 'moment';
import './index.css';
import LeftMenu from '../includes/leftmenu-v2';
import TopBar from '../includes/topBar';
import GetLang from '../includes/language';
import { isUndefined } from 'lodash';
import { useAppContext } from '../includes/indexProvider';
import { PageSettings } from '../config/page-settings';
import ContentNew from '../components/layout/content/content';
import { useKeycloak } from '@react-keycloak/web';
import useLanguage from '../hooks/use-language';

const { Header, Content } = Layout;
const cookies = new Cookies();

// const pageCode = '001';
const pageCode = 'front.lng.v1';

export default (props) => {
  const { keycloak, initialized } = useKeycloak();
  //useLanguage();
  // console.log('company', localStorage);
  if (initialized) {
    if (
      localStorage.getItem('userId') === 'undefined' ||
      localStorage.getItem('comId') === 'undefined' ||
      localStorage.getItem('companyId') === 'undefined' ||
      localStorage.getItem('memComId') === 'undefined' ||
      localStorage.getItem('userId') === null ||
      localStorage.getItem('comId') === null ||
      localStorage.getItem('companyId') === null ||
      localStorage.getItem('memComId') === null
    ) {
      return <Redirect to="/login/company" />;
    }
  }

  useLanguage();
  const app = useAppContext();
  const comId = localStorage.getItem('comId');
  const expire = localStorage.getItem('Expire');
  const [pathName, setPathName] = useState(window.location.pathname);

  // get ภาษามาเก็บค่าไว้
  const labelShow = localStorage.getItem('labelShow');
  const memComId = localStorage.getItem('memComId');
  const langValue = localStorage.getItem('langValue');

  // ส่งกลับค่าภาษากลับไปที่หน้า userMenu
  localStorage.setItem('labelShowValue', labelShow);
  localStorage.setItem('pageCode', pageCode);

  // get token
  const [switchLang, setSwicthLang] = useState({});

  useEffect(() => {
    Lang();
    expireUser();
  }, []);

  const expireUser = () => {
    const now = moment().format('LT');
    if (now > expire) {
    }
  };

  const Lang = async () => {
    const res = await GetLang({
      companyId: comId,
      lang: isUndefined(langValue) ? langValue : 'EN',
      pageCode: pageCode,
    });
    setSwicthLang(res);
  };

  const handleChangeMenu = (item) => {
    const path = item.key ? window.location.pathname : window.location.pathname;
    setPathName(path);
    app.fnc.setPageSelect(item.key);
  };

  return (
    <div>
      {!initialized ? (
        <div></div>
      ) : (
        <PageSettings.Consumer>
          {() => (
            <Layout style={{ height: 'antd', width: '100%', background: '#FAFAFA' }}>
              <LeftMenu labelShow={switchLang} onChangeMenu={handleChangeMenu} />

              <Layout>
                <Header
                  style={{
                    background: '#FAFAFA',
                    padding: '0px',
                    width: '100%',
                  }}
                >
                  <TopBar comId={comId} lang="TH" pageCode={pageCode} pathName={pathName} />
                </Header>

                <Content
                  style={{
                    margin: '36px 16px 16px 16px',
                    background: '#FAFAFA',
                    // minHeight: '88vh',
                  }}
                >
                  <ContentNew />
                </Content>
              </Layout>
            </Layout>
          )}
        </PageSettings.Consumer>
      )}
    </div>
  );
};
